import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PreLoader from '../../Components/PreLoader';
import Helpers from '../../Config/Helpers';
import axios from 'axios';
import { debounce } from 'lodash';
import { Modal, Button, Collapse, Carousel } from 'react-bootstrap'; // Import Carousel for multiple photo handling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ShareProfileComponent from '../../Components/ShareProfileComponent';

export default function UserDetail() {
    const { userId } = useParams();
    const [loading, setLoading] = useState(true);
    const [profileData, setProfileData] = useState(null);
    const [isFollowing, setIsFollowing] = useState(false);
    const [showAllContent, setShowAllContent] = useState(false);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [selectedPhotos, setSelectedPhotos] = useState([]); // Handle multiple photos
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0); // Track current photo in modal
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [followers, setFollowers] = useState([]);
    const [followings, setFollowings] = useState([]);
    const [followersOpen, setFollowersOpen] = useState(false);
    const [followingsOpen, setFollowingsOpen] = useState(false);
    const videoRefs = useRef([]);
    const playPauseLocks = useRef([]);
    const navigate = useNavigate();
    const limit = 10;
    const [showShareModal, setShowShareModal] = useState(false);

    const fetchProfileData = async () => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}auth/user/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                setProfileData(response.data);
                setIsFollowing(response.data.is_followed);
                setFollowers(response.data.followers);
                setFollowings(response.data.followings);
                // console.log('user data',response.data);

            } else {
                Helpers.toast('error', 'Failed to fetch profile data');
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to fetch profile data');
        } finally {
            setLoading(false);
        }
    };

    const handleFollow = async () => {
        try {
            const url = isFollowing ? `${Helpers.apiUrl}auth/followers/${userId}` : `${Helpers.apiUrl}auth/followers`;
            const method = isFollowing ? 'delete' : 'post';
            const data = isFollowing ? {} : { userId: userId };

            const response = await axios({
                method,
                url,
                data,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200 || response.status === 201) {
                setIsFollowing(!isFollowing);
                Helpers.toast('success', isFollowing ? 'Unfollowed successfully' : 'Followed successfully');

                setProfileData((prevData) => ({
                    ...prevData,
                    followers_count: isFollowing ? prevData.followers_count - 1 : prevData.followers_count + 1
                }));

                await fetchProfileData();
            } else {
                Helpers.toast('error', 'Failed to update follow status');
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to update follow status');
        }
    };

    const handleVideoHover = debounce(async (index) => {
        const video = videoRefs.current[index];
        if (video) {
            video.currentTime = 0;
            if (!playPauseLocks.current[index]) {
                playPauseLocks.current[index] = true;
                try {
                    await video.play();
                } catch (error) {
                    console.log('Video play interrupted', error);
                } finally {
                    playPauseLocks.current[index] = false;
                }
            }
        }
    }, 500);

    const handleVideoLeave = debounce(async (index) => {
        const video = videoRefs.current[index];
        if (video) {
            if (!playPauseLocks.current[index]) {
                playPauseLocks.current[index] = true;
                try {
                    await video.pause();
                } catch (error) {
                    console.log('Video pause interrupted', error);
                } finally {
                    playPauseLocks.current[index] = false;
                }
            }
        }
    }, 500);

    const handleVideoPlayPause = async (index) => {
        const video = videoRefs.current[index];
        if (video && !playPauseLocks.current[index]) {
            playPauseLocks.current[index] = true;
            try {
                if (video.paused) {
                    await video.play();
                } else {
                    await video.pause();
                }
            } catch (error) {
                console.log('Video play/pause interrupted', error);
            } finally {
                playPauseLocks.current[index] = false;
            }
        }
    };

    const handleContentClick = async (content) => {
        try {
            await axios.post(`${Helpers.apiUrl}auth/content/increment-view/${content.id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            setProfileData(prevData => ({
                ...prevData,
                contents: prevData.contents.map(c =>
                    c.id === content.id
                        ? { ...c, views_count: (c.views_count || 0) + 1 }
                        : c
                )
            }));

            if (content.type === 'photo') {
                let filePaths;
                try {
                    // Ensure that file_path is either an array or parseable JSON
                    filePaths = Array.isArray(content.file_path) ? content.file_path : JSON.parse(content.file_path);
                } catch (error) {
                    filePaths = [content.file_path];
                }
                setSelectedPhotos(filePaths); // Set the selected photos to the state
                setCurrentPhotoIndex(0); // Start with the first photo in the array
                setShowPhotoModal(true); // Trigger the modal to open
            } else if (content.type === 'video') {
                navigate(`/video/${content.id}`);
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to increment view count');
        }
    };


    useEffect(() => {
        fetchProfileData();
    }, [userId]);

    const linkStyle = {
        display: 'inline-block',
        maxWidth: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
    const formatLink = (link) => {
        if (!link || typeof link !== 'string') {
            // Return an empty string or handle the case when link is null/undefined
            return '';
        }
        // Check if the link starts with a protocol
        if (link.startsWith('http://') || link.startsWith('https://')) {
            return link;
        }
        // Prepend https:// if no protocol is found
        return `https://${link}`;
    };
    const handleForwardProfile = () => {
        setShowShareModal(true);
    };

    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="col-lg-12">

                            <div className="row">
                                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                                    <div className="card-body p-0">
                                        <img
                                            src={profileData?.profile?.banner_image || "../assets/images/u-bg.jpg"}
                                            alt="Banner"
                                            style={{ width: '100%', height: '50vh', objectFit: 'cover' }}
                                        />
                                    </div>
                                    <div className="card-body p-0 position-relative">
                                        <div className="profile-header">
                                            <div className="profile-info">
                                                <figure className="avatar">
                                                    <img src={profileData?.profile?.profile_picture || './assets/images/user-12.png'} alt="Profile" />
                                                </figure>
                                                <div className='text-start'>
                                                    <h4 className="fw-700 font-sm text-start">{profileData?.name}</h4>
                                                    <span className="fw-500 font-xssss text-gray-500 text-start">{profileData?.email}</span>
                                                </div>
                                            </div>
                                            <div className="profile-stats">
                                                <div className="stats-block">
                                                    <span className="fw-700 font-xsss m-2">Followers</span>
                                                    <span className="fw-500 font-xs">{profileData?.followers_count}</span>
                                                </div>
                                                <div className="stats-block">
                                                    <span className="fw-700 font-xsss m-2">Followings</span>
                                                    <span className="fw-500 font-xs">{profileData?.followings_count}</span>
                                                </div>
                                                <button className="btn btn-sm btn-follow m-2 text-white" onClick={handleFollow}>
                                                    {isFollowing ? 'Unfollow' : 'Follow'}
                                                </button>
                                                {/* Forward Profile Button */}
                                                <button className="btn btn-sm btn-follow text-white" onClick={handleForwardProfile}>
                                                    Share Profile
                                                </button>
                                                <ShareProfileComponent
                                                    show={showShareModal}
                                                    handleClose={() => setShowShareModal(false)}
                                                    profileData={profileData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card-body d-block p-4">
                                                        <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">About</h4>
                                                        <p className="fw-500 text-gray-500 lh-24 font-xssss mb-3 text-start">{profileData?.profile?.bio}</p>
                                                        <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">Visibility</h4>
                                                        <p className="fw-500 text-gray-500 lh-24 font-xssss mb-3 text-start">{profileData?.profile?.visibility}</p>
                                                        {/* {profileData?.profile.awards && profileData.profile.awards.length > 0 ? ( */}
                                                        <>
                                                            <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">Awards</h4>
                                                            <p className="fw-500 text-gray-500 lh-24 font-xssss mb-3 text-start">{profileData?.profile?.awards}</p>
                                                        </>
                                                        {/* ) : null} */}
                                                        {/* {profileData?.profile.handle && profileData.profile.handle.length > 0 ? ( */}
                                                        <>
                                                            <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">Handle</h4>
                                                            <p className="fw-500 text-gray-500 lh-24 font-xssss mb-3 text-start">{profileData?.profile?.handle}</p>
                                                        </>
                                                        {/* ) : null} */}



                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card-body d-block p-4">
                                                        {/* {profileData?.profile.message && profileData.profile.message.length > 0 ? ( */}
                                                        <>
                                                            <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">Message</h4>
                                                            <p className="fw-500 text-gray-500 lh-24 font-xssss mb-3 text-start">{profileData?.profile?.message}</p>
                                                        </>

                                                        {/* ) : null}
                                                        {profileData?.profile.website_link && profileData.profile.website_link.length > 0 ? ( */}


                                                        <>
                                                            <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">Website Link</h4>

                                                            <p className='text-start font-xssss'>
                                                                <a
                                                                    href={formatLink(profileData?.profile?.website_link)}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    style={linkStyle}
                                                                    className="text-blue-500 hover:underline"
                                                                >
                                                                    {profileData?.profile?.website_link}
                                                                </a>
                                                            </p>
                                                        </>
                                                        {/* ) : null}
                                                        {profileData?.profile?.social_links && JSON.parse(profileData.profile.social_links).some(link => link) ? ( */}
                                                        <>
                                                            <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">Social Links</h4>
                                                            <ul className="list-unstyled font-xssss text-start">
                                                                {Array.isArray(JSON.parse(profileData?.profile?.social_links)) && JSON.parse(profileData?.profile?.social_links).filter(link => link).map((link, index) => (

                                                                    <li key={index} className="mb-1">
                                                                        <a
                                                                            href={formatLink(link)}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={linkStyle}
                                                                            className="text-blue-500 hover:underline"
                                                                        >
                                                                            {link}
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </>
                                                        {/* ) : null} */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Content Cards */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3">
                                            <div className="card-body d-block p-4">
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <h4 className="fw-700 mb-3 font-xs text-gray-900 text-start">Recent Content</h4>
                                                    {profileData?.contents?.length > 3 && !showAllContent && (
                                                        <span className="btn btn-primary btn-sm text-white" onClick={() => setShowAllContent(true)}>See All</span>
                                                    )}
                                                </div>
                                                <div className="grid mb-3" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '20px' }}>
                                                    {profileData?.contents?.slice(0, showAllContent ? profileData.contents.length : 3).map((content, index) => {
                                                        if (content.content_visibility === 'public' || isFollowing) {
                                                            return (
                                                                <div key={index} className="card rounded-xxl border-0 hover-card" style={{ overflow: 'hidden', position: 'relative', cursor: 'pointer', transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out' }}
                                                                    onClick={() => handleContentClick(content)}>
                                                                    <div className="card-body p-0 text-start">
                                                                        <h5>{content.title}</h5>
                                                                        {content.type === 'video' && (
                                                                            <video
                                                                                ref={el => videoRefs.current[index] = el}
                                                                                loop
                                                                                className="w-100 rounded-lg"
                                                                                controls
                                                                                muted
                                                                                onMouseOver={() => handleVideoHover(index)}
                                                                                onMouseLeave={() => handleVideoLeave(index)}
                                                                                onClick={() => handleVideoPlayPause(index)}
                                                                                style={{ objectFit: 'cover', height: '200px', transition: 'transform 0.3s ease-in-out', borderRadius: '10px' }}
                                                                            >
                                                                                <source src={content.file_path} type="video/mp4" />
                                                                            </video>
                                                                        )}

                                                                        {content.type === 'photo' && (
                                                                            <div>
                                                                                {Array.isArray(content.file_path) || (content.file_path && content.file_path.startsWith('[')) ? (
                                                                                    // If content.file_path is an array or JSON string of multiple photo paths
                                                                                    (Array.isArray(content.file_path) && content.file_path.length > 1) ||
                                                                                        (content.file_path.startsWith('[') && JSON.parse(content.file_path).length > 1) ? (
                                                                                        // Use Carousel only when there are multiple images
                                                                                        <Carousel activeIndex={currentPhotoIndex} onSelect={(selectedIndex) => setCurrentPhotoIndex(selectedIndex)}>
                                                                                            {Array.isArray(content.file_path)
                                                                                                ? content.file_path.map((url, idx) => (
                                                                                                    <Carousel.Item key={idx}>
                                                                                                        <img
                                                                                                            src={url}
                                                                                                            alt={`Photo ${idx + 1}`}
                                                                                                            className="d-block w-100"
                                                                                                            style={{ objectFit: 'cover', height: '200px', borderRadius: '10px' }}
                                                                                                        />
                                                                                                    </Carousel.Item>
                                                                                                ))
                                                                                                : JSON.parse(content.file_path).map((url, idx) => (
                                                                                                    <Carousel.Item key={idx}>
                                                                                                        <img
                                                                                                            src={url}
                                                                                                            alt={`Photo ${idx + 1}`}
                                                                                                            className="d-block w-100"
                                                                                                            style={{ objectFit: 'cover', height: '200px', borderRadius: '10px' }}
                                                                                                        />
                                                                                                    </Carousel.Item>
                                                                                                ))}
                                                                                        </Carousel>
                                                                                    ) : (
                                                                                        // If it's only one photo, display the single image without the carousel
                                                                                        <img
                                                                                            src={Array.isArray(content.file_path) ? content.file_path[0] : JSON.parse(content.file_path)[0]}
                                                                                            alt="photo"
                                                                                            className="w-100 rounded-lg"
                                                                                            style={{ objectFit: 'cover', height: '200px', transition: 'transform 0.3s ease-in-out', borderRadius: '10px' }}
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    // Handle the case where it's a single photo string and not an array or JSON
                                                                                    <img
                                                                                        src={content.file_path}
                                                                                        alt="photo"
                                                                                        className="w-100 rounded-lg"
                                                                                        style={{ objectFit: 'cover', height: '200px', transition: 'transform 0.3s ease-in-out', borderRadius: '10px' }}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        )}



                                                                        {content.type === 'music' && (
                                                                            <audio
                                                                                controls
                                                                                className="w-100 rounded-lg audio-player"
                                                                                style={{ objectFit: 'cover', height: '200px', transition: 'transform 0.3s ease-in-out', borderRadius: '10px' }}
                                                                            >
                                                                                <source src={content.file_path} type="audio/mpeg" />
                                                                            </audio>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                                        <div className="card-body d-block p-4">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <Button
                                                    onClick={() => setFollowersOpen(!followersOpen)}
                                                    aria-controls="followers-collapse"
                                                    aria-expanded={followersOpen}
                                                    className="fw-500 font-xssss text-white btn-primary"
                                                >
                                                    {followersOpen ? 'Hide Followers' : 'Show Followers'}
                                                </Button>
                                            </div>
                                            <Collapse in={followersOpen}>
                                                <div id="followers-collapse">
                                                    <div className="d-flex flex-wrap">
                                                        {followers.length === 0 ? (
                                                            <p className='m-2 p-2'>No Followers</p>
                                                        ) : (
                                                            followers.map((follower, index) => (
                                                                follower?.follower && (
                                                                    <div key={index} onClick={() => navigate(`/user-detail/${follower.follower.id}`)} className="follower-item d-flex align-items-center mb-3 cursor-pointer">
                                                                        <figure className="avatar">
                                                                            <img src={follower.follower.profile?.profile_picture || './assets/images/user-12.png'} alt="image" className="avatar-img" />
                                                                        </figure>
                                                                        <div style={{ paddingLeft: '15px' }}>
                                                                            <h5 className="fw-500 font-sm text-left">{follower.follower.name}</h5>
                                                                            <span className="fw-500 font-xssss text-gray-500 d-block mt-2 text-left">{follower.follower.email}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3">
                                        <div className="card-body d-block p-4">
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <Button
                                                    onClick={() => setFollowingsOpen(!followingsOpen)}
                                                    aria-controls="followings-collapse"
                                                    aria-expanded={followingsOpen}
                                                    className="fw-500 font-xssss text-white btn-primary"
                                                >
                                                    {followingsOpen ? 'Hide Followings' : 'Show Followings'}
                                                </Button>
                                            </div>
                                            <Collapse in={followingsOpen}>
                                                <div id="followings-collapse">
                                                    <div className="d-flex flex-wrap">
                                                        {followings.length === 0 ? (
                                                            <p className='m-2 p-2'>No Followings</p>
                                                        ) : (
                                                            followings.map((following, index) => (
                                                                following?.following && (
                                                                    <div key={index} onClick={() => navigate(`/user-detail/${following.following.id}`)} className="follower-item d-flex align-items-center mb-3 cursor-pointer">
                                                                        <figure className="avatar">
                                                                            <img src={following.following.profile?.profile_picture || './assets/images/user-12.png'} alt="image" className="avatar-img" />
                                                                        </figure>
                                                                        <div style={{ paddingLeft: '15px' }}>
                                                                            <h5 className="fw-500 font-sm text-left">{following.following.name}</h5>
                                                                            <span className="fw-500 font-xssss text-gray-500 d-block mt-2 text-left">{following.following.email}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn-sm text-white mb-1" onClick={() => navigate(-1)}>
                                        <span><FontAwesomeIcon icon={faArrowLeft} /></span> Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {selectedPhotos.length > 0 && (
                <Modal show={showPhotoModal} onHide={() => setShowPhotoModal(false)} size="md" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Photo Viewer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedPhotos.length === 1 ? (
                            // If it's a single photo, display it directly
                            <img
                                src={selectedPhotos[0]}
                                alt="Selected"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '70vh',
                                    display: 'block',
                                    margin: 'auto'
                                }}
                            />
                        ) : (
                            // If there are multiple photos, use a Carousel
                            <Carousel activeIndex={currentPhotoIndex} onSelect={(selectedIndex) => setCurrentPhotoIndex(selectedIndex)}>
                                {selectedPhotos.map((url, idx) => (
                                    <Carousel.Item key={idx}>
                                        <img
                                            src={url}
                                            alt={`Photo ${idx + 1}`}
                                            className="d-block w-100"
                                            style={{
                                                maxHeight: '70vh',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowPhotoModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <style>
                {`
                .grid {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px;
                }
                .card {
                    flex: 1 1 calc(33.33% - 16px); /* Three cards in a row */
                    box-sizing: border-box;
                    padding: 16px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                }
                .card-body {
                    padding: 0;
                    margin: 0;
                }
                .video-card video {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                }
                .photo-card img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                }
                .music-card audio {
                    width: 100%;
                    border-radius: 5px;
                    background-color: #f1f1f1;
                }
                .hover-card {
                    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                }
                .hover-card:hover {
                    transform: scale(1.05);
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 6px 16px rgba(0, 0, 0, 0.1);
                }
                .description {
                    display: -webkit-box;
                    -webkit-line-clamp: 1; /* Number of lines to show */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                }
                .audio-player {
                    width: 100%;
                    border-radius: 5px;
                    background-color: #f1f1f1;
                }
                .avatar {
                    width: 50px;
                    height: 50px;
                    margin: 0;
                    overflow: hidden;
                    border-radius: 50%;
                    border: 2px solid white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .avatar-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
                .follower-item {
                    margin-right: 20px;
                    margin-bottom: 20px;
                }
                 .btn-primary {
        background-color: #4a90e2; /* Blue shade for better visual appeal */
        border-color: #4a90e2; /* Consistent border color */
        transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    }

    .btn-primary:hover {
        background-color: #357ABD; /* Darker shade on hover */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    }

    .btn-primary:focus {
        box-shadow: 0 0 0 0.2rem rgba(74, 144, 226, 0.5); /* Focus ring with transparency */
    }

    .font-xsss {
        font-size: 0.75rem; /* Smaller font size for text labels */
        color: #333; /* Darker text color for better readability */
    }

    .font-xs {
        font-size: 0.875rem; /* Slightly larger font for counts */
        font-weight: 600; /* Bold font weight for counts */
        color: #333; /* Consistent text color */
    }

    /* Additional style for alignment and spacing */
    .d-flex.align-items-center {
        align-items: center; /* Ensure vertical center alignment */
        justify-content: space-between; /* Space elements nicely */
        padding: 0.5rem 1rem; /* Padding for better spacing */
    }
    .profile-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
    }

    .profile-stats {
        display: flex;
        align-items: center;
    }

    .stats-block {
        margin-right: 20px; /* Spacing between blocks */
    }

    .stats-block:last-child {
        margin-right: 0;
    }

    .btn-follow {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border-radius: 6px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .btn-follow:hover {
        background-color: #0056b3;
    }

    .profile-info {
        display: flex;
        align-items: center;
    }

    .avatar {
        width: 100px;
        height: 100px;
        margin-right: 15px;
        overflow: hidden;
        border-radius: 50%;
        border: 5px solid white;
    }

    .avatar img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
                `}
            </style>
        </div>
    );
}
