import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

class Helpers {
    static localhost = "http://127.0.0.1:8000"; // Include protocol
    static server = "https://blackboxapi.cyberifyportfolio.com"; // Use this if you're pointing to a live server
    static basePath = this.server; // Ensure this is set correctly based on your environment
    static apiUrl = `${this.basePath}/api/`;

    static getItem(key, isJson = false) {
        const item = localStorage.getItem(key);
        if (!item) return null;
        if (isJson) {
            try {
                return JSON.parse(item);
            } catch (e) {
                console.error('Error parsing JSON from localStorage', e);
                return null;
            }
        }
        return item;
    }

    static setItem(key, data, isJson = false) {
        if (isJson) {
            localStorage.setItem(key, JSON.stringify(data));
        } else {
            localStorage.setItem(key, data);
        }
    }

    static getAuthToken() {
        return localStorage.getItem('token');
    }

    static authUser = Helpers.getItem('user', true) || {};

    static serverImage(name) {
        return `${this.basePath}/uploads/${name}`;
    }

    static authHeaders() {
        const token = Helpers.getAuthToken();
        return {
            headers: {
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${token}`
            }
        };
    }

    static authFileHeaders() {
        const token = Helpers.getAuthToken();
        return {
            headers: {
                "Content-Type": 'multipart/form-data',
                "Authorization": `Bearer ${token}`
            }
        };
    }

    static toast(type, message) {
        const notyf = new Notyf();
        notyf.open({
            message: message,
            type: type,
            position: { x: 'right', y: 'top' },
            ripple: true,
            dismissible: true,
            duration: 2000,
        });
    }

    static staticImage(path) {
        const imageUrl = new URL(`/src/${path}`, import.meta.url).href;
        return imageUrl;
    }

    static replaceSpaces(str) {
        return str.replace(/ /g, '-');
    }

    static loadScript(scriptName) {
        return new Promise((resolve, reject) => {
            const scriptPath = `/assets/js/${scriptName}`;
            const script = document.createElement('script');
            script.src = scriptPath;
            script.async = true;

            script.onload = () => {
                // console.log(`Loaded script: ${scriptPath}`);
                resolve(script);
            };
            script.onerror = () => {
                console.error(`Failed to load script: ${scriptPath}`);
                reject(new Error(`Script load error: ${scriptPath}`));
            };

            document.body.appendChild(script);
        });
    }
}

export default Helpers;
