import React, { useState, useEffect, useRef } from 'react';
import PreLoader from '../../Components/PreLoader';
import Helpers from '../../Config/Helpers';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ShareProfileComponent from '../../Components/ShareProfileComponent';
import ImageCard from '../../Components/ImageCard';

export default function ArtistPhotos() {
    const [loading, setLoading] = useState(true);
    const [profileData, setProfileData] = useState(null);
    const [videos, setVideos] = useState([]);
    const [shareProfileModel, setShowShareProfileModal] = useState(false);


    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await axios.get(`${Helpers.apiUrl}auth/profiles`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (response.status === 200) {
                    setProfileData(response.data);
                } else {
                    Helpers.toast('error', 'Failed to fetch profile data');
                }
            } catch (error) {
                Helpers.toast('error', 'Failed to fetch profile data');
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${Helpers.apiUrl}auth/artist-photos`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (response.status === 200) {
                    setVideos(response.data.data);
                }
            } catch (error) {
                Helpers.toast('error', 'Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    const handleForwardProfile = () => {
        setShowShareProfileModal(true);
    };

    return (
        <div className='main-content right-chat-active bg-red-200'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                                    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                                    <img
                                            src={profileData?.banner_image || "./assets/images/u-bg.jpg"}
                                            alt="image"
                                            style={{
                                                width: '100%',
                                                height: '50vh', /* or '400px' if you prefer a fixed height */
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </div>
                                    <div className="card-body p-0 position-relative">
                                        <div className="d-flex align-items-center" style={{ position: 'relative', top: '-50px', padding: '0 20px', justifyContent: 'space-between' }}>
                                            <div className="d-flex align-items-center">
                                                <figure className="avatar" style={{ width: '100px', height: '100px', margin: '0', overflow: 'hidden', borderRadius: '50%', border: '5px solid white' }}>
                                                    <img src={profileData?.profile_picture || './assets/images/user-12.png'} alt="image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                </figure>
                                                <div style={{ paddingLeft: '15px' }}>
                                                    <h4 className="fw-700 font-sm text-left" style={{ marginTop: '50px', marginLeft: '10px' }}>
                                                        {profileData?.user?.name}
                                                        <span className="fw-500 font-xssss text-grey-800 d-block mt-2">{profileData?.user?.email}</span>
                                                    </h4>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', gap: '10px', marginTop: '50px' }}>
                                                <Link to="/profile-settings" style={{ color: '#ffffff', borderRadius: '6px', padding: '5px 10px', border: 'none', backgroundColor: '#4267B2', borderColor: '#4267B2' }}>
                                                    Edit Profile
                                                </Link>
                                                <button onClick={handleForwardProfile} style={{ color: '#ffffff', borderRadius: '6px', padding: '5px 10px', border: 'none', backgroundColor: '#4267B2', borderColor: '#4267B2' }}>
                                                    Share Profile
                                                </button>
                                            </div>

                                            <ShareProfileComponent
                                                show={shareProfileModel}
                                                handleClose={() => setShowShareProfileModal(false)}
                                                profileData={profileData} // Pass the profile data here
                                            />
                                          
                                        </div>
                                    </div>
                                    <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                                        <ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
                                            <li className="active list-inline-item me-5"><Link to="/author-profile" className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs1" data-toggle="tab">Posts</Link></li>
                                            <li className="list-inline-item me-5"><Link to="/artist-videos" className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block " href="#navtabs2" data-toggle="tab">Videos</Link></li>
                                            <li className="list-inline-item me-5"><Link to="/artist-photos" className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block active" href="#navtabs2" data-toggle="tab">Photos</Link></li>
                                            <li className="list-inline-item me-5"><Link to="/artist-music" className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs3" data-toggle="tab">Music</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-12 col-xxl-12 col-lg-12'>
                                <div className='card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 p-2'>
                                    <div className="row">
                                        {videos.map((content, index) => (
                                            <div key={index} className="col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-3 m-1">
                                                <ImageCard imageUrl={content.file_path} titleText={content.title} altText="Photo" />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            


                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
