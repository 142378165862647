import React, { useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import Header from './Components/Header';
import Sidebar from './Components/Sidebar';
import Helpers from './Config/Helpers';

export default function Layout() {
    const loadScriptFromCDN = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = true;

            script.onload = () => {
                // console.log(`${src} loaded`);
                resolve(script);
            };
            script.onerror = () => {
                console.error(`Failed to load ${src}`);
                reject(new Error(`Failed to load ${src}`));
            };

            document.body.appendChild(script);
        });
    };

    const location = useLocation();

    const loadScriptsSequentially = async () => {
        try {
            await loadScriptFromCDN('https://code.jquery.com/jquery-3.6.0.min.js');
            await loadScriptFromCDN('https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js');
            await loadScriptFromCDN('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js');
            await Helpers.loadScript("scripts.js");
            await Helpers.loadScript("video-player.js");
        } catch (error) {
            console.error("Script loading failed: ", error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        loadScriptsSequentially();
    }, [location.pathname]);

    return (
        <div className='main-wrapper color-theme-lightblue mont-font'>
            <Header />
            <Sidebar />
            <Outlet />
        </div>
    );
}
