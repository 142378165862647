import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
} from 'react-share';
import { FaInstagram, FaVimeo, FaYoutube, FaTiktok } from 'react-icons/fa';
import { AiOutlineLink } from 'react-icons/ai';
import axios from 'axios';
import Helpers from '../Config/Helpers'; // Adjust the path as needed

const XIcon = ({ size, round }) => (
    <div
        style={{
            width: size,
            height: size,
            backgroundColor: 'black',
            borderRadius: round ? '50%' : '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.793 2.354a1 1 0 010 1.415l-5.793 5.793 5.793 5.793a1 1 0 01-1.415 1.415L12.585 11l-5.793 5.793a1 1 0 01-1.415-1.415L11.171 9l-5.793-5.793a1 1 0 011.415-1.415L12 7.585l5.793-5.793a1 1 0 011.415 0z"
                fill="white"
            />
        </svg>
    </div>
);

const ShareComponent = ({ show, handleClose, shareData }) => {
    const [copySuccess, setCopySuccess] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    const recordShare = () => {
        axios.post(`${Helpers.apiUrl}auth/content/share/${shareData.content_id}`, {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(response => {
            console.log(response.data.message);
        }).catch(error => {
            console.error('Failed to record share:', error);
        });
    };

    const handleCopyLink = (platform) => {
        navigator.clipboard.writeText(shareData.url).then(() => {
            setCopySuccess(`Link copied to clipboard for ${platform}!`);
            const id = setTimeout(() => setCopySuccess(''), 3000);
            setTimeoutId(id);

            // Call the API to store share data
            recordShare();
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    const handleShare = (platform) => {
        // Call the API to store share data
        recordShare();
    };

    const truncateTitle = (title, length) => {
        if (!title) return ''; // Check if title is defined
        return title.length > length ? `${title.substring(0, length)}...` : title;
    };

    const iconStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        transition: 'transform 0.3s',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        cursor: 'pointer',
    };

    const buttonStyle = {
        padding: '10px 20px',
        marginTop: '10px',
        background: 'linear-gradient(90deg, #007bff 0%, #00d4ff 100%)',
        border: 'none',
        borderRadius: '5px',
        color: '#fff',
        fontSize: '16px',
        transition: 'background 0.3s, transform 0.3s',
    };

    const buttonHoverStyle = {
        background: 'linear-gradient(90deg, #0056b3 0%, #0084b3 100%)',
        transform: 'scale(1.05)',
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton style={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #dee2e6' }}>
                <Modal.Title style={{ fontSize: '1.5rem', color: '#333' }}>Share Content</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center" style={{ backgroundColor: '#fff', padding: '20px' }}>
                <p>{truncateTitle(shareData.text || '', 100)}</p>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: '10px' }}>
                    <div style={iconStyle}>
                        <FacebookShareButton url={shareData.url} beforeOnClick={handleShare}>
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                    </div>
                    <div style={iconStyle}>
                        <TwitterShareButton url={shareData.url} beforeOnClick={handleShare}>
                            <XIcon size={32} round />
                        </TwitterShareButton>
                    </div>
                    <div style={iconStyle} onClick={() => handleCopyLink('Instagram')}>
                        <FaInstagram size={24} style={{ color: '#E4405F' }} />
                    </div>
                    <div style={iconStyle} onClick={() => handleCopyLink('Vimeo')}>
                        <FaVimeo size={24} style={{ color: '#1ab7ea' }} />
                    </div>
                    <div style={iconStyle} onClick={() => handleCopyLink('YouTube')}>
                        <FaYoutube size={24} style={{ color: '#FF0000' }} />
                    </div>
                    <div style={iconStyle} onClick={() => handleCopyLink('Matrix')}>
                        <AiOutlineLink size={24} style={{ color: '#000000' }} />
                    </div>
                    <div style={iconStyle} onClick={() => handleCopyLink('TikTok')}>
                        <FaTiktok size={24} style={{ color: '#000000' }} />
                    </div>
                    <Button className='text-white'
                        onMouseEnter={(e) => {
                            e.target.style.background = buttonHoverStyle.background;
                            e.target.style.transform = buttonHoverStyle.transform;
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.background = buttonStyle.background;
                            e.target.style.transform = 'none';
                        }}
                        onClick={() => handleCopyLink('General')}
                        style={buttonStyle}
                    >
                        Copy Link
                    </Button>
                </div>
                {copySuccess && <p style={{ marginTop: '10px', color: 'green' }}>{copySuccess}</p>}
            </Modal.Body>
        </Modal>
    );
};

export default ShareComponent;
