import React, { useState } from 'react';
import axios from 'axios';
import Helpers from '../Config/Helpers'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';

const OnboardingForm = ({ onComplete = () => {} }) => {
  const [formData, setFormData] = useState({
    content_interest: '',
    genres: [],
    mediums: [],
    film_preference: '',
    live_streams: '',
    recommendations: '',
  });

  const [customGenre, setCustomGenre] = useState('');
  const [customMedium, setCustomMedium] = useState('');
  const navigate = useNavigate();

  const genresOptions = [
    'Science Fiction', 'Fantasy', 'Drama', 'Comedy', 'Horror',
    'Thriller', 'Documentary', 'Animation', 'Experimental', 'Other'
  ];

  const mediumsOptions = [
    'Film', 'Looped Visual Art', 'Animation', 'Images / Visual Art',
    'Music Video', 'Other'
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e, listName) => {
    const { value } = e.target;
    const list = formData[listName];
  
    let newList;
  
    if (list.includes(value)) {
      // Unchecking option
      newList = list.filter(item => item !== value);
    } else {
      // Checking option
      if (listName === 'genres' && list.length >= 3) {
        // Do not allow more than 3 genres (including "Other")
        return;
      }
      newList = [...list, value];
    }
  
    setFormData({
      ...formData,
      [listName]: newList,
    });
  };
  
 
  
  const handleCustomInput = (e, listName) => {
    const { value } = e.target;
    if (listName === 'genres') {
      setCustomGenre(value);
    } else {
      setCustomMedium(value);
    }
  };

  const replaceOtherWithCustom = (listName) => {
    if (listName === 'genres' && customGenre.trim() !== '') {
      setFormData({
        ...formData,
        genres: formData.genres.map(item => (item === 'Other' ? customGenre : item)),
      });
      setCustomGenre('');
    }
    if (listName === 'mediums' && customMedium.trim() !== '') {
      setFormData({
        ...formData,
        mediums: formData.mediums.map(item => (item === 'Other' ? customMedium : item)),
      });
      setCustomMedium('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Replace 'Other' with custom input before submission
    replaceOtherWithCustom('genres');
    replaceOtherWithCustom('mediums');

    // Validate that required fields are filled
    if (!formData.content_interest) {
      Helpers.toast('error', 'Please select your content interest.');
      return;
    }
    if (formData.genres.length === 0) {
      Helpers.toast('error', 'Please select at least one genre.');
      return;
    }
    if (formData.mediums.length === 0) {
      Helpers.toast('error', 'Please select at least one medium.');
      return;
    }
    if (!formData.film_preference) {
      Helpers.toast('error', 'Please select your film preference.');
      return;
    }
    if (!formData.live_streams) {
      Helpers.toast('error', 'Please indicate your interest in live streams.');
      return;
    }
    if (!formData.recommendations) {
      Helpers.toast('error', 'Please indicate if you want to receive recommendations.');
      return;
    }

    // Replace 'Other' with custom values for both genres and mediums
    const updatedGenres = formData.genres.map((item) => item === 'Other' ? customGenre : item);
    const updatedMediums = formData.mediums.map((item) => item === 'Other' ? customMedium : item);

    // console.log('updated genres',updatedGenres);
    // console.log('updated mediums ',updatedMediums);
    
    // Prepare submission data
    const submissionData = {
      content_interest: formData.content_interest,
      genres: updatedGenres,
      mediums: updatedMediums,
      film_preference: formData.film_preference,
      live_streams: formData.live_streams === 'Yes',
      recommendations: formData.recommendations === 'Yes',
    };

    try {
      const authToken = Helpers.getItem('token');
      await axios.post(`${Helpers.apiUrl}auth/onboarding`, submissionData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      Helpers.toast('success', 'Onboarding completed successfully');
      onComplete();
      navigate('/');
    } catch (error) {
      console.error('Error submitting onboarding form:', error);
      Helpers.toast('error', 'Failed to submit onboarding form');
    }
};


  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.cardBody}>
          <h2 style={styles.title}>Onboarding Questions</h2>
          <form onSubmit={handleSubmit}>
            {/* Content Interest */}
            <div className="form-group text-start" style={styles.formGroup}>
              <label style={styles.label}>What type of content are you most interested in?</label>
              <select 
                className="form-control" 
                name="content_interest" 
                value={formData.content_interest} 
                onChange={handleChange} 
                style={styles.select}
              >
                <option value="">Select...</option>
                <option value="Films">Films</option>
                <option value="Visual Art">Visual Art</option>
                <option value="Music Videos">Music Videos</option>
              </select>
            </div>

            {/* Genres */}
            <div className="form-group text-start" style={styles.formGroup}>
              <label style={styles.label}>Which genres do you enjoy the most? (Select up to 3)</label>
              <div style={styles.checkboxGroup}>
                {genresOptions.map((genre) => (
                  <div key={genre} className="form-check" style={styles.formCheck}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="genres"
                      value={genre}
                      checked={formData.genres.includes(genre)}
                      onChange={(e) => handleCheckboxChange(e, 'genres')}
                      disabled={
                        formData.genres.length >= 3 && !formData.genres.includes(genre)
                      }
                      style={styles.checkbox}
                    />
                    <label className="form-check-label" style={styles.checkboxLabel}>{genre}</label>
                  </div>
                ))}
              </div>
              {formData.genres.includes('Other') && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter custom genre"
                  value={customGenre}
                  onChange={(e) => handleCustomInput(e, 'genres')}
                  style={styles.textInput}
                />
              )}
            </div>

            {/* Mediums */}
            <div className="form-group text-start" style={styles.formGroup}>
              <label style={styles.label}>What mediums do you prefer? (Select all that apply)</label>
              <div style={styles.checkboxGroup}>
                {mediumsOptions.map((medium) => (
                  <div key={medium} className="form-check" style={styles.formCheck}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="mediums"
                      value={medium}
                      checked={formData.mediums.includes(medium)}
                      onChange={(e) => handleCheckboxChange(e, 'mediums')}
                      style={styles.checkbox}
                    />
                    <label className="form-check-label" style={styles.checkboxLabel}>{medium}</label>
                  </div>
                ))}
              </div>
              {formData.mediums.includes('Other') && (
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter custom medium"
                  value={customMedium}
                  onChange={(e) => handleCustomInput(e, 'mediums')}
                  style={styles.textInput}
                />
              )}
            </div>

            {/* Film Preference */}
            <div className="form-group text-start" style={styles.formGroup}>
              <label style={styles.label}>How do you prefer to watch films?</label>
              <select 
                className="form-control" 
                name="film_preference" 
                value={formData.film_preference} 
                onChange={handleChange} 
                style={styles.select}
              >
                <option value="">Select...</option>
                <option value="Short Films">Short Films</option>
                <option value="Full-Length Films">Full-Length Films</option>
                <option value="Looped Image Videos">Looped Image Videos</option>
              </select>
            </div>

            {/* Live Streams */}
            <div className="form-group text-start" style={styles.formGroup}>
              <label style={styles.label}>Are you interested in live streams or real-time interactions with artists?</label>
              <select 
                className="form-control" 
                name="live_streams" 
                value={formData.live_streams} 
                onChange={handleChange} 
                style={styles.select}
              >
                <option value="">Select...</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            {/* Recommendations */}
            <div className="form-group text-start" style={styles.formGroup}>
              <label style={styles.label}>Would you like to receive recommendations based on your preferences?</label>
              <select 
                className="form-control" 
                name="recommendations" 
                value={formData.recommendations} 
                onChange={handleChange} 
                style={styles.select}
              >
                <option value="">Select...</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            {/* Submit Button */}
            <div className="form-group mb-0" style={styles.formGroup}>
              <button type="submit" className="form-control" style={styles.submitButton}>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    padding: '20px',
    backgroundColor: '#f8f9fa',
  },
  card: {
    width: '100%',
    maxWidth: '600px',
    backgroundColor: 'white',
    borderRadius: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  cardBody: {
    padding: '20px',
  },
  title: {
    fontWeight: 'bolder',
    textAlign: 'center',
    marginBottom: '20px',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    fontWeight: '600',
  },
  select: {
    borderRadius: '10px',
    border: '1px solid #ced4da',
    backgroundColor: '#fff',
    lineHeight: '35px',
    padding: '5px 10px',
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  formCheck: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    marginRight: '10px',
  },
  checkboxLabel: {
    display: 'inline-block',
    marginLeft: '5px',
  },
  textInput: {
    lineHeight: '35px',
    borderRadius: '10px',
    border: '1px solid #ced4da',
    backgroundColor: '#fff',
    padding: '5px 10px',
    marginTop: '10px',
  },
  submitButton: {
    backgroundColor: '#4267B2',
    border: 'none',
    color: 'white',
    borderRadius: '10px',
    padding: '10px 20px',
    fontWeight: '600',
    cursor: 'pointer',
    lineHeight: '35px',
  },
};

export default OnboardingForm;
