import React, { useState, useEffect } from 'react';
import PreLoader from '../../Components/PreLoader';
import Helpers from '../../Config/Helpers';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Modal, Button, Carousel } from 'react-bootstrap';
import Swal from 'sweetalert2';

export default function UploadFiles() {
    const [loading, setLoading] = useState(true);
    const [contents, setContents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [previewContent, setPreviewContent] = useState(null);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [contentsResponse] = await Promise.all([
                    axios.get(`${Helpers.apiUrl}auth/author-content`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    })
                ]);

                if (contentsResponse.status === 200) {
                    setContents(contentsResponse.data.data);
                }
            } catch (error) {
                Helpers.toast('error', 'Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            try {
                const response = await axios.delete(`${Helpers.apiUrl}auth/contents/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (response.status === 200) {
                    setContents(contents.filter(content => content.id !== id));
                    Helpers.toast('success', 'Data deleted successfully');
                }
            } catch (error) {
                console.error('Failed to delete content:', error);
                Helpers.toast('error', 'Failed to delete content');
            }
        }
    };

    const handlePreview = (content) => {
        setPreviewContent(content);
        setIsModalOpen(true);
    };

    const truncateDescription = (description, length) => {
        if (!description) return '';
        return description.length > length ? `${description.substring(0, length)}...` : description;
    };

    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className="main-content bg-lightblue theme-dark-bg py-2">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to="/" className="d-inline-block mt-2">
                                            <i className="ti-arrow-left font-sm text-white"></i>
                                        </Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Uploaded Files</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr className='text-center'>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {contents.length > 0 ? (
                                                    contents.map((content, index) => (
                                                        <tr key={content.id}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td className='text-start'>{truncateDescription(content.title || '', 50)}</td>
                                                            <td>{content.type}</td>
                                                            <td style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                                                                {Array.isArray(content.file_paths) && content.file_paths.length > 1 && (
                                                                    <div>
                                                                        {content.file_paths.map((filePath, idx) => (
                                                                            <a key={idx} href={filePath} target="_blank" rel="noopener noreferrer" className="btn btn-link">
                                                                                View File {idx + 1}
                                                                            </a>
                                                                        ))}
                                                                    </div>
                                                                )}
                                                                <button className="btn btn-primary btn-sm text-white" onClick={() => handlePreview(content)}>Preview</button>
                                                                <button className="btn btn-danger btn-sm text-white" onClick={() => handleDelete(content.id)} style={{ marginLeft: '10px' }}>Delete</button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4" className="text-center">No Data Found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

<Modal
    show={isModalOpen}
    onHide={() => setIsModalOpen(false)}
    centered
>
    <Modal.Header closeButton>
        <Modal.Title>Preview</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {previewContent && (
            <>
                {previewContent.type === 'video' && (
                    <video controls className="w-100">
                        <source src={previewContent.file_path} type="video/mp4" />
                    </video>
                )}
                {previewContent.type === 'photo' && (
                    <Carousel activeIndex={currentPhotoIndex} onSelect={(selectedIndex) => setCurrentPhotoIndex(selectedIndex)}>
                        {Array.isArray(previewContent.file_path) && previewContent.file_path.length > 0 ? (
                            previewContent.file_path.map((url, idx) => (
                                <Carousel.Item key={idx}>
                                    <img
                                        src={url}
                                        alt={`Photo ${idx + 1}`}
                                        className="d-block w-100"
                                        style={{
                                            maxHeight: '70vh',
                                            objectFit: 'contain'
                                        }}
                                    />
                                </Carousel.Item>
                            ))
                        ) : (
                            <p>No images available</p>
                        )}
                    </Carousel>
                )}
                {previewContent.type === 'music' && (
                    <audio controls className="w-100">
                        <source src={previewContent.file_path} type="audio/mpeg" />
                    </audio>
                )}
            </>
        )}
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Close
        </Button>
    </Modal.Footer>
</Modal>

        </div>
    );
}
