import React, { useState, useEffect, useRef } from 'react';
import PreLoader from '../../Components/PreLoader';
import Helpers from '../../Config/Helpers';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Modal, Button, Form, Carousel } from 'react-bootstrap';
import EmojiPicker from 'emoji-picker-react';
import Select from 'react-select';
import { debounce } from 'lodash';
import ShareComponent from '../../Components/ShareComponent';
import ShareProfileComponent from '../../Components/ShareProfileComponent';

export default function AuthorProfile() {
    const [loading, setLoading] = useState(true);
    const [profileData, setProfileData] = useState({});
    const [videos, setVideos] = useState([]);
    const [authorContent, setAuthorContent] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState([]);
    const [fileUrl, setFileUrl] = useState([]);
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [contentVisibility, setContentVisibility] = useState('');
    const [aspectRatio, setAspectRatio] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [comments, setComments] = useState({});
    const [commentText, setCommentText] = useState("");
    const [commentVisible, setCommentVisible] = useState({});
    const [subcategories, setSubcategories] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [technology, setTechnology] = useState('');
    const [medium, setMedium] = useState('');
    const [keywordTags, setKeywordTags] = useState([]);
    const [collaborators, setCollaborators] = useState([]);
    const videoRefs = useRef([]);
    const playPauseLocks = useRef([]);
    const [showShareModal, setShowShareModal] = useState(false);
    const [shareData, setShareData] = useState({});
    const [title, setTitle] = useState('');
    const [displayedComments, setDisplayedComments] = useState({});
    const [keywordInput, setKeywordInput] = useState('');
    const [collaboratorInput, setCollaboratorInput] = useState('');
    const [shareProfileModel, setShowShareProfileModal] = useState(false);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const handlePhotoClick = (photos, index) => {
        setSelectedPhotos(photos);
        setCurrentPhotoIndex(index);
        setShowPhotoModal(true);
    };

    const handleClosePhotoModal = () => {
        setShowPhotoModal(false);
        setSelectedPhotos([]);
        setCurrentPhotoIndex(0);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && keywordInput.trim() !== '') {
            e.preventDefault();
            setKeywordTags([...keywordTags, keywordInput.trim()]);
            setKeywordInput('');
        }
    };

    const handleRemoveTag = (index) => {
        setKeywordTags(keywordTags.filter((_, i) => i !== index));
    };

    const handleCollaboratorKeyPress = (e) => {
        if (e.key === 'Enter' && collaboratorInput.trim() !== '') {
            e.preventDefault();
            setCollaborators([...collaborators, collaboratorInput.trim()]);
            setCollaboratorInput('');
        }
    };

    const handleRemoveCollaborator = (index) => {
        setCollaborators(collaborators.filter((_, i) => i !== index));
    };
    const handleEmojiClick = (emojiObject) => {
        setDescription(prevDescription => prevDescription + emojiObject.emoji);
    };

    const getAcceptType = () => {
        switch (type) {
            case 'video':
                return 'video/*';
            case 'photo':
                return 'image/*';
            case 'music':
                return 'audio/*';
            default:
                return '';
        }
    };

    const fetchSubcategories = async () => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}auth/fetch-sub-categories`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const groupedOptions = response.data.data.reduce((acc, sub) => {
                const category = sub.category;
                if (!acc[category]) acc[category] = [];
                acc[category].push({ value: sub.id, label: sub.sub_category });
                return acc;
            }, {});

            const formattedOptions = Object.keys(groupedOptions).map(category => ({
                label: category,
                options: groupedOptions[category]
            }));

            // console.log('fetch sub categories',formattedOptions);

            setSubcategories(formattedOptions);
        } catch (error) {
            Helpers.toast('error', 'Failed to fetch subcategories');
        }
    };

    const fetchLanguages = async () => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}auth/fetch-languages`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setLanguages(response.data.data.map(lang => ({ value: lang.name, label: lang.name })));
        } catch (error) {
            Helpers.toast('error', 'Failed to fetch languages');
        }
    };

    const fetchLocations = async () => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}auth/fetch-locations`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setLocations(response.data.data.map(loc => ({ value: loc.name, label: loc.name })));
        } catch (error) {
            Helpers.toast('error', 'Failed to fetch locations');
        }
    };

    const fetchProfileData = async () => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}auth/profiles`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                setProfileData(response.data);
                console.log('profile data', response.data);

            } else {
                Helpers.toast('error', 'Failed to fetch profile data');
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to fetch profile data');
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}auth/contents`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                setVideos(response.data.data);
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to fetch data');
        } finally {
            setLoading(false);
        }
    };

    const fetchAuthorData = async () => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}auth/author-content`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                setAuthorContent(response.data.data);
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to fetch data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfileData();
        fetchData();
        fetchAuthorData();
        fetchSubcategories();
        fetchLanguages();
        fetchLocations();
    }, []);

    const handleUploadContent = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();

        if (type === 'photo' && file.length > 0) {
            file.forEach((f, index) => formData.append(`file[${index}]`, f)); // Explicitly using indexed keys

        } else {
            formData.append('file', file[0]); // Single file for videos/music
        }

        formData.append('type', type);
        formData.append('description', description);
        formData.append('content_visibility', contentVisibility);
        formData.append('aspect_ratio', aspectRatio);
        formData.append('title', title);
        formData.append('genres', JSON.stringify(selectedGenres));
        formData.append('technology', technology);
        formData.append('medium', medium);
        formData.append('keyword_tags', JSON.stringify(keywordTags));
        formData.append('collaborators', JSON.stringify(collaborators));
        formData.append('language', selectedLanguage ? selectedLanguage.value : '');
        formData.append('location', selectedLocation ? selectedLocation.value : '');

        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/contents`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 201) {
                Helpers.toast('success', 'Data Uploaded Successfully');
                resetForm();  // Reset before adding the new content
                setVideos([...videos, response.data.data]);
                fetchAuthorData();
                setIsModalOpen(false);
            } else {
                Helpers.toast('error', 'Failed to upload content');
            }

        } catch (error) {
            Helpers.toast('error', 'Failed to upload content');
        } finally {
            setLoading(false);
        }
    };



    const resetForm = () => {
        setFile([]);
        setFileUrl([]);
        setType('');
        setContentVisibility('');
        setAspectRatio('');
        setDescription('');
        setTitle('');
        setSelectedGenres([]);
        setTechnology('');
        setMedium('');
        setKeywordTags([]);
        setCollaborators([]);
        setSelectedLanguage(null);
        setSelectedLocation(null);
    };

    const handleGenreChange = (selectedOptions) => {
        setSelectedGenres(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    const handleKeywordTagsChange = (e) => {
        setKeywordTags(e.target.value.split(','));
    };

    const handleCollaboratorsChange = (e) => {
        setCollaborators(e.target.value.split(','));
    };

    const handleVideoHover = debounce(async (index) => {
        const video = videoRefs.current[index];
        if (video) {
            video.currentTime = 0; // Start playing from the beginning
            if (!playPauseLocks.current[index]) {
                playPauseLocks.current[index] = true;
                try {
                    await video.play();
                } catch (error) {
                    console.log('Video play interrupted', error);
                } finally {
                    playPauseLocks.current[index] = false;
                }
            }
        }
    }, 300);

    const handleVideoLeave = debounce(async (index) => {
        const video = videoRefs.current[index];
        if (video) {
            if (!playPauseLocks.current[index]) {
                playPauseLocks.current[index] = true;
                try {
                    await video.pause();
                } catch (error) {
                    console.log('Video pause interrupted', error);
                } finally {
                    playPauseLocks.current[index] = false;
                }
            }
        }
    }, 300);

    const handleVideoPlayPause = async (index) => {
        const video = videoRefs.current[index];
        if (video && !playPauseLocks.current[index]) {
            playPauseLocks.current[index] = true;
            try {
                if (video.paused) {
                    await video.play();
                } else {
                    await video.pause();
                }
            } catch (error) {
                console.log('Video play/pause interrupted', error);
            } finally {
                playPauseLocks.current[index] = false;
            }
        }
    };

    const toggleCommentSection = async (contentId) => {
        if (!commentVisible[contentId]) {
            try {
                const response = await axios.get(`${Helpers.apiUrl}auth/get-comments?content_id=${contentId}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setComments((prev) => ({
                    ...prev,
                    [contentId]: response.data.data
                }));
                setDisplayedComments((prev) => ({
                    ...prev,
                    [contentId]: response.data.data.slice(0, 3) // display only the first 3 comments
                }));
            } catch (error) {
                Helpers.toast('error', 'Failed to fetch comments');
            }
        }
        setCommentVisible((prev) => ({
            ...prev,
            [contentId]: !prev[contentId]
        }));
    };

    const handleCommentChange = (e) => {
        setCommentText(e.target.value);
    };

    const handleCommentSubmit = async (contentId) => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/comments`, {
                content_id: contentId,
                comment: commentText
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 201) {
                const newComment = response.data.data;
                setComments((prev) => ({
                    ...prev,
                    [contentId]: [newComment, ...(prev[contentId] || [])]
                }));
                setDisplayedComments((prev) => ({
                    ...prev,
                    [contentId]: [newComment, ...(prev[contentId] || []).slice(0, 2)] // Include the new comment
                }));
                setCommentText("");
                setCommentVisible((prev) => ({
                    ...prev,
                    [contentId]: true
                }));
                fetchAuthorData();
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to post comment');
        }
    };

    const handleLike = async (contentId) => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/like`, {
                content_id: contentId,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 201 || response.status === 200) {
                setAuthorContent(prevContents =>
                    prevContents.map(content =>
                        content.id === contentId
                            ? { ...content, user_liked: true, likes_count: content.likes_count + 1 }
                            : content
                    )
                );
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to post like');
        }
    };

    const handleUnlike = async (contentId) => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/unlike`, {
                content_id: contentId,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                setAuthorContent(prevContents =>
                    prevContents.map(content =>
                        content.id === contentId
                            ? { ...content, user_liked: false, likes_count: content.likes_count - 1 }
                            : content
                    )
                );
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to unlike content');
        }
    };

    const handleLikeToggle = (contentId, userLiked) => {
        if (userLiked) {
            handleUnlike(contentId);
        } else {
            handleLike(contentId);
        }
    };

    const handleShare = (content) => {
        const shareData = {
            title: content.description || 'Content Title',
            text: content.description || 'Content Description',
            url: `${window.location.origin}/content/${content.id}`,
            content_id: content.id // Make sure content_id is included here
        };

        setShareData(shareData);
        setShowShareModal(true);
    };

    const iconStyle = {
        backgroundImage: 'url("https://static.xx.fbcdn.net/rsrc.php/v3/yW/r/hnYJwtcMFVV.png?_nc_eui2=AeGu_Ns0XyIF8YGDgkLZ4euKwhg5DgQVGCfCGDkOBBUYJ-zr8ViWDiVFSbVjLU_zlfs5lOO2oaUKDfhunHDM6JTp")',
        backgroundPosition: '0px -254px',
        backgroundSize: 'auto',
        width: '20px',
        height: '20px',
        backgroundRepeat: 'no-repeat',
        display: 'inline-block'
    };

    const [expanded, setExpanded] = useState(false);

    const truncateDescription = (description, length) => {
        if (!description) return '';
        return description.length > length ? `${description.substring(0, length)}...` : description;
    };

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    const handleFileChange = (e) => {
        if (type === 'photo') {
            const files = Array.from(e.target.files);

            // Check if the number of selected photos exceeds 4
            if (files.length + file.length > 4) {
                Helpers.toast('error', 'You can only select up to 4 photos.');
                return;
            }

            setFile(prevFiles => [...prevFiles, ...files]); // Append selected files to existing files
            const urls = files.map(file => URL.createObjectURL(file)); // Generate preview URLs for new selected files
            setFileUrl(prevUrls => [...prevUrls, ...urls]); // Append URLs for preview
        } else {
            const file = e.target.files[0];
            setFile([file]); // Handle single file uploads (video/music) as a single element array
            setFileUrl([URL.createObjectURL(file)]); // Store single URL for preview
        }
    };




    const handleContentClick = async (content) => {
        try {
            await axios.post(`${Helpers.apiUrl}auth/content/increment-view/${content.id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (content.type === 'photo') {
                // Handle photo click
            } else if (content.type === 'video') {
                // Handle video click
            } else if (content.type === 'music') {
                // Handle music click
            }

            fetchAuthorData();
        } catch (error) {
            Helpers.toast('error', 'Failed to increment view count');
        }
    };

    const linkStyle = {
        display: 'inline-block',
        maxWidth: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
    const formatLink = (link) => {
        if (!link || typeof link !== 'string') {
            // Return an empty string or handle the case when link is null/undefined
            return '';
        }
        // Check if the link starts with a protocol
        if (link.startsWith('http://') || link.startsWith('https://')) {
            return link;
        }
        // Prepend https:// 
        return `https://${link}`;
    };
    const handleForwardProfile = () => {
        setShowShareProfileModal(true);
    };

    return (
        <div className='main-content right-chat-active bg-red-200'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                                    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3">
                                        <img
                                            src={profileData?.banner_image || "./assets/images/u-bg.jpg"}
                                            alt="image"
                                            style={{
                                                width: '100%',
                                                height: '50vh', /* or '400px' if you prefer a fixed height */
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </div>
                                    <div className="card-body p-0 position-relative">
                                        <div className="d-flex align-items-center" style={{ position: 'relative', top: '-50px', padding: '0 20px', justifyContent: 'space-between' }}>
                                            <div className="d-flex align-items-center">
                                                <figure className="avatar" style={{ width: '100px', height: '100px', margin: '0', overflow: 'hidden', borderRadius: '50%', border: '5px solid white' }}>
                                                    <img src={profileData?.profile_picture || './assets/images/user-12.png'} alt="image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                </figure>
                                                <div style={{ paddingLeft: '15px' }}>
                                                    <h4 className="fw-700 font-sm text-left" style={{ marginTop: '50px', marginLeft: '10px' }}>
                                                        {profileData?.user?.name}
                                                        <span className="fw-500 font-xssss text-gray-500 d-block mt-2">{profileData?.user?.email}</span>
                                                    </h4>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', gap: '10px', marginTop: '50px' }}>
                                                <Link to="/profile-settings" style={{ color: '#ffffff', borderRadius: '6px', padding: '5px 10px', border: 'none', backgroundColor: '#4267B2', borderColor: '#4267B2' }}>
                                                    Edit Profile
                                                </Link>
                                                <button onClick={handleForwardProfile} style={{ color: '#ffffff', borderRadius: '6px', padding: '5px 10px', border: 'none', backgroundColor: '#4267B2', borderColor: '#4267B2' }}>
                                                    Share Profile
                                                </button>
                                            </div>

                                            <ShareProfileComponent
                                                show={shareProfileModel}
                                                handleClose={() => setShowShareProfileModal(false)}
                                                profileData={profileData} // Pass the profile data here
                                            />
                                        </div>
                                    </div>
                                    <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                                        <ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
                                            <li className="active list-inline-item me-5"><Link to="/author-profile" className="fw-700 font-xssss text-gray-500 pt-3 pb-3 ls-1 d-inline-block active" href="#navtabs1" data-toggle="tab">Posts</Link></li>

                                            <li className="list-inline-item me-5"><Link to="/artist-videos" className="fw-700 font-xssss text-gray-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs2" data-toggle="tab">Videos</Link></li>
                                            <li className="list-inline-item me-5"><Link to="/artist-photos" className="fw-700 font-xssss text-gray-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs2" data-toggle="tab">Photos</Link></li>
                                            <li className="list-inline-item me-5"><Link to="/artist-music" className="fw-700 font-xssss text-gray-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs3" data-toggle="tab">Music</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                                <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3">
                                    <div className="card-body d-block p-4">
                                        <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">About</h4>
                                        <p className="fw-500 text-gray-500 lh-24 font-xssss mb-3 text-start">{profileData?.bio}</p>
                                        <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">Visibility</h4>
                                        <p className="fw-500 text-gray-500 lh-24 font-xssss mb-3 text-start">{profileData?.visibility}</p>
                                        {profileData?.handle?.length > 0 ? (
                                            <>
                                                <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">Handle</h4>
                                                <p className="fw-500 text-gray-500 lh-24 font-xssss mb-3 text-start">{profileData?.handle}</p>
                                            </>
                                        ) : null}


                                        {profileData?.awards && profileData.awards.length > 0 ? (
                                            <>
                                                <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">Awards</h4>
                                                <p className="fw-500 text-gray-500 lh-24 font-xssss mb-3 text-start">{profileData?.awards}</p>
                                            </>
                                        ) : null}

                                        {profileData?.message && profileData.message.length > 0 ? (
                                            <>
                                                <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">Message</h4>
                                                <p className="fw-500 text-gray-500 lh-24 font-xssss mb-3 text-start">{profileData?.message}</p>
                                            </>
                                        ) : null}


                                        {profileData?.website_link && profileData.website_link.length > 0 ? (
                                            <>
                                                <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">Website Link</h4>
                                                <p className='text-start font-xssss'>
                                                    <a
                                                        href={formatLink(profileData.website_link)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={linkStyle}
                                                        className="text-blue-500 hover:underline"
                                                    >
                                                        {profileData.website_link}
                                                    </a>
                                                </p>
                                            </>
                                        ) : null}
                                        {profileData?.social_links && profileData.social_links.length > 0 ? (
                                            <>
                                                <h4 className="fw-700 mb-2 font-xsss text-gray-900 text-start">Social Links</h4>
                                                <ul className="list-unstyled font-xssss text-start">
                                                    {profileData.social_links.map((link, index) => (
                                                        <li key={index} className="mb-1">
                                                            <a
                                                                href={formatLink(link)}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={linkStyle}
                                                                className="text-blue-500 hover:underline"
                                                            >
                                                                {link}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : null}





                                    </div>
                                </div>
                            </div>
                            {/* upload content section */}
                            <div className="col-xl-8 col-xxl-9 col-lg-8">
                                <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3 mt-3">
                                    <div className="card-body d-flex p-0 mt-0">
                                        <a href="#" onClick={() => { setType('video'); setIsModalOpen(true); }} className="d-flex align-items-center font-xssss fw-600 ls-1 text-gray-700 text-dark pe-4">
                                            <i className="font-md text-success feather-video me-2"></i><span className="d-none-xs">Videos</span>
                                        </a>
                                        <a href="#" onClick={() => { setType('photo'); setIsModalOpen(true); }} className="d-flex align-items-center font-xssss fw-600 ls-1 text-gray-700 text-dark pe-4">
                                            <i className="font-md text-warning feather-image me-2"></i><span className="d-none-xs">Photos</span>
                                        </a>
                                        <a href="#" onClick={() => { setType('music'); setIsModalOpen(true); }} className="d-flex align-items-center font-xssss fw-600 ls-1 text-gray-700 text-dark pe-4">
                                            <i className="font-md text-danger feather-music me-2"></i><span className="d-none-xs">Music</span>
                                        </a>
                                    </div>
                                </div>

                                {authorContent.map((content, index) => (
                                    <div key={index} className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"
                                        onMouseOver={() => handleVideoHover(index)}
                                        onMouseLeave={() => handleVideoLeave(index)}
                                    >
                                        <div className="card-body p-0 d-flex">
                                            <figure className="avatar me-3">
                                                <img
                                                    src={content.user.profile.profile_picture}
                                                    alt="image"
                                                    className="shadow-sm rounded-circle w45"
                                                    style={{ width: '45px', height: '45px', objectFit: 'cover' }}
                                                />
                                            </figure>


                                            <div className="d-flex flex-column">
                                                <h4 className="fw-700 text-gray-900 font-xsss text-start m-0">
                                                    {content.user ? content.user.name : 'Unknown User'}
                                                </h4>
                                                <span className="font-xssss fw-500 mt-1 text-gray-500 text-start">
                                                    {content.created_at_human}

                                                </span>
                                            </div>

                                        </div>
                                        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden video-container" style={{ position: 'relative' }} onClick={() => handleContentClick(content)}>
                                            {content.type === 'video' && content.file_path && (
                                                <video
                                                    ref={el => videoRefs.current[index] = el}
                                                    loop
                                                    className="float-right w-100"
                                                    controls
                                                    muted
                                                    onClick={() => handleVideoPlayPause(index)}
                                                    style={{ objectFit: 'contain', height: '400px' }}
                                                >
                                                    <source src={content.file_path} type="video/mp4" />
                                                </video>
                                            )}


                                            {/* Handle multiple or single photo rendering safely */}
                                            {content.type === 'photo' && content.file_path ? (
                                                Array.isArray(content.file_path) ? (
                                                    <div className={`photo-grid photo-grid-${content.file_path.length}`}>
                                                        {content.file_path.map((url, idx) => (
                                                            <img
                                                                key={idx}
                                                                src={url}
                                                                alt={`Uploaded Photo ${idx}`}
                                                                className="grid-photo"
                                                                onClick={() => handlePhotoClick(content.file_path, idx)}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    objectFit: 'cover',
                                                                    height: 'auto',
                                                                    aspectRatio: content.aspect_ratio || '1 / 1', // Dynamically set aspect ratio
                                                                    width: '100%'
                                                                }}
                                                            />
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <img
                                                        src={content.file_path}
                                                        alt="Uploaded Photo"
                                                        className="float-right w-100"
                                                        style={{
                                                            objectFit: 'cover',
                                                            height: 'auto',
                                                            aspectRatio: content.aspect_ratio || '1 / 1', // Dynamically set aspect ratio
                                                            width: '100%',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => handlePhotoClick([content.file_path], 0)}
                                                    />
                                                )
                                            ) : null}


                                            {content.type === 'music' && content.file_path ? (
                                                <audio controls className="float-right w-100">
                                                    <source src={content.file_path} type="audio/mpeg" />
                                                </audio>
                                            ) : (
                                                <></>
                                            )}

                                        </div>


                                        <div className="card-body p-0 me-lg-5">
                                            <p className="fw-500 text-gray-500 lh-26 font-xssss w-100 mb-2 text-start">
                                                {expanded ? content.description : truncateDescription(content.description || '', 150)}
                                                {content.description && content.description.length > 150 &&
                                                    <button className="btn text-gray-950 lh-26 font-xssss" onClick={handleToggleExpand}>
                                                        {expanded ? 'Less' : 'More'}
                                                    </button>
                                                }
                                            </p>
                                        </div>
                                        <div className="card-body d-flex p-0">
                                            <a href="#" className="d-flex align-items-center fw-600 text-gray-900 text-dark lh-26 font-xssss me-2">
                                                <i className="feather-eye text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i>
                                                {content.views_count !== null ? content.views_count : 0} {content.views_count === 1 ? 'View' : 'Views'}
                                            </a>
                                            <a href="#" onClick={(e) => { e.preventDefault(); handleLikeToggle(content.id, content.user_liked); }} className="d-flex align-items-center fw-600 text-gray-900 text-dark lh-26 font-xssss me-2">
                                                <i className={`feather-heart text-white ${content.user_liked ? 'bg-red-gradiant' : 'bg-grey'} me-1 btn-round-xs font-xss`}></i>
                                                {content.likes_count} {content.likes_count === 1 ? 'Like' : 'Likes'}
                                            </a>
                                            <a href="#" onClick={(e) => { e.preventDefault(); toggleCommentSection(content.id); }} className="d-flex align-items-center fw-600 text-gray-900 text-dark lh-26 font-xssss">
                                                <i className="feather-message-circle text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i>
                                                {/* <span className="d-none-xss">{commentVisible[content.id] ? 'Hide' : 'Comment'}</span> */}
                                                <span className="d-none-xss">{content.comments ? content.comments.length : 0} {commentVisible[content.id] ? 'Hide' : 'Comment'}</span>

                                            </a>

                                            <a href="#" onClick={(e) => { e.preventDefault(); handleShare(content); }} className="ms-auto d-flex align-items-center fw-600 text-gray-900 text-dark lh-26 font-xssss">
                                                <i className="feather-share-2 text-gray-900 text-dark btn-round-sm font-lg"></i>
                                                <span className="d-none-xs">Share</span>
                                            </a>
                                        </div>
                                        {commentVisible[content.id] && (
                                            <div className="card-body p-0 mt-3">
                                                <textarea
                                                    className="form-control comment-textarea"
                                                    rows="3"
                                                    placeholder="Write your comment"
                                                    value={commentText}
                                                    onChange={handleCommentChange}
                                                    style={{
                                                        borderRadius: '18px',
                                                        padding: '10px 15px',
                                                        border: '1px solid #ccc',
                                                        width: '100%',
                                                        lineHeight: '1.5', padding: '10px', height: '80px'
                                                    }}
                                                ></textarea>
                                                <button
                                                    className="btn btn-primary mt-2"
                                                    onClick={() => handleCommentSubmit(content.id)}
                                                    style={{
                                                        backgroundColor: '#1877f2',
                                                        color: '#fff',
                                                        borderRadius: '18px',
                                                        padding: '10px 20px',
                                                        border: 'none'
                                                    }}
                                                >
                                                    Post Comment
                                                </button>
                                            </div>
                                        )}
                                        {commentVisible[content.id] && (
                                            <div className="card-body p-0 mt-3">
                                                {displayedComments[content.id] && displayedComments[content.id].map((comment, i) => (
                                                    <div key={i} className="d-flex align-items-start mb-2" style={{ marginBottom: '10px' }}>
                                                        <figure className="avatar me-3">
                                                            <img
                                                                src={comment.user && comment.user.profile && comment.user.profile.profile_picture ? comment.user.profile.profile_picture : './assets/images/profile-4.png'}
                                                                alt="image"
                                                                className="shadow-sm rounded-circle w45"
                                                                style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                                                            />
                                                        </figure>
                                                        <div className="comment-content" style={{
                                                            background: '#f0f2f5',
                                                            borderRadius: '18px',
                                                            padding: '10px 15px',
                                                            maxWidth: '90%'
                                                        }}>
                                                            <h6 className="fw-700 text-gray-900 font-xssss mt-1 mb-1 text-start" style={{
                                                                margin: '0 0 5px 0',
                                                                fontSize: '14px',
                                                                fontWeight: '700'
                                                            }}>
                                                                {comment.user ? comment.user.name : 'Unknown User'}
                                                            </h6>
                                                            <p className="fw-500 text-gray-500 lh-26 font-xssss mb-0 text-start" style={{
                                                                margin: '0',
                                                                fontSize: '14px'
                                                            }}>
                                                                {comment.comment}
                                                            </p                                                            >
                                                            <div className="d-flex align-items-center mt-1 text-start" style={{ marginTop: '5px' }}>
                                                                <span className="d-block font-xssss fw-500 text-gray-500" style={{
                                                                    fontSize: '12px',
                                                                    color: '#65676b',
                                                                }}>
                                                                    {comment.created_at_human}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {comments[content.id] && comments[content.id].length > 3 && (
                                                    <button className="btn btn-primary mt-2" style={{
                                                        backgroundColor: '#1877f2',
                                                        color: '#fff',
                                                        borderRadius: '5px',
                                                        padding: '5px 10px',
                                                        border: 'none'
                                                    }}>
                                                        <Link to={`/all-comments/${content.id}`} className='text-white'>Show all {comments[content.id].length} comments</Link>
                                                    </button>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* modal code */}
            <Modal
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
                centered
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                style={{
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    borderRadius: '20px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Modal.Header closeButton style={{ borderBottom: 'none' }}>
                    <Modal.Title className="w-100 text-center" style={{ fontWeight: 'bolder' }}>Create Post - {type.charAt(0).toUpperCase() + type.slice(1)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleUploadContent}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Group>
                                    <Form.Label className="w-100 text-left">Title <span className='text-red'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        placeholder="Title"
                                        required
                                        style={{
                                            lineHeight: '35px',
                                            borderRadius: '10px',
                                            border: 'none',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="w-100 text-left">Description <span className='text-red'>*</span></Form.Label>
                                    <div style={{ position: 'relative' }}>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            placeholder="What's on your mind?"
                                            required
                                            style={{
                                                lineHeight: '1.5',
                                                padding: '10px',
                                                height: '80px',
                                                borderRadius: '10px',
                                                border: 'none',
                                                boxShadow: 'none',
                                                backgroundColor: 'transparent',
                                                resize: 'none',
                                                outline: 'none',
                                                fontSize: '16px',
                                                fontFamily: 'Arial, sans-serif',
                                            }}
                                        />
                                        <Button
                                            variant="link"
                                            style={{ position: 'absolute', right: '5px', top: '40px' }}
                                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                        >
                                            😀
                                        </Button>
                                    </div>
                                    {showEmojiPicker && (
                                        <div style={{ position: 'absolute', zIndex: 1000 }}>
                                            <EmojiPicker onEmojiClick={handleEmojiClick} />
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                            {(type === 'video' || type === 'photo') && (
                                <Form.Group className="mt-3">
                                    <Form.Label className="w-100 text-left">Aspect Ratio <span className='text-red'>*</span></Form.Label>
                                    <Form.Control
                                        className="form-select"
                                        as="select"
                                        value={aspectRatio}
                                        onChange={(e) => setAspectRatio(e.target.value)}
                                        required
                                        style={{
                                            lineHeight: '35px',
                                            borderRadius: '10px',
                                            border: 'none',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            borderColor: 'gray',
                                        }}
                                    >
                                        <option value="">Select Ratio</option>
                                        <option value="16:9">16:9 Widescreen</option>
                                        <option value="9:16">9:16 Vertical Mobile</option>
                                        <option value="1:1">1:1 Square</option>
                                        <option value="3:4">3:4 Portrait V</option>
                                        <option value="4:3">4:3 Portrait H</option>
                                        <option value="2:3">2:3 Classic</option>
                                        <option value="3:2">3:2 TV</option>
                                    </Form.Control>
                                </Form.Group>
                            )}

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Genres <span className='text-red'>*</span></Form.Label>
                                <Select
                                    options={subcategories}
                                    isMulti
                                    value={selectedGenres.map(id => subcategories.flatMap(cat => cat.options).find(sub => sub.value === id))}
                                    onChange={handleGenreChange}
                                    placeholder="Select Genres"
                                    classNamePrefix="react-select"
                                    isClearable
                                    className='text-left multiple-input'
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            lineHeight: '35px',
                                            borderColor: '#9191AF',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            boxShadow: 'none',
                                        }),
                                    }}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Technology <span className='text-red'>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={technology}
                                    onChange={(e) => setTechnology(e.target.value)}
                                    placeholder="Technology"
                                    required
                                    style={{
                                        lineHeight: '35px',
                                        borderRadius: '10px',
                                        border: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: 'transparent',
                                    }}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Medium</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={medium}
                                    onChange={(e) => setMedium(e.target.value)}
                                    placeholder="Medium"
                                    style={{
                                        lineHeight: '35px',
                                        borderRadius: '10px',
                                        border: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: 'transparent',
                                    }}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Keyword Tags <span className='text-red'>*</span></Form.Label>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Form.Control
                                        type="text"
                                        value={keywordInput}
                                        onChange={(e) => setKeywordInput(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        placeholder="Enter a tag and press Enter"
                                        style={{
                                            lineHeight: '35px',
                                            borderRadius: '10px',
                                            border: 'none',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            marginBottom: '10px',
                                        }}
                                    />
                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                        {keywordTags.map((tag, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', background: '#f0f2f5', padding: '5px 10px', borderRadius: '15px' }}>
                                                {tag}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveTag(index)}
                                                    style={{ marginLeft: '5px', background: 'transparent', border: 'none', cursor: 'pointer', color: 'crimson' }}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Collaborators <span className='text-red'>*</span></Form.Label>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Form.Control
                                        type="text"
                                        value={collaboratorInput}
                                        onChange={(e) => setCollaboratorInput(e.target.value)}
                                        onKeyPress={handleCollaboratorKeyPress}
                                        placeholder="Enter a name and press Enter"
                                        style={{
                                            lineHeight: '35px',
                                            borderRadius: '10px',
                                            border: 'none',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            marginBottom: '10px',
                                        }}
                                    />
                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                        {collaborators.map((collaborator, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', background: '#f0f2f5', padding: '5px 10px', borderRadius: '15px' }}>
                                                {collaborator}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveCollaborator(index)}
                                                    style={{ marginLeft: '5px', background: 'transparent', border: 'none', cursor: 'pointer', color: 'crimson' }}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Language <span className='text-red'>*</span></Form.Label>
                                <Select
                                    options={languages}
                                    value={selectedLanguage}
                                    onChange={setSelectedLanguage}
                                    placeholder="Select Language"
                                    classNamePrefix="react-select"
                                    isClearable
                                    className='text-left input-selector'
                                    required
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            borderColor: '#9191AF',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            lineHeight: '35px',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            border: '1px solid #9191AF',
                                            boxShadow: 'none',
                                        }),
                                    }}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Location <span className='text-red'>*</span></Form.Label>
                                <Select
                                    options={locations}
                                    value={selectedLocation}
                                    onChange={setSelectedLocation}
                                    placeholder="Select Location"
                                    classNamePrefix="react-select"
                                    isClearable
                                    className='text-left'
                                    required
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            borderColor: '#9191AF',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            lineHeight: '35px',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            border: '1px solid #ced4da',
                                            boxShadow: 'none',
                                        }),
                                    }}
                                />
                            </Form.Group>

                            <style>
                                {`
          
        `}
                            </style>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Content Visibility <span className='text-red'>*</span></Form.Label>
                                <Form.Control
                                    className="form-select"
                                    as="select"
                                    value={contentVisibility}
                                    onChange={(e) => setContentVisibility(e.target.value)}
                                    required
                                    style={{
                                        lineHeight: '35px',
                                        borderRadius: '10px',
                                        border: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    <option value="">Select Visibility</option>
                                    <option value="public">Public</option>
                                    <option value="private">Private</option>
                                    <option value="friends">Friends</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group className="mt-3">
                            <Form.Label className="w-100 text-left">Select {type.charAt(0).toUpperCase() + type.slice(1)}</Form.Label>
                                <Form.Control
                                    className="input-file mb-2"
                                    type="file"
                                    id="file"
                                    accept={getAcceptType()}
                                    onChange={handleFileChange}
                                    multiple={type === 'photo'}
                                    required
                                    style={{ display: 'none' }}
                                />
                                <label
                                    htmlFor="file"
                                    className="text-center btn-tertiary js-labelFile w-100 mb-2"
                                    style={{ border: '1px dashed #ced4da', padding: '10px', borderRadius: '10px', backgroundSize: 'cover', backgroundPosition: 'center' }}
                                >
                                    {!fileUrl.length && (
                                        <>
                                            <i className="x1b0d499 xep6ejk" style={iconStyle} data-visualcompletion="css-img"></i>
                                            <span className="js-fileName">Drag and drop {type} file to upload</span>
                                        </>
                                    )}
                                    {fileUrl?.length > 0 && type === 'photo' && (
                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                            {fileUrl.map((url, index) => (
                                                <div key={index} style={{ position: 'relative' }}>
                                                    <img src={url} alt={`preview-${index}`} style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }} />
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            const newFiles = file.filter((_, i) => i !== index);
                                                            const newUrls = fileUrl.filter((_, i) => i !== index);
                                                            setFile(newFiles);
                                                            setFileUrl(newUrls);
                                                        }}
                                                        style={{ position: 'absolute', top: '0', right: '0', background: 'red', color: 'white', borderRadius: '50%', cursor: 'pointer' }}
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}


                                    {fileUrl?.length > 0 && type !== 'photo' && (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {type === 'video' ? (
                                                <video src={fileUrl[0]} controls style={{ width: '100%', maxHeight: '300px', marginBottom: '10px' }} />
                                            ) : (
                                                <audio src={fileUrl[0]} controls style={{ width: '100%', marginBottom: '10px' }} />
                                            )}
                                            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm text-white"
                                                    onClick={() => document.getElementById('file').click()}
                                                >
                                                    Replace
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm text-white"
                                                    onClick={() => { setFile([]); setFileUrl([]); }}
                                                >
                                                    Remove {type.charAt(0).toUpperCase() + type.slice(1)}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </label>
                            </Form.Group>

                        </div>
                        <Button variant="primary" type="submit" className="mt-3 text-white" style={{ borderRadius: '10px', backgroundColor: '#4267B2', borderColor: '#4267B2' }}>
                            {loading ? 'Uploading...' : 'Upload'}
                        </Button>
                    </Form>
                </Modal.Body>

            </Modal>

            <>
                <ShareComponent show={showShareModal} handleClose={() => setShowShareModal(false)} shareData={shareData} />
            </>

            <Modal show={showPhotoModal} onHide={handleClosePhotoModal} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Photo Gallery</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel activeIndex={currentPhotoIndex} onSelect={(selectedIndex) => setCurrentPhotoIndex(selectedIndex)}>
                        {selectedPhotos.map((photo, idx) => (
                            <Carousel.Item key={idx}>
                                <img
                                    src={photo}
                                    alt={`Photo ${idx}`}
                                    className="d-block w-100"
                                    style={{ maxHeight: '80vh', objectFit: 'contain' }}
                                />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePhotoModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <style>
                {`
            .input-selector:active {
                border-color: red;
            }
          .photo-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Dynamically adjust column size */
    gap: 10px; /* Adjust gap between images */
    margin-top: 10px;
}

.grid-photo {
    width: 100%;
    height: auto; /* Let height adjust dynamically based on width */
    object-fit: cover; /* Maintain aspect ratio without distortion */
    border-radius: 8px;
    transition: transform 0.3s ease; /* Add smooth zoom effect */
}

.grid-photo:hover {
    transform: scale(1.05); /* Zoom on hover */
}

/* Layout for exactly 1 photo */
.photo-grid-1 {
    grid-template-columns: 1fr; /* Single column */
}

/* Layout for exactly 2 photos */
.photo-grid-2 {
    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
}

/* Layout for exactly 3 photos */
 /* Layout for 3 photos */
                            .photo-grid-3 {
                                display: grid;
                                grid-template-columns: 1fr 1fr; /* Two equal columns */
                                grid-template-rows: auto auto; /* Two rows, first for large image, second for smaller images */
                                grid-gap: 10px; /* Space between images */
                            }

                            .photo-grid-3 img:first-child {
                                grid-column: 1 / -1; /* Span the first image across both columns */
                                width: 100%;
                                height: 200px; /* Adjust the height as needed */
                                object-fit: cover;
                            }

                            .photo-grid-3 img:nth-child(2), .photo-grid-3 img:nth-child(3) {
                                width: 100%;
                                height: 150px; /* Adjust height to be smaller than the first image */
                                object-fit: cover;
                            }

                            /* Responsive behavior for smaller screens */
                            @media (max-width: 768px) {
                                .photo-grid-3 {
                                    grid-template-columns: 1fr; /* Stack images in one column on small screens */
                                }
                            }

                        /* Layout for exactly 4 photos */
                        .photo-grid-4 {
                            grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
                        }

                        @media (max-width: 768px) {
                            .photo-grid, .photo-grid-2, .photo-grid-3, .photo-grid-4 {
                                grid-template-columns: 1fr; /* Stack all photos in a single column */
                            }
                        }

                        .react-select__control--is-focused {
                            border-color: red !important;
                        }
                        .react-select__menu {
                            border-radius: 10px !important;
                        }
                        .react-select__control {
                            border-radius: 10px !important;
                            border: 1px solid #9191AF !important;
                        }
        `}
            </style>

        </div>
    );
}
