import React, { useState, useEffect, useRef } from 'react';
import PreLoader from '../Components/PreLoader';
import { debounce } from 'lodash';
import axios from 'axios';
import Helpers from '../Config/Helpers';
import ShareComponent from '../Components/ShareComponent';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button, Carousel } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import Bookmark from '../Components/Bookmark';
import FeaturedCard from '../Components/FeaturedCard';

function Home() {
    const [loading, setLoading] = useState(true);
    const [contents, setContents] = useState([]);
    const [comments, setComments] = useState({});
    const [commentText, setCommentText] = useState("");
    const [commentVisible, setCommentVisible] = useState({});
    const [showShareModal, setShowShareModal] = useState(false);
    const [shareData, setShareData] = useState({});
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const playerRefs = useRef([]);
    const playPauseLocks = useRef([]);
    const [displayedComments, setDisplayedComments] = useState({});
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);


    const handleVideoHover = debounce(async (index) => {
        const player = playerRefs.current[index];
        if (player) {
            player.seekTo(0); // Start playing from the beginning
            if (!playPauseLocks.current[index]) {
                playPauseLocks.current[index] = true;
                try {
                    await player.getInternalPlayer().play();
                } catch (error) {
                    console.log('Video play interrupted', error);
                } finally {
                    playPauseLocks.current[index] = false;
                }
            }
        }
    }, 300);

    const handleVideoLeave = debounce(async (index) => {
        const player = playerRefs.current[index];
        if (player) {
            if (!playPauseLocks.current[index]) {
                playPauseLocks.current[index] = true;
                try {
                    await player.getInternalPlayer().pause();
                } catch (error) {
                    console.log('Video pause interrupted', error);
                } finally {
                    playPauseLocks.current[index] = false;
                }
            }
        }
    }, 300);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}auth/contents`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                // console.log(response.data.data, 'data');
                setContents(response.data.data);
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to fetch contents');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    const handleUserDetail = (content) => {
        // Retrieve the authenticated user from localStorage
        const authUser = JSON.parse(localStorage.getItem('user'));
        const userId = authUser?.data?.id;
        if (userId && content.user) {
            // Compare the IDs
            if (userId === content.user.user_id) {
                navigate('/author-profile');
            } else {
                navigate(`/user-detail/${content.user.user_id}`);
            }
        } else {
            console.log('User information not found');
        }
    };


    const toggleCommentSection = async (contentId) => {
        if (!commentVisible[contentId]) {
            try {
                const response = await axios.get(`${Helpers.apiUrl}auth/get-comments?content_id=${contentId}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setComments((prev) => ({
                    ...prev,
                    [contentId]: response.data.data
                }));
                setDisplayedComments((prev) => ({
                    ...prev,
                    [contentId]: response.data.data.slice(0, 3) // display only the first 3 comments
                }));
            } catch (error) {
                Helpers.toast('error', 'Failed to fetch comments');
            }
        }
        setCommentVisible((prev) => ({
            ...prev,
            [contentId]: !prev[contentId]
        }));
    };

    const handleCommentChange = (e) => {
        setCommentText(e.target.value);
    };

    const handleCommentSubmit = async (contentId) => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/comments`, {
                content_id: contentId,
                comment: commentText
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 201) {
                const newComment = response.data.data;
                console.log('new comment', newComment);

                setComments((prev) => ({
                    ...prev,
                    [contentId]: [newComment, ...(prev[contentId] || [])]
                }));
                setDisplayedComments((prev) => ({
                    ...prev,
                    [contentId]: [newComment, ...(prev[contentId] || []).slice(0, 2)] // Include the new comment
                }));
                setCommentText("");
                setCommentVisible((prev) => ({
                    ...prev,
                    [contentId]: true
                }));

                fetchData();
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to post comment');
        }
    };

    const handleLike = async (contentId) => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/like`, {
                content_id: contentId,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 201 || response.status === 200) {
                setContents(prevContents =>
                    prevContents.map(content =>
                        content.id === contentId
                            ? { ...content, user_liked: true, likes_count: content.likes_count + 1 }
                            : content
                    )
                );
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to post like');
        }
    };

    const handleUnlike = async (contentId) => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/unlike`, {
                content_id: contentId,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.status === 200) {
                setContents(prevContents =>
                    prevContents.map(content =>
                        content.id === contentId
                            ? { ...content, user_liked: false, likes_count: content.likes_count - 1 }
                            : content
                    )
                );
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to unlike content');
        }
    };

    const handleLikeToggle = (contentId, userLiked) => {
        if (userLiked) {
            handleUnlike(contentId);
        } else {
            handleLike(contentId);
        }
    };

    const handleShare = (content) => {
        const shareData = {
            title: content.description || 'Content Title',
            text: content.description || 'Content Description',
            url: `${window.location.origin}/content/${content.id}`,
            content_id: content.id // Make sure content_id is included here
        };

        setShareData(shareData);
        setShowShareModal(true);
    };

    const truncateDescription = (description, length) => {
        if (!description) return '';
        return description.length > length ? `${description.substring(0, length)}...` : description;
    };

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    const setShowAllComments = (contentId) => {
        setDisplayedComments((prev) => ({
            ...prev,
            [contentId]: comments[contentId]
        }));
    };

    const handleContentClick = async (content) => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/content/increment-view/${content.id}`, {}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            // Only update local state if the backend confirms the increment
            if (response.data.views_count !== undefined) {
                setContents(prevContents =>
                    prevContents.map(c =>
                        c.id === content.id
                            ? { ...c, views_count: response.data.views_count }
                            : c
                    )
                );
            }
    
            if (content.type === 'photo') {
                const filePath = content.file_path;
    
                // Ensure filePath is always an array
                const photosArray = Array.isArray(filePath)
                    ? filePath
                    : [filePath]; // Wrap single photo in array
    
                setSelectedPhotos(photosArray);
                setCurrentPhotoIndex(0); // Change as needed for multiple photos
                setShowPhotoModal(true);
            } else if (content.type === 'video') {
                navigate(`/video/${content.id}`);
            } else if (content.type === 'music') {
                // Handle music type if needed
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to increment view count');
        }
    };
    


    const handleBookmarkToggle = (contentId, status) => {
        setContents(prevContents =>
            prevContents.map(content =>
                content.id === contentId
                    ? { ...content, user_bookmarked: status }
                    : content
            )
        );
    };

    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className="container">
                    <div className="row mt-3 justify-content-between">
                        <div className="col-lg-8">
                            <div className="card w-100 shadow-none bg-transparent bg-transparent-card border-0 p-0 mb-0">
                                <div className="owl-carousel category-card owl-theme overflow-hidden nav-none">
                                </div>
                            </div>
                            {contents
                                .filter(content => content.content_visibility === 'public')
                                .map((content, index) => (
                                    <div key={index} className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"
                                        onMouseOver={() => handleVideoHover(index)}
                                        onMouseLeave={() => handleVideoLeave(index)}
                                    >
                                        <div className="card-body p-0 d-flex">
                                            <figure className="avatar me-3">
                                                <img
                                                    src={content.user && content.user.profile && content.user.profile.profile_picture ? content.user.profile.profile_picture : './assets/images/profile-4.png'}
                                                    alt="image"
                                                    className="shadow-sm rounded-circle w45"
                                                    style={{ width: '45px', height: '45px', objectFit: 'cover' }}
                                                />
                                            </figure>

                                            <div className="d-flex flex-column">
                                                <h4 className="fw-700 text-gray-900 font-xsss text-start m-0" onClick={() => { handleUserDetail(content); }} style={{ cursor: 'pointer' }} >
                                                    {content.user ? content.user.name : 'Unknown User'}
                                                </h4>
                                                <span className="font-xssss fw-500 mt-1 text-gray-500 text-start">
                                                    {content.created_at_human}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="card-body p-0 mb-3 rounded-3 overflow-hidden video-container" style={{ position: 'relative' }} onClick={() => handleContentClick(content)}>
                                            {content.type === 'video' ? (
                                                <Link to={`/video/${content.id}`}>
                                                    <ReactPlayer
                                                        ref={el => playerRefs.current[index] = el}
                                                        url={content.file_path}
                                                        loop
                                                        controls
                                                        muted
                                                        width="100%"
                                                        height="400px"
                                                        style={{ objectFit: 'contain' }}
                                                    />
                                                </Link>
                                            ) : content.type === 'photo' && Array.isArray(content.file_path) ? (
                                                <div className={`photo-grid photo-grid-${content.file_path.length}`}>
                                                    {content.file_path.map((url, idx) => (
                                                        <img
                                                            key={idx}
                                                            src={url}
                                                            alt={`Uploaded Photo ${idx}`}
                                                            className="grid-photo"
                                                            onClick={() => {
                                                                setSelectedPhotos(content.file_path);
                                                                setCurrentPhotoIndex(idx);
                                                                setShowPhotoModal(true);
                                                            }}
                                                            style={{
                                                                cursor: 'pointer',
                                                                aspectRatio: content.aspect_ratio || '1 / 1', // Set the aspect ratio dynamically
                                                                width: '100%',
                                                                height: 'auto', // Maintain aspect ratio based on width
                                                            }}
                                                        />
                                                    ))}
                                                </div>
                                            ) : content.type === 'photo' ? (
                                                <img
                                                    src={content.file_path}
                                                    alt="photo"
                                                    className="float-right w-100"
                                                    style={{
                                                        objectFit: 'contain',
                                                        cursor: 'pointer',
                                                        aspectRatio: content.aspect_ratio || '1 / 1', // Set aspect ratio for single photo
                                                        width: '100%',
                                                        height: 'auto', // Maintain aspect ratio based on width
                                                    }}
                                                    onClick={() => {
                                                        setSelectedPhotos([content.file_path]);
                                                        setCurrentPhotoIndex(0);
                                                        setShowPhotoModal(true);
                                                    }}
                                                />
                                            ) : content.type === 'music' && (
                                                <audio
                                                    controls
                                                    className="float-right w-100"
                                                >
                                                    <source src={content.file_path} type="audio/mpeg" />
                                                </audio>
                                            )}
                                        </div>

                                        <div className="card-body p-0 me-lg-5">
                                            <p className="fw-500 text-gray-700 lh-26 font-xssss w-100 mb-2 text-start">
                                                {expanded ? content.description : truncateDescription(content.description || '', 150)}
                                                {content.description && content.description.length > 180 &&
                                                    <button className="btn text-gray-900 lh-26 font-xssss" onClick={handleToggleExpand}>
                                                        {expanded ? 'Less' : 'More'}
                                                    </button>
                                                }
                                            </p>
                                        </div>

                                        <div className="card-body d-flex p-0">
                                            <a href="#" className="d-flex align-items-center fw-600 text-gray-900 text-dark lh-26 font-xssss me-2">
                                                <i className="feather-eye text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i>
                                                {content.views_count !== null ? content.views_count : 0} {content.views_count === 1 ? 'View' : 'Views'}
                                            </a>
                                            <a href="#" onClick={(e) => { e.preventDefault(); handleLikeToggle(content.id, content.user_liked); }} className="d-flex align-items-center fw-600 text-gray-900 text-dark lh-26 font-xssss me-2">
                                                <i className={`feather-heart text-white ${content.user_liked ? 'bg-red-gradiant' : 'bg-grey'} me-1 btn-round-xs font-xss`}></i>
                                                {content.likes_count} {content.likes_count === 1 ? 'Like' : 'Likes'}
                                            </a>
                                            <a href="#" onClick={(e) => { e.preventDefault(); toggleCommentSection(content.id); }} className="d-flex align-items-center fw-600 text-gray-900 text-dark lh-26 font-xssss me-2">
                                                <i className="feather-message-circle text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i>
                                                <span className="d-none-xss">{content.comments ? content.comments.length : 0} {commentVisible[content.id] ? 'Hide' : 'Comment'}</span>
                                            </a>
                                            <div className="ms-auto d-flex">
                                                <Bookmark content={content} onBookmarkToggle={handleBookmarkToggle} />
                                                <a href="#" onClick={(e) => { e.preventDefault(); handleShare(content); }} className="d-flex align-items-center fw-600 text-gray-900 text-dark lh-26 font-xssss ms-2">
                                                    <i className="feather-share-2 text-gray-900 text-dark btn-round-sm font-xss"></i>
                                                    <span className="d-none-xs">Share</span>
                                                </a>
                                            </div>
                                        </div>
                                        {commentVisible[content.id] && (
                                            <div className="card-body p-0 mt-3">
                                                <textarea
                                                    className="form-control comment-textarea"
                                                    rows="3"
                                                    placeholder="Write your comment"
                                                    value={commentText}
                                                    onChange={handleCommentChange}
                                                    style={{
                                                        borderRadius: '10px',
                                                        padding: '10px 15px',
                                                        border: '1px solid #ccc',
                                                        width: '100%',
                                                        lineHeight: '1.5', padding: '10px', height: '80px'
                                                    }}
                                                ></textarea>
                                                <button
                                                    className="btn btn-primary mt-2"
                                                    onClick={() => handleCommentSubmit(content.id)}
                                                    style={{
                                                        backgroundColor: '#1877f2',
                                                        color: '#fff',
                                                        borderRadius: '10px',
                                                        padding: '10px 20px',
                                                        border: 'none'
                                                    }}
                                                >
                                                    Post Comment
                                                </button>
                                            </div>
                                        )}
                                        {commentVisible[content.id] && (
                                            <div className="card-body p-0 mt-3">
                                                {displayedComments[content.id] && displayedComments[content.id].map((comment, i) => (
                                                    <div key={i} className="d-flex align-items-start mb-2" style={{ marginBottom: '10px' }}>
                                                        <figure className="avatar me-3">
                                                            <img
                                                                src={comment.user && comment.user.profile && comment.user.profile.profile_picture ? comment.user.profile.profile_picture : './assets/images/profile-4.png'}
                                                                alt="image"
                                                                className="shadow-sm rounded-circle w45"
                                                                style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                                                            />
                                                        </figure>
                                                        <div className="comment-content" style={{
                                                            background: '#f0f2f5',
                                                            borderRadius: '10px',
                                                            padding: '10px 15px',
                                                            maxWidth: '90%'
                                                        }}>
                                                            <h6 className="fw-700 text-gray-900 font-xssss mt-1 mb-1 text-start" style={{
                                                                margin: '0 0 5px 0',
                                                                fontSize: '14px',
                                                                fontWeight: '700'
                                                            }}>
                                                                {comment.user ? comment.user.name : 'Unknown User'}
                                                            </h6>
                                                            <p className="fw-500 text-gray-500 lh-26 font-xssss mb-0 text-start" style={{
                                                                margin: '0',
                                                                fontSize: '14px'
                                                            }}>
                                                                {comment.comment}
                                                            </p>
                                                            <div className="d-flex align-items-center mt-1 text-start" style={{ marginTop: '5px' }}>
                                                                <span className="d-block font-xssss fw-500 text-gray-500" style={{
                                                                    fontSize: '12px',
                                                                    color: '#65676b',
                                                                }}>
                                                                    {comment.commented_at_human}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {comments[content.id] && comments[content.id].length > 3 && (
                                                    <button className="btn btn-primary mt-2" style={{
                                                        backgroundColor: '#1877f2',
                                                        color: '#fff',
                                                        borderRadius: '5px',
                                                        padding: '5px 10px',
                                                        border: 'none'
                                                    }}>
                                                        <Link to={`/all-comments/${content.id}`} className='text-white'>Show all {comments[content.id].length} comments</Link>
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))
                            }

                        </div>
                        <div className="col-lg-4">
                            <FeaturedCard />
                        </div>
                    </div>
                </div>

            )}
            {showShareModal && (
                <ShareComponent show={showShareModal} handleClose={() => setShowShareModal(false)} shareData={shareData} />
            )}
            {selectedPhotos.length > 0 && (
                <Modal show={showPhotoModal} onHide={() => setShowPhotoModal(false)} size="md" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Photo Viewer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedPhotos.length === 1 ? (
                            <img
                                src={selectedPhotos[0]}
                                alt="Selected"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '70vh',
                                    display: 'block',
                                    margin: 'auto'
                                }}
                            />
                        ) : (
                            <Carousel activeIndex={currentPhotoIndex} onSelect={(selectedIndex) => setCurrentPhotoIndex(selectedIndex)}>
                                {selectedPhotos.map((url, idx) => (
                                    <Carousel.Item key={idx}>
                                        <img
                                            src={url}
                                            alt={`Photo ${idx + 1}`}
                                            className="d-block w-100"
                                            style={{
                                                maxHeight: '70vh',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowPhotoModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <style>{`
                    .featured-section {
                        position: fixed;
                        right: 0;
                    }
                    .photo-grid {
                        display: grid;
                        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive columns */
                        grid-gap: 10px; /* Space between photos */
                        margin-top: 10px;
                    }

                   .grid-photo {
                        /* These settings will make the image maintain its aspect ratio */
                        object-fit: cover;
                        border-radius: 8px;
                        max-width: 100%;
                    }

                    

                        .grid-photo:hover {
                            transform: scale(1.05); /* Slight zoom on hover */
                            transition: transform 0.3s ease;
                        }

                        /* Default photo styles */
                        .photo-grid img {
                            width: 100%;
                        
                            border-radius: 8px;
                        }

                        /* Layout for 1 photo */
                        .photo-grid-1 {
                            display: grid;
                            grid-template-columns: 1fr;
                        }

                        /* Layout for 2 photos */
                        .photo-grid-2 {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            grid-gap: 10px;
                        }

                        /* Layout for 3 photos */
                            .photo-grid-3 {
                                display: grid;
                                grid-template-columns: 1fr 1fr; /* Two equal columns */
                                grid-template-rows: auto auto; /* Two rows, first for large image, second for smaller images */
                                grid-gap: 10px; /* Space between images */
                            }

                            .photo-grid-3 img:first-child {
                                grid-column: 1 / -1; /* Span the first image across both columns */
                                width: 100%;
                                height: 200px; /* Adjust the height as needed */
                                object-fit: cover;
                            }

                            .photo-grid-3 img:nth-child(2), .photo-grid-3 img:nth-child(3) {
                                width: 100%;
                                height: 150px; /* Adjust height to be smaller than the first image */
                                object-fit: cover;
                            }
                                /* Layout for exactly 4 photos */
                                .photo-grid-4 {
                                    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
                                }

                            /* Responsive behavior for smaller screens */
                            @media (max-width: 768px) {
                                .photo-grid-3 {
                                    grid-template-columns: 1fr; /* Stack images in one column on small screens */
                                }
                            }

                        @media (max-width: 991px) {
                            .featured-section {
                                position: relative;
                                margin-top: 20px;
                            }
                        }
                        @media (max-width: 768px) {
                            .photo-grid-2, .photo-grid-3, .photo-grid-4 {
                                grid-template-columns: 1fr; /* Stack all photos in a single column */
                            }
                        }

            `}</style>
        </div>
    );
}

export default Home;
