import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Config/Helpers';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        // Regex for validating email format
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = [];
        if (!email) {
            validationErrors.push('Email is required');
        } else if (!validateEmail(email)) {
            validationErrors.push('Please enter a valid email address');
        }

        if (validationErrors.length > 0) {
            const errorMessage = validationErrors.join('<br />');
            Helpers.toast('error', errorMessage);
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(`${Helpers.apiUrl}password/reset-link`, { email });

            if (response.status === 200) {
                Helpers.toast('success', 'OTP Code Sent Successfully');
                localStorage.setItem('resetEmail', email);
                navigate('/verify-code');
            }
        } catch (error) {
            // Handle errors
            const errorMessage = error.response?.data?.errors || 'Password reset request failed';
            Helpers.toast('error', errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main-wrap">
            <div className="nav-header bg-transparent shadow-none border-0">
                <div className="nav-top w-100 d-flex justify-content-between align-items-center">
                <a href="/" className="flex items-center">
                        <img
                            src="./assets/logo/blackbox-logo.png"
                            alt="Blackbox Logo"
                            className="h-10 mr-2"
                            style={{ width: '150px', display: 'block' }}
                        />

                    </a>
                </div>
            </div>
            <div className="row">
                {/* <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat" style={{ backgroundImage: 'url(./assets/images/login-bg-2.jpg)' }}></div> */}
                <div 
    className="col-xl-5 d-none d-xl-block p-0 vh-50 bg-image-cover bg-no-repeat" 
    style={{ 
        backgroundImage: 'url(./assets/logo/forget-password.png)', 
        width: '40%', 
        backgroundSize: 'cover', // or 'contain' depending on the desired effect
        backgroundPosition: 'center',
        height: '100vh' // Ensure the height fits the viewport height
    }}
></div>
                <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                    <div className="card shadow-none border-0 ms-auto me-auto login-card">
                        <div className="card-body rounded-0 text-left">
                            <h2 className="fw-700 display1-size display2-md-size mb-4">Forgot Password</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group icon-input mb-3">
                                    <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                    <input
                                        type="email"
                                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                        placeholder="Enter Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete="email"
                                    />
                                </div>
                                <div className="col-sm-12 p-0 text-left">
                                    <div className="form-group mb-1">
                                        <button type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0">
                                            {loading ? 'Sending...' : 'Send'}
                                        </button>
                                    </div>
                                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                        Remember your password?
                                        <Link to="/login" className="fw-700 ms-1">Login</Link>
                                    </h6>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
