import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../Config/Helpers';
import Sidebar from './Sidebar';
import HeaderNotification from './HeaderNotifications';
import debounce from 'lodash.debounce';

function Header() {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState('');
  const [authUser, setAuthUser] = useState(null);
  const [profilePicture, setProfilePicture] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false); // Dropdown state
  const navigate = useNavigate();
  const location = useLocation(); // to check the current route
  const formRef = useRef(null); // Reference for the form element
  const dropdownRef = useRef(null); // Reference for the dropdown element

  useEffect(() => {
    const fetchAuthUser = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setAuthUser(null);
        return;
      }

      try {
        const response = await axios.get(`${Helpers.apiUrl}auth/auth-user-detail`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const userData = response.data.data;
        setAuthUser(userData);
    
        if (userData && userData.profile && userData.profile.profile_picture) {
          setProfilePicture(`${userData.profile.profile_picture}`);
        }
      } catch (error) {
        console.error('There was an error fetching the authenticated user!', error);
        setAuthUser(null);
      }
    };

    fetchAuthUser();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchSuggestions = useCallback(
    debounce(async (search) => {
      const token = localStorage.getItem('token');

      try {
        const response = await axios.get(`${Helpers.apiUrl}auth/search-suggestions`, {
          params: { search },
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setSuggestions(response.data.suggestions);
      } catch (error) {
        console.error('There was an error fetching the suggestions!', error);
      }
    }, 300), // 300ms delay
    []
  );

  const handleFilter = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${Helpers.apiUrl}auth/search`, {
        params: { search: searchTerm },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setSuggestions([]); // Clear suggestions
      navigate('/search-results', { state: { results: response.data } });
    } catch (error) {
      console.error('There was an error fetching the contents!', error);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length > 2) { // Fetch suggestions if the input length is greater than 2
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    setSearchTerm(suggestion.description);
    setSuggestions([]);

    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${Helpers.apiUrl}auth/search`, {
        params: { search: suggestion.description },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      navigate('/search-results', { state: { results: response.data } });
    } catch (error) {
      console.error('There was an error fetching the contents!', error);
    }
  };

  const handleBlur = () => {
    // Delay hiding suggestions to allow click events to process
    setTimeout(() => setSuggestions([]), 100);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post(`${Helpers.apiUrl}auth/logout`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.status === 200) {
        Helpers.toast('success', 'Successfully logged out');
        localStorage.removeItem('token');
        localStorage.removeItem('user'); // Remove any other user-related data
        navigate('/login');
      }
    } catch (error) {
      Helpers.toast('error', 'Logout failed');
    }
  };

  // Define paths that do not require the notification icon
  const excludedPaths = ['/login', '/register', '/forgot-password'];

  // Check if current path is excluded
  const isExcludedPath = excludedPaths.includes(location.pathname);

  return (
    <>
      <div className="nav-header bg-white shadow-xs border-0">
        <div className="nav-top">
          <a href="/" className="flex items-center">
            <img
              src="/assets/logo/blackbox-logo.png"
              alt="Blackbox Logo"
              className="h-10 mr-2"
              style={{ width: '120px', display: 'block' }}
            />
          </a>
          {authUser && authUser.utype === 'artist' && (
            <Link to="/author-profile" className="mob-menu me-2">
              <i className="feather-video text-grey-900 font-sm btn-round-md bg-greylight"></i>
            </Link>
          )}

          {/* <a href="#" className="me-2 menu-search-icon mob-menu"><i className="feather-search text-grey-900 font-sm btn-round-md bg-greylight"></i></a> */}
          <button className="nav-menu me-0 ms-2" onClick={toggleSidebar}></button>
        </div>

        <form onSubmit={handleFilter} ref={formRef} className="float-left header-search">
          <div className="form-group mb-0 icon-input">
            <i className="feather-search font-sm text-grey-400"></i>
            <input
              type="text"
              placeholder="Start typing to search.."
              className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg"
              value={searchTerm}
              onChange={handleSearchChange}
              onBlur={handleBlur}
            />
          </div>
          {error && <div className="text-start mt-1" style={{ color: 'red' }}>{error}</div>}
          {suggestions.length > 0 && (
            <ul className="suggestions-list text-start">
              {suggestions.map((suggestion, index) => (
                <li key={index} onMouseDown={() => handleSuggestionClick(suggestion)}>
                  {suggestion.description}
                </li>
              ))}
            </ul>
          )}
        </form>
        <a href="/" className="p-2 text-center ms-3 menu-icon center-menu-icon"><i className="feather-home font-lg alert-primary btn-round-lg theme-dark-bg text-current "></i></a>
        {authUser && authUser.utype === 'artist' && (
          <Link to="/author-profile" className="p-2 text-center ms-0 menu-icon center-menu-icon">
            <i className="feather-video font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500"></i>
          </Link>
        )}
        <div className="ms-auto">
          {authUser && !isExcludedPath && <HeaderNotification />}
        </div>

        {authUser && !isExcludedPath && (
          <div className="header-settings position-relative">
            <div ref={dropdownRef} className="p-0 ms-3 menu-icon position-relative cursor-pointer" onClick={toggleDropdown}>
              <img src={profilePicture || "./assets/images/profile-4.png"} alt="user" className="shadow-sm rounded-circle mt--1"
                style={{ width: '30px', height: '30px', objectFit: 'cover' }} />
              <div
                className={`dropdown-menu dropdown-menu-end p-4 rounded-3 border-0 shadow-lg ${dropdownOpen ? 'show' : ''}`}
                aria-labelledby="dropdownMenuSettings"
                style={{ position: 'absolute', top: '100%', right: 0, zIndex: 1000 }}
              >
                <h4 className="fw-700 font-sm mb-1">Settings</h4>
                <hr />
                <ul className="p-1">
                  <li>
                    <Link to="/default-settings" className="item-content mb-1 cursor-pointer" style={{ color: 'darkgray' }}>
                      Default Settings
                    </Link>
                  </li>
                  <hr />
                  <li>
                    <label className="item-content mb-1 cursor-pointer" onClick={handleLogout}>
                      Logout
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

      </div>

      {sidebarVisible && <div className="sidebar-overlay" onClick={toggleSidebar}></div>}
      <Sidebar sidebarVisible={sidebarVisible} />

      <style>
        {`
          .suggestions-list {
            position: absolute;
            background: white;
            border: 1px solid #ddd;
            width: 350px;
            max-height: 200px;
            overflow-y: auto;
            z-index: 1000;
            margin-top: 5px;
            border-radius: 5px;
          }

          .suggestions-list li {
            padding: 10px;
            cursor: pointer;
          }

          .suggestions-list li:hover {
            background-color: #f0f0f0;
          }

          .header-settings .dropdown-menu {
            display: none;
          }

          .header-settings .dropdown-menu.show {
            display: block;
          }
        `}
      </style>
    </>
  );
}

export default Header;
