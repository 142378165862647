import React from 'react';
import axios from 'axios';
import Helpers from '../Config/Helpers';

const Bookmark = ({ content, onBookmarkToggle }) => {
  const handleBookmark = async () => {
    try {
      const response = await axios.post(`${Helpers.apiUrl}auth/bookmark`,
        { content_id: content.id },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

      if (response.status === 201) {
        Helpers.toast('success', 'Content Bookmarked Successfully');
        onBookmarkToggle(content.id, true);
      } else if (response.status === 200) {
        Helpers.toast('success', 'Already Bookmarked');
        onBookmarkToggle(content.id, true);
      }
    } catch (error) {
      console.error('There was a problem with the bookmark request:', error);
      Helpers.toast('warning', 'Failed to Bookmark content');
    }
  };

  const handleUnbookmark = async () => {
    try {
      const response = await axios.post(`${Helpers.apiUrl}auth/unbookmark`,
        { content_id: content.id },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

      if (response.status === 200) {
        Helpers.toast('success', 'Bookmark removed successfully');
        onBookmarkToggle(content.id, false);
      }
    } catch (error) {
      console.error('There was a problem with the unbookmark request:', error);
      Helpers.toast('warning', 'Failed to remove bookmark');
    }
  };

  return (
    <a href="#" onClick={(e) => { e.preventDefault(); content.user_bookmarked ? handleUnbookmark() : handleBookmark(); }} className="d-flex align-items-center fw-600 text-gray-900 text-dark lh-26 font-xssss ms-2">
      <i className={`feather-bookmark text-white ${content.user_bookmarked ? 'bg-black' : 'bg-grey'} btn-round-sm font-xss`}></i>
    </a>
  );
};

export default Bookmark;
