import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';
import Helpers from '../Config/Helpers';
import { useParams, useNavigate } from 'react-router-dom';
import PreLoader from '../Components/PreLoader';
import ShareComponent from '../Components/ShareComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function VideoDetail() {
    const { id } = useParams();
    const [content, setContent] = useState(null);
    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState("");
    const [loading, setLoading] = useState(true);
    const [commentVisible, setCommentVisible] = useState(false);
    const [displayedComments, setDisplayedComments] = useState([]);
    const [showAllComments, setShowAllComments] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [shareData, setShareData] = useState({});

    const playerRef = useRef(null);
    const lastPauseTimeRef = useRef(0);
    const totalDurationRef = useRef(0);

    const navigate = useNavigate();

    // Fetch video content
    const fetchContent = async () => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}auth/video/content/${id}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });
            if (response.status === 200) {
                setContent(response.data.data);
                await fetchComments(response.data.data.id);
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to fetch content');
        } finally {
            setLoading(false);
        }
    };

    // Fetch comments
    const fetchComments = async (contentId) => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}auth/get-comments?content_id=${contentId}`, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });
            if (response.status === 200) {
                setComments(response.data.data);
                // console.log('get comments',response.data.data);
                
                setDisplayedComments(response.data.data.slice(0, 3)); // Show only first 3 comments
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to fetch comments');
        }
    };

    // Handle comment input
    const handleCommentChange = (e) => setCommentText(e.target.value);

    // Submit new comment
    const handleCommentSubmit = async () => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/comments`, {
                content_id: id,
                comment: commentText
            }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });
            if (response.status === 201) {
                
                const newComment = { ...response.data.data };
                // console.log('new comment',newComment);

                setComments((prev) => [newComment, ...prev]);
                setDisplayedComments((prev) => [newComment, ...prev].slice(0, showAllComments ? undefined : 3));
                setCommentText("");
                setCommentVisible(true);
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to post comment');
        }
    };

    const handleLike = async () => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/like`, { content_id: id }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });
            if (response.status === 201 || response.status === 200) {
                setContent((prevContent) => ({
                    ...prevContent,
                    user_liked: true,
                    likes_count: prevContent.likes_count + 1
                }));
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to post like');
        }
    };

    const handleUnlike = async () => {
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/unlike`, { content_id: id }, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });
            if (response.status === 200) {
                setContent((prevContent) => ({
                    ...prevContent,
                    user_liked: false,
                    likes_count: prevContent.likes_count - 1
                }));
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to unlike content');
        }
    };

    const handleLikeToggle = (userLiked) => {
        userLiked ? handleUnlike() : handleLike();
    };

    const handlePlay = () => {
        lastPauseTimeRef.current = playerRef.current.getCurrentTime();
        totalDurationRef.current = 0;
    };

    const handlePause = async () => {
        if (playerRef.current) {
            const currentTime = playerRef.current.getCurrentTime();
            if (lastPauseTimeRef.current !== null) {
                const pausedDuration = currentTime - lastPauseTimeRef.current;
                await savePlaybackTime(pausedDuration);
            }
            lastPauseTimeRef.current = currentTime;
        }
    };

    const handleEnded = async () => {
        if (playerRef.current) {
            const videoLength = playerRef.current.getDuration();
            const remainingDuration = videoLength - totalDurationRef.current;
            totalDurationRef.current += remainingDuration;
        }
    };

    const savePlaybackTime = async (duration) => {
        const roundedDuration = Math.max(1, Math.round(duration * 100) / 100);
        if (roundedDuration > 0) {
            try {
                await axios.post(`${Helpers.apiUrl}auth/content/save-duration`, {
                    content_id: id,
                    duration: roundedDuration
                }, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
                });
            } catch (error) {
                Helpers.toast('error', 'Failed to save playback time');
            }
        }
    };

    const toggleCommentSection = () => {
        setCommentVisible(!commentVisible);
    };

    const handleShowAllComments = () => {
        setDisplayedComments(comments);
        setShowAllComments(true);
    };

    const handleShare = (content) => {
        const shareData = {
            title: content.title || 'Content Title',
            text: content.description || 'Content Description',
            url: `${window.location.origin}/content/${content.id}`
        };
        setShareData(shareData);
        setShowShareModal(true);
    };

    useEffect(() => {
        fetchContent();
        fetchComments(id);

        return () => {
            if (playerRef.current) {
                const currentTime = playerRef.current.getCurrentTime();
                if (lastPauseTimeRef.current !== null) {
                    totalDurationRef.current += (currentTime - lastPauseTimeRef.current);
                } else {
                    totalDurationRef.current += currentTime;
                }
                savePlaybackTime(totalDurationRef.current);
            }
        };
    }, [id]);

    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className="container mt-3">
                    <button className="btn btn-primary btn-sm text-white mb-2" onClick={() => navigate(-1)}>
                    <span><FontAwesomeIcon icon={faArrowLeft} /></span> Back
                    </button>
                    {content && (
                        <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                            <div className="card-body p-0 mb-3 rounded-xxl overflow-hidden video-container" style={{ position: 'relative' }}>
                                <ReactPlayer
                                    className="float-right w-100"
                                    url={content.file_path}
                                    controls
                                    width="100%"
                                    height="400px"
                                    ref={playerRef}
                                    onPlay={handlePlay}
                                    onPause={handlePause}
                                    onEnded={handleEnded}
                                />
                            </div>
                            <div className="card-body p-0 me-lg-5">
                                <p className="fw-500 text-gray-700 lh-26 font-xssss w-100 mb-2 text-start">
                                    {content.description}
                                </p>
                            </div>
                            <div className="card-body d-flex p-0">
                                <a
                                    href="#"
                                    onClick={(e) => { e.preventDefault(); handleLikeToggle(content.user_liked); }}
                                    className="d-flex align-items-center fw-600 text-gray-900 text-dark lh-26 font-xssss me-2"
                                >
                                    <i className={`feather-heart text-white ${content.user_liked ? 'bg-red-gradiant' : 'bg-grey'} me-1 btn-round-xs font-xss`}></i>
                                    {content.likes_count} {content.likes_count === 1 ? 'Like' : 'Likes'}
                                </a>
                                <a
                                    href="#"
                                    onClick={(e) => { e.preventDefault(); toggleCommentSection(); }}
                                    className="d-flex align-items-center fw-600 text-gray-900 text-dark lh-26 font-xssss"
                                >
                                    <i className="feather-message-circle text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i>
                                    {commentVisible ? `${comments.length} Hide` : `${comments.length} Comment`}
                                </a>
                                <a href="#" onClick={(e) => { e.preventDefault(); handleShare(content); }} className="ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss">
                                    <i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i>
                                    Share
                                </a>
                            </div>
                            {commentVisible && (
                                <div className="card-body p-0 mt-3">
                                    <textarea
                                        className="form-control comment-textarea"
                                        rows="3"
                                        placeholder="Add a comment..."
                                        value={commentText}
                                        onChange={handleCommentChange}
                                        style={{
                                            borderRadius: '10px',
                                            padding: '10px 15px',
                                            border: '1px solid #ccc',
                                            width: '100%',
                                            lineHeight: '1.5',
                                            height: '80px'
                                        }}
                                    />
                                    <button
                                        className="btn btn-primary mt-2"
                                        onClick={handleCommentSubmit}
                                        style={{
                                            backgroundColor: '#1877f2',
                                            color: '#fff',
                                            borderRadius: '10px',
                                            padding: '10px 20px',
                                            border: 'none'
                                        }}
                                    >
                                        Post Comment
                                    </button>
                                </div>
                            )}
                            {commentVisible && (
                                <div className="mt-4">
                                    {displayedComments.map((comment, i) => (
                                        <div key={i} className="d-flex align-items-start mb-3">
                                            <figure className="avatar me-3">
                                                <img
                                                    src={comment?.user && comment?.user?.profile && comment?.user?.profile?.profile_picture ? comment?.user?.profile?.profile_picture : '/assets/images/profile-4.png'}
                                                    alt="image"
                                                    className="shadow-sm rounded-circle w45"
                                                    style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                                                />
                                            </figure>
                                            <div className="comment-content" style={{
                                                background: '#f0f2f5',
                                                borderRadius: '10px',
                                                padding: '10px 15px',
                                                maxWidth: '90%'
                                            }}>
                                                <h6 className="fw-700 text-grey-900 font-xssss mt-1 mb-1 text-start">
                                                    {comment.user ? comment.user.name : 'Unknown User'}
                                                </h6>
                                                <p className="fw-500 text-grey-500 lh-26 font-xssss mb-0 text-start">
                                                    {comment.comment}
                                                </p>
                                                <div className="d-flex align-items-center mt-1">
                                                    <span className="d-block font-xssss fw-500 text-grey-500 text-start">
                                                        {comment.created_at_human}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {!showAllComments && comments.length > 3 && (
                                        <div className="text-end">
                                            <button
                                                className="btn btn-primary mt-2"
                                                onClick={handleShowAllComments}
                                                style={{
                                                    backgroundColor: '#1877f2',
                                                    color: '#fff',
                                                    borderRadius: '5px',
                                                    padding: '5px 10px',
                                                    border: 'none'
                                                }}>
                                                Show all {comments.length} comments
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            <ShareComponent show={showShareModal} handleClose={() => setShowShareModal(false)} shareData={shareData} />
        </div>
    );
}

export default VideoDetail;
