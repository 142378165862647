import React from 'react';

export default function ImageCard({ imageUrl, titleText, altText }) {
    // Truncate titleText if it's longer than 30 characters
    const truncatedTitle = titleText?.length > 30 ? `${titleText.slice(0, 30)}...` : titleText;

    return (
        <div className="card-container">
            <div className="card-title">
                <p>{truncatedTitle}</p>
            </div>
            <div className="image-card">
                <div className="image-container">
                    <img src={imageUrl} alt={altText} className="image" />
                </div>
            </div>
            <style jsx>{`
                .card-container {
                    display: inline-block;
                    width: 100%;
                    max-width: 300px; /* Fixed max-width to ensure uniformity */
                    margin-bottom: 5px;
                    text-align: center;
                }

                .card-title {
                    margin-bottom: 2px;
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: #333;
                    text-align: center;
                    position: relative;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .image-card {
                    width: 100%;
                    border-radius: 15px;
                    overflow: hidden;
                    transition: transform 0.4s ease, box-shadow 0.4s ease;
                }

                .image-card:hover {
                    transform: scale(1.05);
                    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 10px 24px rgba(0, 0, 0, 0.15);
                }

                .image-container {
                    width: 100%;
                    height: 200px; /* Fixed height for uniform image size */
                    overflow: hidden;
                }

                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                    transition: transform 0.4s ease;
                }

                .image-card:hover .image {
                    transform: scale(1.1);
                }
            `}</style>
        </div>
    );
}
