import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Config/Helpers';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const storedEmail = localStorage.getItem('resetEmail');
        if (storedEmail) {
            setEmail(storedEmail);
        } else {
            navigate('/forgot-password');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = [];
        if (!password) {
            validationErrors.push('Password is required');
        } else if (password.length < 6) {
            validationErrors.push('Password must be at least 6 characters');
        }

        if (password !== confirmPassword) {
            validationErrors.push('Passwords do not match');
        }

        if (validationErrors.length > 0) {
            const errorMessage = validationErrors.join('<br />');
            Helpers.toast('error', errorMessage);
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(`${Helpers.apiUrl}password/reset`, { email, password, password_confirmation: confirmPassword });

            if (response.status === 200) {
                Helpers.toast('success', 'Password has been successfully reset');
                navigate('/login');
            }
        } catch (error) {
            // Handle errors
            const errorMessage = error.response?.data?.errors || 'Password reset failed';
            Helpers.toast('error', errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main-wrap">
            <div className="nav-header bg-transparent shadow-none border-0">
                <div className="nav-top w-100 d-flex justify-content-between align-items-center">
                <a href="/" className="flex items-center">
                        <img
                            src="./assets/logo/blackbox-logo.png"
                            alt="Blackbox Logo"
                            className="h-10 mr-2"
                            style={{ width: '150px', display: 'block' }}
                        />

                    </a>
                </div>
            </div>
            <div className="row">
                {/* <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat" style={{ backgroundImage: 'url(./assets/images/login-bg-2.jpg)' }}></div> */}
                <div
                    className="col-xl-5 d-none d-xl-block p-0 vh-50 bg-image-cover bg-no-repeat"
                    style={{
                        backgroundImage: 'url(./assets/logo/forget-password.png)',
                        width: '40%',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '100vh' 
                    }}
                ></div>
                <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                    <div className="card shadow-none border-0 ms-auto me-auto login-card">
                        <div className="card-body rounded-0 text-left">
                            <h2 className="fw-700 display1-size display2-md-size mb-4">Reset <br />Your Password</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group icon-input mb-3">
                                    <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                    <input
                                        type="email"
                                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                        placeholder="Your Email Address"
                                        value={email}
                                        readOnly
                                    />
                                </div>
                                <div className="form-group icon-input mb-3">
                                    <input
                                        type="password"
                                        className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                </div>
                                <div className="form-group icon-input mb-1">
                                    <input
                                        type="password"
                                        className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                </div>
                                <div className="col-sm-12 p-0 text-left">
                                    <div className="form-group mb-1">
                                        <button type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0">
                                            {loading ? 'Changing...' : 'Change Password'}
                                        </button>
                                    </div>
                                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                        Remember your password?
                                        <Link to="/login" className="fw-700 ms-1">Login</Link>
                                    </h6>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
