import React, { useState, useEffect, useRef } from 'react';
import PreLoader from './PreLoader';
import { Tabs, Tab, Carousel, Modal, Button } from 'react-bootstrap'; // Import Carousel for multiple photo handling
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import Helpers from '../Config/Helpers';
import UserDetailModal from './UserDetailModal';
import FullUserDetailModal from './FullUserDetailModal';

export default function SearchResults() {
    const [loading, setLoading] = useState(true);
    const [key, setKey] = useState('accounts');
    const [selectedUser, setSelectedUser] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [fullDetailModalShow, setFullDetailModalShow] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [selectedPhotos, setSelectedPhotos] = useState([]); // Handle multiple photos
    const location = useLocation();
    const videoRefs = useRef([]);
    const playPauseLocks = useRef([]);
    const baseUrl = Helpers.basePath;

    const [searchResults, setSearchResults] = useState([]);
    const [languageContents, setLanguageContents] = useState([]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (location.state && location.state.results) {
            setSearchResults(location.state.results);
            const searchTerm = location.state.search?.toLowerCase() || '';
            const filteredResults = location.state.results
                .flatMap(result => normalizeContents(result.contents))
                .filter(content => content.language?.toLowerCase() === searchTerm);
            setLanguageContents(filteredResults);
        }
    }, [location.state]);

    const handleVideoHover = debounce(async (index) => {
        const video = videoRefs.current[index];
        if (video) {
            video.currentTime = 0; // Start playing from the beginning
            if (!playPauseLocks.current[index]) {
                playPauseLocks.current[index] = true;
                try {
                    await video.play();
                } catch (error) {
                    console.log('Video play interrupted', error);
                } finally {
                    playPauseLocks.current[index] = false;
                }
            }
        }
    }, 500); // Adjusted debounce delay

    const handleVideoLeave = debounce(async (index) => {
        const video = videoRefs.current[index];
        if (video) {
            if (!playPauseLocks.current[index]) {
                playPauseLocks.current[index] = true;
                try {
                    await video.pause();
                } catch (error) {
                    console.log('Video pause interrupted', error);
                } finally {
                    playPauseLocks.current[index] = false;
                }
            }
        }
    }, 500); // Adjusted debounce delay

    const handleVideoPlayPause = async (index) => {
        const video = videoRefs.current[index];
        if (video && !playPauseLocks.current[index]) {
            playPauseLocks.current[index] = true;
            try {
                if (video.paused) {
                    await video.play();
                } else {
                    await video.pause();
                }
            } catch (error) {
                console.log('Video play/pause interrupted', error);
            } finally {
                playPauseLocks.current[index] = false;
            }
        }
    };

    const handleContentClick = async (content) => {
        if (content.type === 'photo') {
            let filePaths;
            try {
                filePaths = Array.isArray(content.file_path) ? content.file_path : JSON.parse(content.file_path);
            } catch (error) {
                filePaths = [content.file_path];
            }
            setSelectedPhotos(filePaths); // Set the selected photos to the state
            setCurrentPhotoIndex(0); // Start with the first photo
            setShowPhotoModal(true); // Show the modal
        }
    };

    const normalizeContents = (contents) => {
        if (Array.isArray(contents)) {
            return contents;
        } else if (typeof contents === 'object' && contents !== null) {
            return Object.values(contents);
        }
        return [];
    };

    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className="main-content theme-dark-bg py-2 mt-3">
                    <div className="middle-sidebar-left mt-2">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="custom-tabs">
                                                <Tab eventKey="accounts" title="Accounts" className="custom-tab">
                                                    <div className='text-start mt-3'>
                                                        {Object.values(searchResults).map((result, index) => (
                                                            result.user ? (
                                                                <div key={index} className="user-card flex items-center mb-4 cursor-pointer" onClick={() => handleContentClick(result)}>
                                                                    <figure className="avatar mr-3">
                                                                        <img
                                                                            src={result.user.profile && result.user.profile.profile_picture ? result.user.profile.profile_picture : './assets/images/profile-4.png'}
                                                                            alt="image"
                                                                            className="shadow-sm rounded-full w-12 h-12 object-cover hover-card"
                                                                            style={{
                                                                                width: '70px',
                                                                                height: '70px',
                                                                                borderRadius: '50%',
                                                                                objectFit: 'cover',
                                                                                border: '2px solid #ddd',
                                                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                                marginRight: '10px',
                                                                            }}
                                                                        />
                                                                    </figure>
                                                                    <div className="user-info">
                                                                        <span className="user-name text-lg font-bold">{result.user.name}</span>
                                                                        <span className="user-bio text-gray-500">{result.user.profile?.bio}</span>
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="photos" title="Photos" className="custom-tab">
                                                    <div className='text-start mt-3'>
                                                        <div className="photo-grid">
                                                            {Object.values(searchResults).flatMap(result => normalizeContents(result.contents).filter(content => content.type === 'photo')).map((content, index) => (
                                                                <div key={index} className="photo-card">
                                                                    <img
                                                                        src={Array.isArray(content.file_path) ? content.file_path[0] : content.file_path}
                                                                        alt="photo"
                                                                        className="w-100 rounded-lg"
                                                                        style={{ objectFit: 'cover', height: '200px' }}
                                                                        onClick={() => handleContentClick(content)}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="videos" title="Videos" className="custom-tab">
                                                    <div className='text-start mt-3'>
                                                        <div className="video-grid">
                                                            {Object.values(searchResults).flatMap(result => normalizeContents(result.contents).filter(content => content.type === 'video')).map((content, index) => (
                                                                <div key={index} className="video-card hover-card">
                                                                    <div className="card rounded-xxl border-0 "
                                                                        onMouseOver={() => handleVideoHover(index)}
                                                                        onMouseLeave={() => handleVideoLeave(index)}
                                                                    >
                                                                        <video
                                                                            ref={el => videoRefs.current[index] = el}
                                                                            loop
                                                                            className="w-full"
                                                                            controls
                                                                            muted
                                                                            onClick={() => handleVideoPlayPause(index)}
                                                                            style={{ objectFit: 'cover', height: '100%' }}
                                                                        >
                                                                            <source src={content.file_path} type="video/mp4" />
                                                                        </video>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="music" title="Music" className="custom-tab">
                                                    <div className='text-start mt-3'>
                                                        <div className="music-grid">
                                                            {Object.values(searchResults).flatMap(result => normalizeContents(result.contents).filter(content => content.type === 'music')).map((content, index) => (
                                                                <div key={index} className="music-card">
                                                                    <div className="card-body p-0 m-0 d-flex align-items-center justify-content-center" style={{ height: '70px' }}>
                                                                        <audio
                                                                            controls
                                                                            className="w-100 audio-player p-2"
                                                                        >
                                                                            <source src={content.file_path} type="audio/mpeg" />
                                                                        </audio>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="mostLiked" title="Most Liked" className="custom-tab">
                                                    <div className='text-start mt-3'>
                                                        {['video', 'photo', 'music'].map(type => {
                                                            const mostLikedContent = Object.values(searchResults)
                                                                .flatMap(result => normalizeContents(result.contents))
                                                                .filter(content => content.type === type && content.most_likes > 0)
                                                                .sort((a, b) => b.most_likes - a.most_likes)[0];

                                                            return mostLikedContent ? (
                                                                <div key={type} className={`${type}-card`}>
                                                                    {type === 'video' && (
                                                                        <div className="card rounded-xxl border-0 hover-card">
                                                                            <video
                                                                                loop
                                                                                className="w-full"
                                                                                controls
                                                                                muted
                                                                                style={{ objectFit: 'cover', height: '100%' }}
                                                                            >
                                                                                <source src={mostLikedContent.file_path} type="video/mp4" />
                                                                            </video>
                                                                        </div>
                                                                    )}
                                                                    {type === 'photo' && (
                                                                        <>
                                                                            <img
                                                                                src={Array.isArray(mostLikedContent.file_path) ? mostLikedContent.file_path[0] : mostLikedContent.file_path}
                                                                                alt="photo"
                                                                                className="w-100 rounded-lg"
                                                                                style={{ objectFit: 'cover', height: '200px', cursor: 'pointer' }}
                                                                                onClick={() => {
                                                                                    setSelectedPhotos(Array.isArray(mostLikedContent.file_path) ? mostLikedContent.file_path : [mostLikedContent.file_path]);
                                                                                    setCurrentPhotoIndex(0);
                                                                                    setShowPhotoModal(true);
                                                                                }}
                                                                            />
                                                                        </>
                                                                    )}
                                                                    {type === 'music' && (
                                                                        <div className="card-body p-0 m-0 d-flex align-items-center justify-content-center" style={{ height: '70px' }}>
                                                                            <audio
                                                                                controls
                                                                                className="w-100 audio-player p-2"
                                                                            >
                                                                                <source src={mostLikedContent.file_path} type="audio/mpeg" />
                                                                            </audio>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ) : null;
                                                        })}
                                                    </div>
                                                </Tab>

                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Photo Modal */}
            {selectedPhotos.length > 0 && (
                <Modal show={showPhotoModal} onHide={() => setShowPhotoModal(false)} size="md" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Photo Viewer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedPhotos.length === 1 ? (
                            <img
                                src={selectedPhotos[0]}
                                alt="Selected"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '70vh',
                                    display: 'block',
                                    margin: 'auto'
                                }}
                            />
                        ) : (
                            <Carousel activeIndex={currentPhotoIndex} onSelect={(selectedIndex) => setCurrentPhotoIndex(selectedIndex)}>
                                {selectedPhotos.map((url, idx) => (
                                    <Carousel.Item key={idx}>
                                        <img
                                            src={url}
                                            alt={`Photo ${idx + 1}`}
                                            className="d-block w-100"
                                            style={{
                                                maxHeight: '70vh',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowPhotoModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <style>
                {`
                .custom-tabs .nav-link {
                    border: none;
                    padding: 0.5rem 1rem;
                    margin-right: 0.25rem;
                    font-size: 1rem;
                    font-weight: 500;
                    color: #4A5568;
                }
                .custom-tabs .nav-link.active {
                    border-bottom: 2px solid #3182CE;
                    color: #3182CE;
                }
                .hover-card {
                    transition: transform 0.5s ease, box-shadow 0.5s ease; /* Add smooth transition */
                    margin: 10px;
                }
                .hover-card:hover {
                    transform: scale(1.05);
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 6px 16px rgba(0, 0, 0, 0.1);
                }
                .photo-grid, .video-grid, .music-grid {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px; /* Increased gap */
                }
                .photo-card, .video-card, .music-card {
                    flex: 1 1 calc(25% - 16px); /* Four cards in a row */
                    box-sizing: border-box;
                    padding: 0px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    transition: transform 0.5s ease, box-shadow 0.5s ease; /* Add smooth transition */
                }
                .photo-card img, .video-card video {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                }
                .music-card .card-body {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .music-card audio {
                    width: 100%;
                    height: 100%;
                }
                .user-card {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    cursor: pointer; /* Add pointer cursor */
                }
                .user-info {
                    display: flex;
                    flex-direction: column;
                }
                .user-name {
                    font-weight: bold;
                }
                .user-bio {
                    color: #777;
                }
                .audio-player {
                    width: 100%;
                    border-radius: 5px;
                    background-color: #f1f1f1;
                }
                `}
            </style>
        </div>
    );
}
