import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PreLoader from './PreLoader';
import Helpers from '../Config/Helpers';
import axios from 'axios';

const FeaturedCard = () => {

    const [contests, setContests] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchContests = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await axios.get(`${Helpers.apiUrl}auth/contests`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const contestData = response.data.data;
                setContests(contestData);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching contests:', error);
                setContests(null);
                setLoading(false); // Set loading to false even if there is an error
            }
        };
        fetchContests();
    }, []);

    if (loading) {
        return <PreLoader />;
    }

    const handleParticipatetContest = async (contestId) => {
        try {
            const token = localStorage.getItem('token');

            // Call the check-participation API
            const response = await axios.get(`${Helpers.apiUrl}auth/check-participation/${contestId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Check the 'participated' field in the response
            if (response.data.participated) {
                Helpers.toast('error', 'You have already participated in this contest.');
            } else {
                // If the user hasn't participated, navigate to the contest participation page
                navigate(`/contest-participate/${contestId}`);
            }
        } catch (error) {
            console.error('Error checking participation:', error);
            Helpers.toast('error', 'There was an error checking your participation status.');
        }
    };


    const handleImageLoad = () => {
        setLoading(false);
    };

    return (
        <>
            {loading && <PreLoader />}
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                <div className="card-body d-flex position-relative" style={{ alignItems: 'stretch' }}>
                    <div style={{ flex: '1 0 50%', marginRight: '10px', display: 'flex' }}>
                        <img
                            src="./assets/images/featured-2.jpg"
                            alt="photo"
                            className="w-100"
                            style={{ objectFit: 'cover', height: '100%', borderRadius: '15px' }}
                            onLoad={handleImageLoad}
                        />
                    </div>
                    <div className="d-flex flex-column p-2 text-left" style={{ flex: '1 0 50%', height: '100%' }}>
                        <span className="mb-2 text-black">Visual Art</span>
                        <span className="mb-2 text-black">Film</span>
                        <span className="mb-2 text-black">Music Video</span>
                        <span className="mb-2 text-black">Artists</span>
                    </div>
                </div>
                {!loading && (
                    <div className="w-100 p-3">
                        <Link to="/featured" className="btn rounded-xxl text-white w-100" style={{ background: '#346E93' }}>
                            Go To Featured
                        </Link>
                    </div>
                )}
            </div>

            {/* Check for contests */}
            {contests && contests.length > 0 ? (
                <div className="card w-100 shadow-lg rounded-xxl border-0 mb-3" style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
                    <div className="card-body d-flex flex-column" style={{ alignItems: 'stretch' }}>
                        <h3 style={{ fontSize: '1.5rem', fontWeight: '400', marginBottom: '20px', textAlign: 'center' }}>🏆 Running Contest</h3>

                        {/* Display each contest in its own row */}
                        {contests.map((contest) => (
                            <div key={contest.id} style={{ marginBottom: '20px', padding: '15px', border: '1px solid #ddd', borderRadius: '10px', textAlign: 'left' }}>
                                <ul style={{ listStyleType: 'none', paddingLeft: '0', lineHeight: '2rem', color: '#555' }}>
                                    <li>
                                        <strong>Contest Name:</strong> <span>{contest.name}</span>
                                    </li>
                                    <li>
                                        <strong>Contest Type:</strong>
                                        <span>
                                            {contest.categories && contest.categories.length > 0
                                                ? contest.categories.map((category, index) => (
                                                    <span key={category.id}>
                                                        {category.name}{index < contest.categories.length - 1 ? ', ' : ''}
                                                    </span>
                                                ))
                                                : 'No Category'}
                                        </span>
                                    </li>
                                    <li>
                                        <strong>Start Time:</strong> <span>{contest.start_date}</span>
                                    </li>
                                    <li>
                                        <strong>End Time:</strong> <span>{contest.end_date}</span>
                                    </li>
                                </ul>

                                {/* Participate Button */}
                                <button className='btn btn-sm rounded-xxl text-white' style={{ background: '#346E93' }} onClick={() => handleParticipatetContest(contest.id)}>
                                    Participate
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <>
                
                </>
            )}
        </>
    );
};

export default FeaturedCard;
