import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Config/Helpers';

function Register() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = [];
        if (!name) {
            errors.push('Name is required');
        }
        if (!email) {
            errors.push('Email is required');
        } else if (!validateEmail(email)) {
            errors.push('Please enter a valid email address');
        }
        if (!password) {
            errors.push('Password is required');
        }
        if (password.length < 6) {
            errors.push('Password must be at least 6 characters long');
        }
        if (password !== confirmPassword) {
            errors.push('Passwords do not match');
        }

        if (errors.length > 0) {
            const errorMessage = errors.join('<br />');
            Helpers.toast('error', errorMessage);
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(`${Helpers.apiUrl}auth/register`, {
                name,
                email,
                password,
                password_confirmation: confirmPassword
            });
            const { token, user } = response.data;

            // Save token and user info
            Helpers.setItem('token', token);
            Helpers.setItem('user', user, true);

            // Display success notification
            Helpers.toast('success', 'Registration Successful');
            navigate('/login');
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Registration failed';
            const serverErrors = error.response?.data?.errors;

            if (serverErrors) {
                const serverErrorsList = [];
                Object.values(serverErrors).forEach((errArray) => {
                    serverErrorsList.push(...errArray);
                });
                const serverErrorMessage = serverErrorsList.join('<br />');
                Helpers.toast('error', serverErrorMessage);
            } else {
                Helpers.toast('error', errorMessage);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main-wrap">
            <div className="nav-header bg-transparent shadow-none border-0">
                <div className="nav-top w-100 d-flex justify-content-between align-items-center">
                <a href="/" className="flex items-center">
                        <img
                            src="./assets/logo/blackbox-logo.png"
                            alt="Blackbox Logo"
                            className="h-10 mr-2"
                            style={{ width: '150px', display: 'block' }}
                        />

                    </a>
                </div>
            </div>

            <div className="row">
                {/* <div className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat" style={{ backgroundImage: 'url(./assets/images/login-bg-2.jpg)' }}></div> */}
                <div
                    className="col-xl-5 d-none d-xl-block p-0 vh-50 bg-image-cover bg-no-repeat"
                    style={{
                        backgroundImage: 'url(./assets/logo/register.png)',
                        width: '40%',
                        backgroundSize: 'cover', // or 'contain' depending on the desired effect
                        backgroundPosition: 'center',
                        height: '100vh' // Ensure the height fits the viewport height
                    }}
                ></div>
                <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                    <div className="card shadow-none border-0 ms-auto me-auto login-card">
                        <div className="card-body rounded-0 text-left">
                            <h2 className="fw-700 display1-size display2-md-size mb-4">Create <br />Your Account</h2>
                            <form onSubmit={handleSubmit} className='mb-4'>
                                <div className="form-group icon-input mb-3">
                                    <i className="font-sm ti-user text-grey-500 pe-0"></i>
                                    <input
                                        type="text"
                                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                        placeholder="Your Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        autoComplete="name"
                                    />
                                </div>
                                <div className="form-group icon-input mb-3">
                                    <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                    <input
                                        type="email"
                                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                        placeholder="Your Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete="email"
                                    />
                                </div>
                                <div className="form-group icon-input mb-3">
                                    <input
                                        type="password"
                                        className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="new-password"
                                    />
                                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                </div>
                                <div className="form-group icon-input mb-1">
                                    <input
                                        type="password"
                                        className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        autoComplete="new-password"
                                    />
                                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                </div>
                                <div className="col-sm-12 p-0 text-left">
                                    <div className="form-group mb-1">
                                        <button type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0">
                                            {loading ? 'Registering...' : 'Register'}
                                        </button>
                                    </div>
                                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                        Already have an account?
                                        <Link to="/login" className="fw-700 ms-1">Login</Link>
                                    </h6>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
