import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Helpers from '../../Config/Helpers';
import PreLoader from '../../Components/PreLoader';

function ArtistDetailsPage() {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [contents, setContents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [key, setKey] = useState('videos');
    const videoRefs = useRef([]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            const token = localStorage.getItem('token'); // Adjust this line based on how you store the token

            try {
                const userResponse = await axios.get(`${Helpers.apiUrl}auth/user/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setUser(userResponse.data);

                const contentsResponse = await axios.get(`${Helpers.apiUrl}auth/user/${id}/contents`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setContents(contentsResponse.data.data); // Adjusted to handle JSON:API response format
            } catch (error) {
                console.error('There was an error fetching the details!', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [id]);

    const isPublic = user && user.profile && user.profile.visibility === 'public';

    const normalizeContents = (contents) => {
        if (Array.isArray(contents)) {
            return contents;
        } else if (typeof contents === 'object' && contents !== null) {
            return Object.values(contents);
        }
        return [];
    };

    const handleVideoHover = async (index) => {
        const video = videoRefs.current[index];
        if (video) {
            video.currentTime = 0; // Start playing from the beginning
            try {
                await video.play();
            } catch (error) {
                console.log('Video play interrupted', error);
            }
        }
    };

    const handleVideoLeave = async (index) => {
        const video = videoRefs.current[index];
        if (video) {
            try {
                await video.pause();
            } catch (error) {
                console.log('Video pause interrupted', error);
            }
        }
    };

    return (
        <div className='main-content right-chat-active'>
            <div className="main-content theme-dark-bg py-2 mt-3">
                {loading ? (
                    <PreLoader />
                ) : (
                    <div className="middle-sidebar-left mt-2">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <div className="row">
                                        <div className="col-lg-12 mt-3">
                                            {isPublic ? (
                                                <div>
                                                    <div className="user-card flex items-center">
                                                        <figure className="avatar mr-3">
                                                            <img
                                                                src={user.profile && user.profile.profile_picture ? user.profile.profile_picture : './assets/images/profile-4.png'}
                                                                alt="User Profile"
                                                                className="rounded-circle"
                                                                style={{
                                                                    width: '70px',
                                                                    height: '70px',
                                                                    borderRadius: '50%',
                                                                    objectFit: 'cover',
                                                                    border: '2px solid #ddd',
                                                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                    marginRight: '10px'
                                                                }}
                                                            />
                                                        </figure>
                                                        <div className="user-info text-left">
                                                            <span className="user-name block text-lg font-bold text-gray-800">Name: {user.name}</span>
                                                            <span className="user-email block text-sm text-gray-600">Email: {user.email}</span>
                                                            <span className="user-bio block mt-2 text-gray-700">Bio: {user.profile.bio}</span>
                                                            <span className="user-visibility block mt-2 text-gray-700">Visibility:  {user.profile.visibility}</span>
                                                        </div>
                                                    </div>
                                                    <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="custom-tabs mt-4">
                                                        <Tab eventKey="videos" title="Videos" className="custom-tab">
                                                            <div className='text-start mt-3'>
                                                                <div className="video-grid">
                                                                    {normalizeContents(contents).filter(content => content.type === 'video').map((content, index) => (
                                                                        <div key={index} className="video-card">
                                                                            <div className="card rounded-xxl border-0 hover-card"
                                                                                onMouseOver={() => handleVideoHover(index)}
                                                                                onMouseLeave={() => handleVideoLeave(index)}
                                                                            >
                                                                                <video
                                                                                    ref={el => videoRefs.current[index] = el}
                                                                                    loop
                                                                                    className="w-full"
                                                                                    controls
                                                                                    muted
                                                                                    style={{ objectFit: 'cover', height: '100%' }}
                                                                                >
                                                                                    <source src={content.file_path} type="video/mp4" />
                                                                                </video>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="photos" title="Photos" className="custom-tab">
                                                            <div className='text-start mt-3'>
                                                                <div className="photo-grid">
                                                                    {normalizeContents(contents).filter(content => content.type === 'photo').map((content, index) => (
                                                                        <div key={index} className="photo-card">
                                                                            <img
                                                                                src={content.file_path}
                                                                                alt="photo"
                                                                                className="w-100 rounded-lg"
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="music" title="Music" className="custom-tab">
                                                            <div className='text-start mt-3'>
                                                                <div className="music-grid">
                                                                    {normalizeContents(contents).filter(content => content.type === 'music').map((content, index) => (
                                                                        <div key={index} className="music-card">
                                                                            <div className="card-body p-0 m-0 d-flex align-items-center justify-content-center" style={{ height: '70px' }}>
                                                                                <audio
                                                                                    controls
                                                                                    className="w-100 audio-player p-2"
                                                                                >
                                                                                    <source src={content.file_path} type="audio/mpeg" />
                                                                                </audio>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                </div>
                                            ) : (

                                                <div className="user-card flex items-center">
                                                    <figure className="avatar mr-3">
                                                        <img
                                                            src={user.profile && user.profile.profile_picture ? user.profile.profile_picture : './assets/images/profile-4.png'}
                                                            alt="User Profile"
                                                            className="rounded-circle"
                                                            style={{
                                                                width: '70px',
                                                                height: '70px',
                                                                borderRadius: '50%',
                                                                objectFit: 'cover',
                                                                border: '2px solid #ddd',
                                                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                                marginRight: '10px'
                                                            }}
                                                        />
                                                    </figure>
                                                    <div className="user-info text-left">
                                                        <span className="user-name block text-lg font-bold text-gray-800">Name: {user.name}</span>
                                                        <span className="user-email block text-sm text-gray-600">Email: {user.email}</span>
                                                        <span className="user-bio block mt-2 text-gray-700">Bio: {user.profile.bio}</span>
                                                        <span className="user-visibility block mt-2 text-gray-700">Visibility:  {user.profile.visibility}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <style>
                {`
                .custom-tabs .nav-link {
                    border: none;
                    padding: 0.5rem 1rem;
                    margin-right: 0.25rem;
                    font-size: 1rem;
                    font-weight: 500;
                    color: #4A5568;
                }
                .custom-tabs .nav-link.active {
                    border-bottom: 2px solid #3182CE;
                    color: #3182CE;
                }
                .hover-card:hover {
                    transform: scale(1.05);
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 6px 16px rgba(0, 0, 0, 0.1);
                }
                .video-container::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: opacity 0.3s ease;
                    opacity: 0;
                }
                .video-container:hover::before {
                    opacity: 1;
                }
                .description {
                    display: -webkit-box;
                    -webkit-line-clamp: 1; /* Number of lines to show */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                }
                .photo-grid, .video-grid, .music-grid {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px;
                }
                .photo-card:hover {
                    transform: scale(1.05);
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 6px 16px rgba(0, 0, 0, 0.1);
                }
                .photo-card, .video-card, .music-card {
                    flex: 1 1 calc(25% - 16px); /* Four cards in a row */
                    box-sizing: border-box;
                    padding: 0px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }
                .photo-card img, .video-card video {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                }
                .music-card .card-body {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .music-card audio {
                    width: 100%;
                    height: 100%;
                }
                .user-card {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    cursor: pointer; /* Add pointer cursor */
                }
                .user-info {
                    display: flex;
                    flex-direction: column;
                }
                .user-name {
                    font-weight: bold;
                }
                .user-bio {
                    color: #777;
                }
                .audio-player {
                    width: 100%;
                    border-radius: 5px;
                    background-color: #f1f1f1;
                }
                `}
            </style>
        </div>
    );
}

export default ArtistDetailsPage;
