import React, { useState, useEffect } from 'react';
import PreLoader from './PreLoader';
import Helpers from '../Config/Helpers';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

export default function DefaultSettings() {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleLogout = async () => {
    try {
      const response = await axios.post(`${Helpers.apiUrl}auth/logout`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.status === 200) {
        Helpers.toast('success', 'Successfully Logged Out');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
      }
    } catch (error) {
      Helpers.toast('error', 'Logout failed');
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await axios.delete(`${Helpers.apiUrl}auth/delete-account`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.status === 200) {
        Helpers.toast('success', 'Account successfully deleted');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
      }
    } catch (error) {
      Helpers.toast('error', 'Account deletion failed');
    }
  };

  return (
    <div className='main-content right-chat-active'>
      {loading ? (
        <PreLoader />
      ) : (
        <div className="main-content bg-lightblue theme-dark-bg py-2">
          <div className="middle-sidebar-left mt-2">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs text-start">Default Settings</h4>
                      <div className="nav-caption fw-600 font-xssss text-grey-500 mb-2 text-start">General</div>
                      <ul className="list-inline mb-4">
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link to="/profile-settings" className="pt-2 pb-2 d-flex align-items-center">
                            <i className="btn-round-md bg-primary-gradiant text-white feather-user font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">Profile Settings</h4>
                          </Link>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link to="/privacy-policy" className="pt-2 pb-2 d-flex align-items-center">
                            <i className="btn-round-md bg-blue-gradiant text-white feather-file font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0">Privacy Policy</h4>
                          </Link>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <div onClick={() => setShowModal(true)} className="pt-2 pb-2 d-flex align-items-center cursor-pointer">
                            <i className="btn-round-md bg-red-gradiant text-white feather-user-x font-md me-3"></i>
                            <h4 className="fw-600 font-xsss mb-0 mt-0 text-danger">Delete Account</h4>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <span style={closeStyle} onClick={() => setShowModal(false)}>&times;</span>
            <h2>Confirm to Delete</h2>
            <p>Are you sure you want to delete your account? This action cannot be undone.</p>
            <button style={buttonStyle} onClick={handleDeleteAccount}>Yes</button>
            <button style={buttonStyle} onClick={() => setShowModal(false)}>No</button>
          </div>
        </div>
      )}
    </div>
  );
}

const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  zIndex: 1000,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'rgba(0,0,0,0.4)'
};

const modalContentStyle = {
  backgroundColor: '#fefefe',
  margin: 'auto',
  padding: '20px',
  border: '1px solid #888',
  width: '80%',
  maxWidth: '500px',
  borderRadius: '10px',
  textAlign: 'center'
};

const closeStyle = {
  color: '#aaa',
  float: 'right',
  fontSize: '28px',
  fontWeight: 'bold',
  cursor: 'pointer'
};

const buttonStyle = {
  margin: '10px',
  padding: '10px 20px',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '5px',
  fontSize: '16px',
  backgroundColor: '#d9534f',
  color: 'white'
};

const noButtonStyle = {
  backgroundColor: '#f0ad4e',
  color: 'white'
};
