import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Helpers from '../Config/Helpers';
import initializeEcho from './pusher';

const HeaderNotification = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [unreadCount, setUnreadCount] = useState(0);
    const [selectedComment, setSelectedComment] = useState(null);
    const location = useLocation();
    const listenersInitialized = useRef(false);
    const shownNotificationIds = useRef(new Set());
    const navigate = useNavigate();
    
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownOpen && !event.target.closest('.header-notification') && !selectedComment) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen, selectedComment]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`${Helpers.apiUrl}auth/auth-user-detail`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                localStorage.setItem('user', JSON.stringify(response.data));
                localStorage.setItem('userId', response.data.data.id);
            } catch (error) {
                console.error('Error fetching user details', error);
            }
        };

        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`${Helpers.apiUrl}auth/user-notifications`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });

                const notifications = response.data.data;

                if (Array.isArray(notifications)) {
                    const sortedNotifications = notifications.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    setNotifications(sortedNotifications);
                    setUnreadCount(sortedNotifications.filter(notification => !notification.read_at).length);

                    sortedNotifications.forEach(notification => {
                        if (notification.data && notification.data.notification_id) {
                            shownNotificationIds.current.add(notification.data.notification_id);
                        }
                    });
                } else {
                    console.error('Error: notifications is not an array', notifications);
                }
            } catch (error) {
                console.error('Error fetching notifications', error);
            } finally {
                setLoading(false);
            }
        };

        const initializeNotifications = async () => {
            await fetchUserDetails();
            await fetchNotifications();

            const userId = localStorage.getItem('userId');
            const echo = initializeEcho();

            if (userId && !listenersInitialized.current) {
                const notificationChannel = echo.private(`notifications.${userId}`);
                notificationChannel.stopListening('NotificationCreated').listen('NotificationCreated', async (e) => {
                    await fetchNotifications();

                    if (e.data && e.data.notification_id && !shownNotificationIds.current.has(e.data.notification_id)) {
                        const toastMessage = e.type === 'App\\Notifications\\CommentAdded'
                            ? 'New comment added'
                            : e.type === 'App\\Notifications\\LikeAdded'
                                ? 'New like added'
                                : e.type === 'App\\Notifications\\FollowerAdded'
                                    ? 'New follower added'
                                    : `New notification: ${e.type}`;
                        Helpers.toast('success', toastMessage);
                        shownNotificationIds.current.add(e.data.notification_id);
                    }
                });

                notificationChannel.listen('NotificationDeleted', (e) => {
                    setNotifications((prevNotifications) =>
                        prevNotifications.filter((notification) => notification.id !== e.notification_id)
                    );
                    setUnreadCount((prevCount) => prevCount - 1);
                });

                listenersInitialized.current = true;
            } else {
                // console.error('User ID is null or listeners already initialized');
            }
        };

        initializeNotifications();
    }, []);

    const handleNotificationClick = async (notification) => {
       
        
        if (notification.type === 'App\\Notifications\\CommentAdded') {
            // Close the dropdown only when clicking on a comment notification
            setDropdownOpen(false);
            // Navigate to the CommentDetail page with the content ID or comment ID
            navigate(`/all-comments/${notification.content_id}`);
        } else if (notification.type === 'App\\Notifications\\LikeAdded') {
            setDropdownOpen(false);
            navigate(`/all-comments/${notification.content_id}`);

            // Helpers.toast('success', `You clicked on a like from: ${notification.user?.name}`);
        } else if (notification.type === 'App\\Notifications\\FollowerAdded') {
            setDropdownOpen(false);
            navigate(`/user-detail/${notification.follower_id}`);
      
        }

        if (!notification.read_at) {
            try {
                setNotifications(prevNotifications =>
                    prevNotifications.map(n =>
                        n.id === notification.id ? { ...n, read_at: new Date().toISOString() } : n
                    )
                );
                setUnreadCount(prevCount => prevCount - 1);

                await axios.post(`${Helpers.apiUrl}auth/mark-as-read`, { id: notification.id }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } catch (error) {
                console.error('Error marking notification as read', error);
                setNotifications(prevNotifications =>
                    prevNotifications.map(n =>
                        n.id === notification.id ? { ...n, read_at: null } : n
                    )
                );
                setUnreadCount(prevCount => prevCount + 1);
            }
        }
    };

    const closeModal = () => {
        setSelectedComment(null);
    };

    const excludedPaths = ['/login', '/register', '/forgot-password','/onboarding'];
    const isExcludedPath = excludedPaths.includes(location.pathname);

    let authUser;
    try {
        authUser = JSON.parse(localStorage.getItem('user'));
    } catch (e) {
        console.error('Failed to parse authUser from localStorage', e);
        authUser = null;
    }

    if (!authUser || isExcludedPath) {
        return null;
    }

    return (
        <div className="header-notification position-relative">
            <a
                href="#"
                className="p-2 text-center ms-auto menu-icon"
                id="dropdownMenu3"
                onClick={(e) => {
                    e.preventDefault();
                    toggleDropdown();
                }}
                aria-expanded={dropdownOpen}
            >
                <span className={`dot-count ${unreadCount > 0 ? 'bg-danger' : ''}`}></span>
                <i className="feather-bell font-xl text-current"></i>
            </a>
            <div
                className={`dropdown-menu dropdown-menu-end p-4 rounded-3 border-0 shadow-lg ${dropdownOpen ? 'show' : ''}`}
                aria-labelledby="dropdownMenu3"
                style={{ position: 'absolute', top: '100%', right: 10, zIndex: 1000, width: '300px', maxHeight: '400px', overflowY: 'auto' }}
            >
                <h4 className="fw-700 font-xss mb-4">Notifications</h4>
                {loading ? (
                    <p>Loading...</p>
                ) : notifications.length === 0 ? (
                    <p>No notifications</p>
                ) : (
                    notifications.map(notification => (
                        <div
                            key={notification.id}
                            className={`card bg-transparent-card w-100 border-0 ps-5 mb-3 ${!notification.read_at ? 'bg-light' : ''}`}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleNotificationClick(notification)}
                        >
                            {notification.user && notification.user.profile && notification.user.profile.profile_picture && (
                                <img
                                    src={notification.user.profile.profile_picture}
                                    alt="user"
                                    className="w40 position-absolute left-0"
                                    style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover', margin: '5px' }}
                                />
                            )}
                            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block p-1">
                                {notification.type === 'App\\Notifications\\CommentAdded' ? (
                                    <>New comment added by {notification.user?.name}</>
                                ) : notification.type === 'App\\Notifications\\LikeAdded' ? (
                                    <>New like from {notification.user?.name}</>
                                ) : notification.type === 'App\\Notifications\\FollowerAdded' ? (
                                    <>New follower Added</>
                                ) : (
                                    <>New notification from {notification.user?.name}</>
                                )}
                                <span className="text-grey-400 font-xsssss fw-600 float-right p-1">{notification.created_at_human}</span>
                            </h5>
                            <h6 className="text-grey-500 fw-500 font-xssss lh-4 p-1">
                                {notification.type === 'App\\Notifications\\CommentAdded' ? (
                                    notification.comment ? (
                                        notification.comment.length > 50 ? `${notification.comment.substring(0, 50)}...` : notification.comment
                                    ) : (
                                        "No comment available"
                                    )
                                ) : notification.type === 'App\\Notifications\\LikeAdded' ? (
                                    "Liked your content"
                                ) : notification.type === 'App\\Notifications\\FollowerAdded' ? (
                                    `${notification.user?.name} started following you`
                                ) : (
                                    "New notification"
                                )}
                            </h6>
                        </div>
                    ))
                )}
            </div>

            <Modal show={!!selectedComment} onHide={closeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Comment Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-start'>
                    <p className="text-black">{selectedComment}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default HeaderNotification;
