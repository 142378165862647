import React, { useState, useEffect } from 'react';
import Helpers from '../../Config/Helpers';
import PreLoader from '../../Components/PreLoader';
import axios from 'axios';

function SuggestedFollows() {
    const [loading, setLoading] = useState(true);
    const [suggestFollows, setSuggestFollows] = useState([]);

    // Fetch data function
    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token'); // Adjust this line based on how you store the token

            const response = await axios.get(`${Helpers.apiUrl}auth/suggest-follow`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const data = response.data.data;
            setSuggestFollows(data);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch suggested follows', error);
            setLoading(false);
        }
    };

    // Use effect to fetch data on mount
    useEffect(() => {
        fetchData();
    }, []);

    const handleFollow = async (userId) => {
        try {
            const token = localStorage.getItem('token'); // Adjust token retrieval as needed
            await axios.post(`${Helpers.apiUrl}auth/followers`, { userId: userId }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            Helpers.toast('success', 'Followed successfully');
            
            // Re-fetch the suggestions after following
            fetchData();
        } catch (error) {
            console.error('Failed to follow the user', error);
            alert('Failed to follow.');
        }
    };

    const goToProfile = (userId) => {
        window.location.href = `/user-detail/${userId}`;
    };

    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className="container">
                    <h1 className="text-center mb-3">Suggested Follows</h1>
                    <div className="row justify-content-start"> 
                        {suggestFollows && suggestFollows.length > 0 ? (
                            suggestFollows.map((suggest, index) => (
                                <div key={index} className="col-6 col-sm-6 col-md-3 col-lg-3 mb-4 d-flex justify-content-center">
                                    <div className="card text-center shadow-md h-100 d-flex flex-column align-items-center rounded-lg hover-card">
                                        <img
                                            src={suggest?.profile?.profile_picture || 'https://via.placeholder.com/100'}
                                            alt={`${suggest.name}'s profile`}
                                            className="card-img-top mt-3 rounded-circle"
                                            style={{ width: '80px', height: '80px', objectFit: 'cover', transition: 'transform 0.3s ease-in-out' }}
                                        />
                                        <div className="card-body d-flex flex-column justify-content-between w-100">
                                            <h5 className="card-title" style={{ fontSize: '14px', fontWeight: 'bold' }}>{suggest.name}</h5>
                                            <span className="card-text" style={{ fontSize: '12px', color: '#888' }}>Followers : {suggest.followers_count}</span>
                                            <span className="card-text" style={{ fontSize: '12px', color: '#888' }}>Followings : {suggest.followings_count}</span>
                                            <div className="d-flex justify-content-center gap-2 mt-2">
                                                <button
                                                    className="btn btn-sm btn-primary text-white"
                                                    onClick={() => handleFollow(suggest.id)}
                                                >
                                                    Follow
                                                </button>
                                                <button
                                                    className="btn btn-sm btn-primary text-white"
                                                    onClick={() => goToProfile(suggest.id)}
                                                >
                                                    Go to Profile
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className='text-center'>No suggested follows available</p>
                        )}
                    </div>
                </div>
            )}
            <style>
                {`
                .hover-card {
                    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                    border-radius: 14px;
                    overflow: hidden;
                }
                .hover-card:hover {
                    transform: scale(1.03);
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 6px 16px rgba(0, 0, 0, 0.1);
                }
                .hover-card .card-body {
                    padding: 15px;
                }
                .rounded-lg {
                    border-radius: 10px;
                }
                .card-img-top {
                    transition: transform 0.3s ease-in-out;
                }
                .card-img-top:hover {
                    transform: scale(1.1);
                }
                `}
            </style>
        </div>
    );
}

export default SuggestedFollows;
