import React, { useState, useEffect } from 'react';
import PreLoader from '../Components/PreLoader';
import { useNavigate } from 'react-router-dom';

export default function PrivacyPolicy() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    const currentYear = new Date().getFullYear(); // Dynamic year for the footer

    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className="main-content bg-lightblue theme-dark-bg py-2">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <div className="row">
                                        <div className="col-lg-12 text-start">
                                            <h5 className="mb-4 font-lg fw-700 mont-font mb-3 mb-2 font-md-xs text-start">Privacy Policy</h5>
                                            
                                            <h5 className="mb-2 font-sm fw-500 mont-font font-md-xs text-start">Introduction</h5>
                                            <p>Welcome to Blakbox, an app designed for users to share and interact with digital content such as photos, music, and videos. In this privacy policy, we explain how we collect, use, and safeguard your personal data when you use our app. The privacy of our users is important to us, and we are committed to protecting your personal information.</p>

                                            <h5 className="mb-2 font-sm fw-500 mont-font font-md-xs text-start">Types of Users</h5>
                                            <h4 className='mont-font fw-400'>Blakbox has two types of users:</h4>
                                            <ul>
                                                <li><strong>Artists:</strong> Artists can upload photos, music, and videos, interact with other artists, and view and follow their profiles. Artists can also like and comment on content.</li>
                                                <li><strong>Users:</strong> Users can view the content uploaded by artists, comment, like the content, and follow the artists, but they cannot upload content themselves.</li>
                                            </ul>

                                            <h5 className="mb-2 mt-2 font-sm fw-500 mont-font font-md-xs text-start">Information We Collect</h5>
                                            <ul>
                                                <li><strong>Account Information:</strong> When you register for an account, we collect information such as your name, email address, and any profile information you provide.</li>
                                                <li><strong>Content Uploads:</strong> Artists can upload photos, music, and videos, which are stored and shared on the app for other users to view.</li>
                                                <li><strong>Interaction Data:</strong> We collect data on the likes, comments, follows, and views you make on the app.</li>
                                                <li><strong>Device Information:</strong> When uploading content, we may request access to your device's camera, microphone, or file storage to facilitate the upload process.</li>
                                            </ul>

                                            {/* Notification Permissions Section */}
                                            <h5 className="mb-2 mt-2 font-sm fw-500 mont-font font-md-xs text-start">Notification Permissions</h5>
                                            <p>Blakbox sends notifications for comments, likes, and follows on your content. You can control notification preferences and permissions through your device settings.</p>

                                            <h5 className="mb-2 mt-2 font-sm fw-500 mont-font font-md-xs text-start">How We Use Your Information</h5>
                                            <ul>
                                                <li><strong>Providing and Improving Services:</strong> To enable the core features of the app, such as allowing artists to upload content and enabling interactions between users.</li>
                                                <li><strong>Personalization:</strong> To recommend content and other artists based on your interactions, such as follows, likes, comments, and shares.</li>
                                                <li><strong>Communication:</strong> To respond to user inquiries, troubleshoot issues, and notify users about updates or changes to the app.</li>
                                                <li><strong>Security:</strong> To protect your account and ensure the integrity of the app.</li>
                                            </ul>

                                            <h5 className="mb-2 mt-2 font-sm fw-500 mont-font font-md-xs text-start">Sharing of Information</h5>
                                            <p>We do not share your personal information with third parties for marketing purposes. However, your content (photos, music, videos, likes, and comments) will be visible to other users within the app. We may share data with third-party service providers to support the functionality of the app, such as cloud storage providers.</p>

                                            <h5 className="mb-2 mt-2 font-sm fw-500 mont-font font-md-xs text-start">User Control and Privacy</h5>
                                            <ul>
                                                <li><strong>Profile Visibility:</strong> Your profile and uploaded content are visible to other users of the app. Simple users can interact with content by liking and commenting, but cannot upload content.</li>
                                                <li><strong>Content Sharing:</strong> Any content you upload (photos, music, videos) will be publicly available within the app.</li>
                                                <li><strong>Access to Device:</strong> If you choose to upload content, we will ask for permission to access your camera, microphone, or file storage. You can control these permissions through your device settings.</li>
                                            </ul>

                                            <h5 className="mb-2 mt-2 font-sm fw-500 mont-font font-md-xs text-start">Data Security</h5>
                                            <p>We take reasonable precautions to protect your personal information. However, no method of transmission over the Internet or method of electronic storage is completely secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.</p>

                                            <h5 className="mb-2 mt-2 font-sm fw-500 mont-font font-md-xs text-start">Your Rights</h5>
                                            <ul>
                                                <li><strong>Access and Update Information:</strong> You can update or correct your profile and content information at any time through your account settings.</li>
                                                <li><strong>Delete Account:</strong> You can request to delete your account at any time.</li>
                                            </ul>

                                            <h5 className="mb-2 mt-2 font-sm fw-500 mont-font font-md-xs text-start">Changes to this Privacy Policy</h5>
                                            <p>We may update this privacy policy from time to time. We will notify users of any significant changes by posting a notice within the app or sending an email to registered users.</p>

                                            <h5 className="mb-2 mt-2 font-sm fw-500 mont-font font-md-xs text-start">Contact Us</h5>
                                            <p>If you have any questions about this privacy policy, please contact us at <a href="mailto:support@blakboxapp.com">support@blakboxapp.com</a>.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Footer Section */}
                            <footer className="text-center text-gray-600 mt-4">
                                © {currentYear} Cyberify Services. All rights reserved.
                            </footer>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
