import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Config/Helpers';
import OnboardingForm from '../OnboardingForm';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showOnboarding, setShowOnboarding] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = [];
        if (!email) {
            validationErrors.push('Email is required');
        } else if (!validateEmail(email)) {
            validationErrors.push('Please enter a valid email address');
        }
        if (!password) {
            validationErrors.push('Password is required');
        }

        if (validationErrors.length > 0) {
            const errorMessage = validationErrors.join('<br />');
            Helpers.toast('error', errorMessage);
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(`${Helpers.apiUrl}auth/login`, { email, password });
            const { token, user } = response.data;

            // Set token and user info
            Helpers.setItem('token', token);
            Helpers.setItem('user', user, true);

            // Set the default headers for axios
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            Helpers.toast('success', 'Login Successful');

            // Check if the user has completed onboarding
            const checkResponse = await axios.get(`${Helpers.apiUrl}auth/check-user-feature`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (!checkResponse.data.userFeature) {
                setShowOnboarding(true);
            } else {
                navigate('/');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Login failed';
            Helpers.toast('error', errorMessage);
        } finally {
            setLoading(false);
        }
    };

    if (showOnboarding) {
        return <OnboardingForm onComplete={() => navigate('/')} />;
    }

    return (
        <div className="main-wrap">
            <div className="nav-header bg-transparent shadow-none border-0">
                <div className="nav-top w-100 d-flex justify-content-between align-items-center">
                    <a href="/" className="flex items-center">
                        <img
                            src="./assets/logo/blackbox-logo.png"
                            alt="Blackbox Logo"
                            className="h-10 mr-2"
                            style={{ width: '150px', display: 'block' }}
                        />
                    </a>
                </div>
            </div>
            <div className="row">
                <div
                    className="col-xl-5 d-none d-xl-block p-0 vh-50 bg-image-cover bg-no-repeat"
                    style={{
                        backgroundImage: 'url(./assets/logo/login.png)',
                        width: '40%',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: 'auto'
                    }}
                ></div>
                <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                    <div className="card shadow-none border-0 ms-auto me-auto login-card">
                        <div className="card-body rounded-0 text-left">
                            <h2 className="fw-700 display1-size display2-md-size mb-3">Login into <br />Blakbox</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group icon-input mb-3">
                                    <i className="font-sm ti-email text-grey-500 pe-0"></i>
                                    <input
                                        type="email"
                                        className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                        placeholder="Your Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete="email"
                                    />
                                </div>
                                <div className="form-group icon-input mb-1">
                                    <input
                                        type="password"
                                        className="style2-input ps-5 form-control text-grey-900 font-xss ls-3"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="current-password"
                                    />
                                    <i className="font-sm ti-lock text-grey-500 pe-0"></i>
                                </div>
                                <div className="form-check text-left mb-3">
                                    <Link to="/forgot-password" className="fw-600 font-xsss text-grey-700 mt-1 float-right">Forgot your Password?</Link>
                                </div>
                                <div className="col-sm-12 p-0 text-left">
                                    <div className="form-group mb-1">
                                        <button type="submit" className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0">
                                            {loading ? 'Logging in...' : 'Login'}
                                        </button>
                                    </div>
                                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                        Don't have an account?
                                        <Link to="/register" className="fw-700 ms-1">Register</Link>
                                    </h6>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
