import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab, Modal, Button, Carousel } from 'react-bootstrap';
import axios from 'axios';
import Helpers from '../../Config/Helpers';
import { debounce } from 'lodash';
import PreLoader from '../../Components/PreLoader';


const BookmarkedContent = () => {
    const [key, setKey] = useState('photo');
    const [loading, setLoading] = useState(true);
    const [photoData, setPhotoData] = useState([]);
    const [videoData, setVideoData] = useState([]);
    const [musicData, setMusicData] = useState([]);
    const [showPhotoModal, setShowPhotoModal] = useState(false);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const videoRefs = useRef([]);
    const playPauseLocks = useRef([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token'); // Adjust this line based on how you store the token

                const response = await axios.get(`${Helpers.apiUrl}auth/bookmark`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                
                const data = response.data.data;
                setPhotoData(data.filter(content => content.type === 'photo'));
                setVideoData(data.filter(content => content.type === 'video'));
                setMusicData(data.filter(content => content.type === 'music'));

                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch bookmarked content', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleVideoHover = debounce(async (index) => {
        const video = videoRefs.current[index];
        if (video) {
            video.currentTime = 0; // Start playing from the beginning
            if (!playPauseLocks.current[index]) {
                playPauseLocks.current[index] = true;
                try {
                    await video.play();
                } catch (error) {
                    console.log('Video play interrupted', error);
                } finally {
                    playPauseLocks.current[index] = false;
                }
            }
        }
    }, 500);

    const handleVideoLeave = debounce(async (index) => {
        const video = videoRefs.current[index];
        if (video) {
            if (!playPauseLocks.current[index]) {
                playPauseLocks.current[index] = true;
                try {
                    await video.pause();
                } catch (error) {
                    console.log('Video pause interrupted', error);
                } finally {
                    playPauseLocks.current[index] = false;
                }
            }
        }
    }, 500);

    const handleVideoPlayPause = async (index) => {
        const video = videoRefs.current[index];
        if (video && !playPauseLocks.current[index]) {
            playPauseLocks.current[index] = true;
            try {
                if (video.paused) {
                    await video.play();
                } else {
                    await video.pause();
                }
            } catch (error) {
                console.log('Video play/pause interrupted', error);
            } finally {
                playPauseLocks.current[index] = false;
            }
        }
    };

    const handlePhotoClick = (photos, index) => {
        setSelectedPhotos(photos);
        setCurrentPhotoIndex(index);
        setShowPhotoModal(true);
    };

    const renderTabContent = (data) => {
        return (
            <div className='text-start mt-3'>
                <div className="grid">
                    {data.map((content, index) => {
                        // Check if file_path is an array or a string
                        let filePaths;
                        try {
                            filePaths = Array.isArray(content.file_path)
                                ? content.file_path
                                : JSON.parse(content.file_path); // Parse the file_path as JSON if it's a string
                        } catch (error) {
                            filePaths = [content.file_path]; // Treat it as a single string if not JSON
                        }

                        return (
                            <div key={index} className="card rounded-xxl border-0 hover-card">
                                <div className="card-body">
                                    <h5>{content.title}</h5>

                                    {content.type === 'video' && filePaths.length > 0 && (
                                        <video
                                            ref={el => videoRefs.current[index] = el}
                                            loop
                                            className="w-100 rounded-lg"
                                            controls
                                            muted
                                            onMouseOver={() => handleVideoHover(index)}
                                            onMouseLeave={() => handleVideoLeave(index)}
                                            onClick={() => handleVideoPlayPause(index)}
                                            style={{ objectFit: 'cover', height: '200px', transition: 'transform 0.3s ease-in-out', borderRadius: '10px' }}
                                        >
                                            <source src={filePaths[0]} type="video/mp4" />
                                        </video>
                                    )}

                                    {content.type === 'photo' && filePaths.length > 0 && (
                                        <div>
                                            {filePaths.length === 1 ? (
                                                <img
                                                    src={filePaths[0]}
                                                    alt="photo"
                                                    className="w-100"
                                                    onClick={() => handlePhotoClick(filePaths, 0)}
                                                    style={{ objectFit: 'cover', height: '200px', transition: 'transform 0.3s ease-in-out', borderRadius: '10px', cursor: 'pointer' }}
                                                />
                                            ) : (
                                                <Carousel>
                                                    {filePaths.map((url, idx) => (
                                                        <Carousel.Item key={idx}>
                                                            <img
                                                                src={url}
                                                                alt={`Photo ${idx}`}
                                                                className="d-block w-100"
                                                                style={{ objectFit: 'cover', height: '200px', borderRadius: '10px', cursor: 'pointer' }}
                                                                onClick={() => handlePhotoClick(filePaths, idx)}
                                                            />
                                                        </Carousel.Item>
                                                    ))}
                                                </Carousel>
                                            )}
                                        </div>
                                    )}

                                    {content.type === 'music' && filePaths.length > 0 && (
                                        <audio
                                            controls
                                            className="w-100 rounded-md audio-player"
                                            style={{ borderRadius: '10px' }}
                                        >
                                            <source src={filePaths[0]} type="audio/mpeg" />
                                        </audio>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <div>
                    <PreLoader />
                </div>
            ) : (
                <div className="main-content theme-dark-bg py-2 mt-3">
                    <div className="middle-sidebar-left mt-2">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-lg-5 p-4 w-100 border-0">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <Tabs id="bookmarked-tabs" activeKey={key} onSelect={(k) => setKey(k)} className="custom-tabs">
                                                <Tab eventKey="photo" title="Photo" className="custom-tab">
                                                    {renderTabContent(photoData)}
                                                </Tab>
                                                <Tab eventKey="video" title="Video" className="custom-tab">
                                                    {renderTabContent(videoData)}
                                                </Tab>
                                                <Tab eventKey="music" title="Music" className="custom-tab">
                                                    {renderTabContent(musicData)}
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for displaying photos */}
            {selectedPhotos.length > 0 && (
                <Modal show={showPhotoModal} onHide={() => setShowPhotoModal(false)} size="md" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Photo Viewer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedPhotos.length === 1 ? (
                            <img
                                src={selectedPhotos[0]}
                                alt="Selected"
                                style={{
                                    maxWidth: '100%',
                                    maxHeight: '70vh',
                                    display: 'block',
                                    margin: 'auto'
                                }}
                            />
                        ) : (
                            <Carousel activeIndex={currentPhotoIndex} onSelect={(selectedIndex) => setCurrentPhotoIndex(selectedIndex)}>
                                {selectedPhotos.map((url, idx) => (
                                    <Carousel.Item key={idx}>
                                        <img
                                            src={url}
                                            alt={`Photo ${idx + 1}`}
                                            className="d-block w-100"
                                            style={{
                                                maxHeight: '70vh',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowPhotoModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            <style>
                {`
                .custom-tabs .nav-link {
                    border: none;
                    padding: 0.5rem 1rem;
                    margin-right: 0.25rem;
                    font-size: 1rem;
                    font-weight: 500;
                    color: #4A5568;
                }
                .custom-tabs .nav-link.active {
                    border-bottom: 2px solid #3182CE;
                    color: #3182CE;
                }
                .grid {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px;
                }
                .card {
                    flex: 1 1 calc(33.33% - 16px); /* Three cards in a row */
                    box-sizing: border-box;
                    padding: 16px;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                }
                .card-body {
                    padding: 0;
                    margin: 0;
                }
                .video-card video {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                    transition: transform 0.3s ease-in-out;
                }
                .photo-card img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                    transition: transform 0.3s ease-in-out;
                }
                .music-card audio {
                    width: 100%;
                    border-radius: 5px;
                    background-color: #f1f1f1;
                }
                .hover-card {
                    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                }
                .hover-card:hover {
                    transform: scale(1.05);
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 6px 16px rgba(0, 0, 0, 0.1);
                }
                .description {
                    display: -webkit-box;
                    -webkit-line-clamp: 1; /* Number of lines to show */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: normal;
                }
                .audio-player {
                    width: 100%;
                    border-radius: 5px;
                    background-color: #f1f1f1;
                }
                `}
            </style>
        </div>
    );
};

export default BookmarkedContent;
