import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import PreLoader from '../../Components/PreLoader';
import Helpers from '../../Config/Helpers';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

export default function UploadVideos() {
    const [loading, setLoading] = useState(true);
    const [contents, setContents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tagId, setTagId] = useState('');
    const [subCategoryId, setSubCategoryId] = useState('');
    const [tags, setTags] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [contentVisibility, setContentVisibility] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [contentsResponse, tagsResponse, subCategoriesResponse] = await Promise.all([
                    axios.get(`${Helpers.apiUrl}auth/contents`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    }),
                    axios.get(`${Helpers.apiUrl}auth/fetch-tags`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    }),
                    axios.get(`${Helpers.apiUrl}auth/fetch-sub-categories`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    })
                ]);

                if (contentsResponse.status === 200) {
                    setContents(contentsResponse.data.data);
                }

                if (tagsResponse.status === 200) {
                    setTags(tagsResponse.data.data);
                }

                if (subCategoriesResponse.status === 200) {
                    setSubCategories(subCategoriesResponse.data.data);
                }
            } catch (error) {
                Helpers.toast('error', 'Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleDelete = async (id) => {
        try {
            const response = await axios.delete(`${Helpers.apiUrl}auth/contents/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.status === 200) {
                setContents(contents.filter(content => content.id !== id));
                Helpers.toast('success', 'Data deleted successfully');
            }
        } catch (error) {
            console.error('Failed to delete content:', error);
            Helpers.toast('error', 'Failed to delete content');
        }
    };
    const truncateDescription = (description, length) => {
        if (!description) return '';
        return description.length > length ? `${description.substring(0, length)}...` : description;
    };
    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className="main-content bg-lightblue theme-dark-bg py-2">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to="/" className="d-inline-block mt-2">
                                            <i className="ti-arrow-left font-sm text-white"></i>
                                        </Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Uploads</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    {/* <th scope="col">Title</th> */}
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Media</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {contents.map((content, index) => (
                                                    <tr key={content.id}>
                                                        <th scope="row">{index + 1}</th>
                                                        {/* <td>{content.title}</td> */}
                                                        <td>{truncateDescription(content.description || '', 70)}</td>
                                                        <td>{content.type}</td>
                                                        <td>
                                                            {content.type === 'video' && (
                                                                <video controls className="w-100">
                                                                    <source src={content.file_path} type="video/mp4" />
                                                                </video>
                                                            )}
                                                            {content.type === 'photo' && (
                                                                <img
                                                                    src={content.file_path}
                                                                    alt="photo"
                                                                    className="w-100"
                                                                    style={{ objectFit: 'cover' }}
                                                                />
                                                            )}
                                                            {content.type === 'music' && (
                                                                <audio controls className="w-100">
                                                                    <source src={content.file_path} type="audio/mpeg" />
                                                                </audio>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-danger" onClick={() => handleDelete(content.id)}>Delete</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
