import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Config/Helpers';
import OnboardingForm from '../OnboardingForm';

const OnboardingChecker = ({ children }) => {
    const [showOnboarding, setShowOnboarding] = useState(false);
    const navigate = useNavigate();

    const checkOnboarding = async () => {
        const token = Helpers.getItem('token');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            try {
                const checkResponse = await axios.get(`${Helpers.apiUrl}auth/check-user-feature`);
                if (!checkResponse.data.userFeature || checkResponse.data.userFeature.length === 0) {
                    setShowOnboarding(true);
                    navigate('/onboarding');
                } 
            } catch (error) {
                console.error('Error checking user feature:', error);
                Helpers.toast('error', 'Error checking onboarding status');
            }
        }
    };
    
    useEffect(() => {
        checkOnboarding();
    }, []);
    

    if (showOnboarding) {
        return <OnboardingForm onComplete={checkOnboarding} />;
    }

    return children;
};

export default OnboardingChecker;
