import axios from 'axios';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import Helpers from '../Config/Helpers';

let echo = null;

const initializeEcho = () => {
    if (!echo) {
        echo = new Echo({
            broadcaster: 'pusher',
            key: '5df2d5dde64134a7b8f3',
            cluster: 'ap2',
            forceTLS: true,
            authorizer: (channel, options) => {
                return {
                    authorize: (socketId, callback) => {
                        axios.post(`${Helpers.apiUrl}broadcasting/auth`, {
                            socket_id: socketId,
                            channel_name: channel.name
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        })
                        .then(response => {
                            callback(false, response.data);
                        })
                        .catch(error => {
                            callback(true, error);
                        });
                    }
                };
            }
        });
    }
    return echo;
};

export default initializeEcho;
