import './App.css';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import HeaderNotification from './Components/HeaderNotifications';
import Layout from './Layout';
import Home from './Pages/Home';
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
import DefaultSettings from './Components/DefaultSettings';
import ForgotPassword from './Pages/Auth/ForgotPassword';
import VerifyCode from './Pages/Auth/VerifyCode';
import ResetPassword from './Pages/Auth/ResetPassword';
import PrivateRoute from './Components/PrivateRoute';
import ProfileSettings from './Pages/User/ProfileSettings';
import UploadVideos from './Pages/Artist/UploadVideos';
import AuthorProfile from './Pages/Artist/AuthorProfile';
import ArtistVideos from './Pages/Artist/ArtistVideos';
import ArtistPhotos from './Pages/Artist/ArtistPhotos';
import ArtistMusic from './Pages/Artist/ArtistMusic';
import ContentPage from './Pages/User/ContentPage';
import SearchResults from './Components/SearchResults';
import ArtistDetailsPage from './Pages/Artist/ArtistDetailsPage';
import UploadFiles from './Pages/Artist/UploadedFiles';
import CommentList from './Components/CommentList';
import Featured from './Pages/Artist/Featured';
import VideoDetail from './Pages/VideoDetail';
import UserDetail from './Pages/User/UserDetail';
import BookmarkedContent from './Pages/User/BookmarkedContent';
import OnboardingChecker from './Pages/Auth/OnboardingChecker';
import OnboardingForm from './Pages/OnboardingForm';
import SuggestedFollows from './Pages/User/SuggestedFollows';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import ParticipateContest from './Components/ParticipateContest';


function App() {
  return (
    <BrowserRouter>
      <HeaderNotification />
      <Routes>
        {/* Public routes */}
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/verify-code' element={<VerifyCode />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path="/onboarding" element={<OnboardingForm />} />
        {/* Protected routes, with OnboardingChecker */}
        <Route path="/" element={
          <PrivateRoute>
            <OnboardingChecker>
              <Layout />
            </OnboardingChecker>
          </PrivateRoute>
        }>
          <Route path='/' element={<Home />} />
          <Route path='/default-settings' element={<DefaultSettings />} />
          <Route path='/profile-settings' element={<ProfileSettings />} />
          <Route path='/upload-videos' element={<UploadVideos />} />
          <Route path='/upload-files' element={<UploadFiles />} />
          <Route path='/author-profile' element={<AuthorProfile />} />
          <Route path='/artist-videos' element={<ArtistVideos />} />
          <Route path='/artist-photos' element={<ArtistPhotos />} />
          <Route path='/artist-music' element={<ArtistMusic />} />
          <Route path="/content/:contentId" element={<ContentPage />} />
          <Route path="/search-results" element={<SearchResults />} />
          <Route path="/user/:id" element={<ArtistDetailsPage />} />
          <Route path="/all-comments/:contentId" element={<CommentList />} />
          <Route path="/featured" element={<Featured />} />
          <Route path="/video/:id" element={<VideoDetail />} />
          <Route path="/user-detail/:userId" element={<UserDetail />} />
          <Route path="/bookmarked-content" element={<BookmarkedContent />} />
          <Route path="/suggested-follows" element={<SuggestedFollows />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contest-participate/:contestId" element={<ParticipateContest />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
