import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PreLoader from './PreLoader';
import Helpers from '../Config/Helpers';
import { Modal, Button, Form } from 'react-bootstrap';
import EmojiPicker from 'emoji-picker-react';
import axios from 'axios';
import Select from 'react-select';

const ParticipateContest = () => {
    const { contestId } = useParams();
    const [loading, setLoading] = useState(true);
    const [contest, setContest] = useState(null);
    const [profileData, setProfileData] = useState({});
    const [videos, setVideos] = useState([]);
    const [authorContent, setAuthorContent] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [keywordInput, setKeywordInput] = useState('');
    const [file, setFile] = useState([]);
    const [fileUrl, setFileUrl] = useState([]);
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [contentVisibility, setContentVisibility] = useState('');
    const [aspectRatio, setAspectRatio] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [subcategories, setSubcategories] = useState([]);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [technology, setTechnology] = useState('');
    const [medium, setMedium] = useState('');
    const [keywordTags, setKeywordTags] = useState([]);
    const [collaborators, setCollaborators] = useState([]);
    const [title, setTitle] = useState('');
    const [collaboratorInput, setCollaboratorInput] = useState('');

    const fetchData = async (url) => {
        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching data", error);
            return null;
        }
    };

    useEffect(() => {
        const fetchContestDetails = async () => {
            setLoading(true);
            const contestData = await fetchData(`${Helpers.apiUrl}auth/contest/${contestId}`);
            if (contestData) setContest(contestData.data[0]);
            setLoading(false);
        };

        fetchContestDetails();
    }, [contestId]);

    useEffect(() => {
        const fetchProfileRelatedData = async () => {
            setLoading(true);

            const [profile, subcategoriesRes, languagesRes, locationsRes, authorContentRes] = await Promise.all([
                fetchData(`${Helpers.apiUrl}auth/profiles`),
                fetchData(`${Helpers.apiUrl}auth/fetch-sub-categories`),
                fetchData(`${Helpers.apiUrl}auth/fetch-languages`),
                fetchData(`${Helpers.apiUrl}auth/fetch-locations`),
                fetchData(`${Helpers.apiUrl}auth/author-content`),
            ]);

            if (profile) setProfileData(profile);
            if (subcategoriesRes) {
                const groupedOptions = subcategoriesRes.data.reduce((acc, sub) => {
                    const category = sub.category;
                    if (!acc[category]) acc[category] = [];
                    acc[category].push({ value: sub.id, label: sub.sub_category });
                    return acc;
                }, {});
                setSubcategories(Object.keys(groupedOptions).map(category => ({
                    label: category,
                    options: groupedOptions[category],
                })));
            }
            if (languagesRes) setLanguages(languagesRes.data.map(lang => ({ value: lang.name, label: lang.name })));
            if (locationsRes) setLocations(locationsRes.data.map(loc => ({ value: loc.name, label: loc.name })));
            if (authorContentRes) setAuthorContent(authorContentRes.data);

            setLoading(false);
        };

        fetchProfileRelatedData();
    }, []);

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFile([...file, ...selectedFiles]);
        const urls = selectedFiles.map(file => URL.createObjectURL(file));
        setFileUrl([...fileUrl, ...urls]);
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && keywordInput.trim() !== '') {
            e.preventDefault();
            setKeywordTags([...keywordTags, keywordInput.trim()]);
            setKeywordInput('');
        }
    };
    const handleCollaboratorKeyPress = (e) => {
        if (e.key === 'Enter' && collaboratorInput.trim() !== '') {
            e.preventDefault();
            setCollaborators([...collaborators, collaboratorInput.trim()]);
            setCollaboratorInput('');
        }
    };

    const handleRemoveCollaborator = (index) => {
        setCollaborators(collaborators.filter((_, i) => i !== index));
    };
    const getAcceptType = () => {
        switch (type) {
            case 'video':
                return 'video/*';
            case 'photo':
                return 'image/*';
            case 'music':
                return 'audio/*';
            default:
                return '';
        }
    };

    const handleUploadContent = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        if (file.length === 0) {
            Helpers.toast('error', 'Please select a file to upload');
            return; // Prevent form submission if no file is selected
        }
        if (type === 'photo') {
            file.forEach((f, index) => formData.append(`file[${index}]`, f)); // Multiple photos allowed
        } else if (type === 'video' || type === 'music') {
            formData.append('file', file[0]); // Only one file for video/music
        }
        
        // file.forEach((f, index) => formData.append(`file[${index}]`, f));
        formData.append('type', type);
        formData.append('description', description);
        formData.append('content_visibility', contentVisibility);
        formData.append('aspect_ratio', aspectRatio);
        formData.append('title', title);
        formData.append('genres', JSON.stringify(selectedGenres));
        formData.append('technology', technology);
        formData.append('medium', medium);
        formData.append('keyword_tags', JSON.stringify(keywordTags));
        formData.append('collaborators', JSON.stringify(collaborators));
        formData.append('language', selectedLanguage?.value || '');
        formData.append('location', selectedLocation?.value || '');
        formData.append('contest_id', contestId);
        // console.log('data comes',formData);
        
        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/contents`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 201) {
                Helpers.toast('success', 'Data Uploaded Successfully');
                resetForm(); // Reset form state after successful upload
                setIsModalOpen(false);
            } else {
                Helpers.toast('error', 'Failed to upload content');
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to upload content');
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setFile([]);
        setFileUrl([]);
        setType('');
        setContentVisibility('');
        setAspectRatio('');
        setDescription('');
        setTitle('');
        setSelectedGenres([]);
        setTechnology('');
        setMedium('');
        setKeywordTags([]);
        setCollaborators([]);
        setSelectedLanguage(null);
        setSelectedLocation(null);
    };

    const handleGenreChange = (selectedOptions) => {
        setSelectedGenres(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };
    const handleRemoveTag = (index) => {
        setKeywordTags(keywordTags.filter((_, i) => i !== index));
    };
   

    if (!contest) {
        return <p>No contest found or it's not currently running.</p>;
    }
    const handleEmojiClick = (emojiObject) => {
        setDescription(prevDescription => prevDescription + emojiObject.emoji);
    };

      
    const iconStyle = {
        backgroundImage: 'url("https://static.xx.fbcdn.net/rsrc.php/v3/yW/r/hnYJwtcMFVV.png?_nc_eui2=AeGu_Ns0XyIF8YGDgkLZ4euKwhg5DgQVGCfCGDkOBBUYJ-zr8ViWDiVFSbVjLU_zlfs5lOO2oaUKDfhunHDM6JTp")',
        backgroundPosition: '0px -254px',
        backgroundSize: 'auto',
        width: '20px',
        height: '20px',
        backgroundRepeat: 'no-repeat',
        display: 'inline-block'
    };
    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <PreLoader />
            ) : (
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left d-flex flex-column justify-content-center align-items-center">
                    <div className="col-xl-8 col-xxl-9 col-lg-8">
                        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                            <h1 className="mb-4 text-center">Participate in {contest.name}</h1>
                            <div className="card-body">
                                <ul style={{ listStyleType: 'none', paddingLeft: '0', lineHeight: '2rem', color: '#555', textAlign: 'left' }}>
                                    <li>
                                        <strong>Contest Name:</strong> <span>{contest.name}</span>
                                    </li>
                                    <li>
                                        <strong>Contest Type:</strong>
                                        <span>{contest.categories?.map(category => category.name).join(', ') || 'No Category'}</span>
                                    </li>
                                    <li>
                                        <strong>Start Time:</strong> <span>{contest.start_date}</span>
                                    </li>
                                    <li>
                                        <strong>End Time:</strong> <span>{contest.end_date}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-8 col-xxl-9 col-lg-8">
                        <div className="card justify-content-center w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                            <div className="card-body d-flex justify-content-center align-items-center p-0 mt-0">
                                <a href="#" onClick={() => { setType('video'); setIsModalOpen(true); }} className="d-flex align-items-center font-xssss fw-600 ls-1 text-gray-700 text-dark pe-4">
                                    <i className="font-md text-success feather-video me-2"></i><span className="d-none-xs">Videos</span>
                                </a>
                                <a href="#" onClick={() => { setType('photo'); setIsModalOpen(true); }} className="d-flex align-items-center font-xssss fw-600 ls-1 text-gray-700 text-dark pe-4">
                                    <i className="font-md text-warning feather-image me-2"></i><span className="d-none-xs">Photos</span>
                                </a>
                                <a href="#" onClick={() => { setType('music'); setIsModalOpen(true); }} className="d-flex align-items-center font-xssss fw-600 ls-1 text-gray-700 text-dark">
                                    <i className="font-md text-danger feather-music me-2"></i><span className="d-none-xs">Music</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
 )}
            {/* modal code start */}
            <Modal
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
                centered
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                style={{
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    borderRadius: '20px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Modal.Header closeButton style={{ borderBottom: 'none' }}>
                    <Modal.Title className="w-100 text-center" style={{ fontWeight: 'bolder' }}>Create Post - {type.charAt(0).toUpperCase() + type.slice(1)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleUploadContent}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Group>
                                    <Form.Label className="w-100 text-left">Title <span className='text-red'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        placeholder="Title"
                                        required
                                        style={{
                                            lineHeight: '35px',
                                            borderRadius: '10px',
                                            border: 'none',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="w-100 text-left">Description <span className='text-red'>*</span></Form.Label>
                                    <div style={{ position: 'relative' }}>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            placeholder="What's on your mind?"
                                            required
                                            style={{
                                                lineHeight: '1.5',
                                                padding: '10px',
                                                height: '80px',
                                                borderRadius: '10px',
                                                border: 'none',
                                                boxShadow: 'none',
                                                backgroundColor: 'transparent',
                                                resize: 'none',
                                                outline: 'none',
                                                fontSize: '16px',
                                                fontFamily: 'Arial, sans-serif',
                                            }}
                                        />
                                        <Button
                                            variant="link"
                                            style={{ position: 'absolute', right: '5px', top: '40px' }}
                                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                        >
                                            😀
                                        </Button>
                                    </div>
                                    {showEmojiPicker && (
                                        <div style={{ position: 'absolute', zIndex: 1000 }}>
                                            <EmojiPicker onEmojiClick={handleEmojiClick} />
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                            {(type === 'video' || type === 'photo') && (
                                <Form.Group className="mt-3">
                                    <Form.Label className="w-100 text-left">Aspect Ratio <span className='text-red'>*</span></Form.Label>
                                    <Form.Control
                                        className="form-select"
                                        as="select"
                                        value={aspectRatio}
                                        onChange={(e) => setAspectRatio(e.target.value)}
                                        required
                                        style={{
                                            lineHeight: '35px',
                                            borderRadius: '10px',
                                            border: 'none',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            borderColor: 'gray',
                                        }}
                                    >
                                        <option value="">Select Ratio</option>
                                        <option value="16:9">16:9 Widescreen</option>
                                        <option value="9:16">9:16 Vertical Mobile</option>
                                        <option value="1:1">1:1 Square</option>
                                        <option value="3:4">3:4 Portrait V</option>
                                        <option value="4:3">4:3 Portrait H</option>
                                        <option value="2:3">2:3 Classic</option>
                                        <option value="3:2">3:2 TV</option>
                                    </Form.Control>
                                </Form.Group>
                            )}

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Genres <span className='text-red'>*</span></Form.Label>
                                <Select
                                    options={subcategories}
                                    isMulti
                                    value={selectedGenres.map(id => subcategories.flatMap(cat => cat.options).find(sub => sub.value === id))}
                                    onChange={handleGenreChange}
                                    placeholder="Select Genres"
                                    classNamePrefix="react-select"
                                    isClearable
                                    className='text-left multiple-input'
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            lineHeight: '35px',
                                            borderColor: '#9191AF',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            boxShadow: 'none',
                                        }),
                                    }}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Technology <span className='text-red'>*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    value={technology}
                                    onChange={(e) => setTechnology(e.target.value)}
                                    placeholder="Technology"
                                    required
                                    style={{
                                        lineHeight: '35px',
                                        borderRadius: '10px',
                                        border: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: 'transparent',
                                    }}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Medium</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={medium}
                                    onChange={(e) => setMedium(e.target.value)}
                                    placeholder="Medium"
                                    style={{
                                        lineHeight: '35px',
                                        borderRadius: '10px',
                                        border: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: 'transparent',
                                    }}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Keyword Tags <span className='text-red'>*</span></Form.Label>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Form.Control
                                        type="text"
                                        value={keywordInput}
                                        onChange={(e) => setKeywordInput(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        placeholder="Enter a tag and press Enter"
                                        style={{
                                            lineHeight: '35px',
                                            borderRadius: '10px',
                                            border: 'none',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            marginBottom: '10px',
                                        }}
                                    />
                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                        {keywordTags.map((tag, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', background: '#f0f2f5', padding: '5px 10px', borderRadius: '15px' }}>
                                                {tag}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveTag(index)}
                                                    style={{ marginLeft: '5px', background: 'transparent', border: 'none', cursor: 'pointer', color: 'crimson' }}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Collaborators <span className='text-red'>*</span></Form.Label>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Form.Control
                                        type="text"
                                        value={collaboratorInput}
                                        onChange={(e) => setCollaboratorInput(e.target.value)}
                                        onKeyPress={handleCollaboratorKeyPress}
                                        placeholder="Enter a name and press Enter"
                                        style={{
                                            lineHeight: '35px',
                                            borderRadius: '10px',
                                            border: 'none',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            marginBottom: '10px',
                                        }}
                                    />
                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                        {collaborators.map((collaborator, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', background: '#f0f2f5', padding: '5px 10px', borderRadius: '15px' }}>
                                                {collaborator}
                                                <button
                                                    type="button"
                                                    onClick={() => handleRemoveCollaborator(index)}
                                                    style={{ marginLeft: '5px', background: 'transparent', border: 'none', cursor: 'pointer', color: 'crimson' }}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Language <span className='text-red'>*</span></Form.Label>
                                <Select
                                    options={languages}
                                    value={selectedLanguage}
                                    onChange={setSelectedLanguage}
                                    placeholder="Select Language"
                                    classNamePrefix="react-select"
                                    isClearable
                                    className='text-left input-selector'
                                    required
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            borderColor: '#9191AF',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            lineHeight: '35px',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            border: '1px solid #9191AF',
                                            boxShadow: 'none',
                                        }),
                                    }}
                                />
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Location <span className='text-red'>*</span></Form.Label>
                                <Select
                                    options={locations}
                                    value={selectedLocation}
                                    onChange={setSelectedLocation}
                                    placeholder="Select Location"
                                    classNamePrefix="react-select"
                                    isClearable
                                    className='text-left'
                                    required
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            borderColor: '#9191AF',
                                            boxShadow: 'none',
                                            backgroundColor: 'transparent',
                                            lineHeight: '35px',
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            borderRadius: '10px',
                                            border: '1px solid #ced4da',
                                            boxShadow: 'none',
                                        }),
                                    }}
                                />
                            </Form.Group>

                            <style>
                                {`
          
        `}
                            </style>

                            <Form.Group className="mt-3">
                                <Form.Label className="w-100 text-left">Content Visibility <span className='text-red'>*</span></Form.Label>
                                <Form.Control
                                    className="form-select"
                                    as="select"
                                    value={contentVisibility}
                                    onChange={(e) => setContentVisibility(e.target.value)}
                                    required
                                    style={{
                                        lineHeight: '35px',
                                        borderRadius: '10px',
                                        border: 'none',
                                        boxShadow: 'none',
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    <option value="">Select Visibility</option>
                                    <option value="public">Public</option>
                                    <option value="private">Private</option>
                                    <option value="friends">Friends</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group className="mt-3">
                            <Form.Label className="w-100 text-left">Select {type.charAt(0).toUpperCase() + type.slice(1)}</Form.Label>
                                <Form.Control
                                    className="input-file mb-2"
                                    type="file"
                                    id="file"
                                    accept={getAcceptType()}
                                    onChange={handleFileChange}
                                    multiple={type === 'photo'}
                                    required
                                    style={{ display: 'none' }}
                                />
                                <label
                                    htmlFor="file"
                                    className="text-center btn-tertiary js-labelFile w-100 mb-2"
                                    style={{ border: '1px dashed #ced4da', padding: '10px', borderRadius: '10px', backgroundSize: 'cover', backgroundPosition: 'center' }}
                                >
                                    {!fileUrl.length && (
                                        <>
                                            <i className="x1b0d499 xep6ejk" style={iconStyle} data-visualcompletion="css-img"></i>
                                            <span className="js-fileName">Drag and drop {type} file to upload</span>
                                        </>
                                    )}
                                    {fileUrl?.length > 0 && type === 'photo' && (
                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                            {fileUrl.map((url, index) => (
                                                <div key={index} style={{ position: 'relative' }}>
                                                    <img src={url} alt={`preview-${index}`} style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }} />
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            const newFiles = file.filter((_, i) => i !== index);
                                                            const newUrls = fileUrl.filter((_, i) => i !== index);
                                                            setFile(newFiles);
                                                            setFileUrl(newUrls);
                                                        }}
                                                        style={{ position: 'absolute', top: '0', right: '0', background: 'red', color: 'white', borderRadius: '50%', cursor: 'pointer' }}
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}


                                    {fileUrl?.length > 0 && type !== 'photo' && (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {type === 'video' ? (
                                                <video src={fileUrl[0]} controls style={{ width: '100%', maxHeight: '300px', marginBottom: '10px' }} />
                                            ) : (
                                                <audio src={fileUrl[0]} controls style={{ width: '100%', marginBottom: '10px' }} />
                                            )}
                                            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm text-white"
                                                    onClick={() => document.getElementById('file').click()}
                                                >
                                                    Replace
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm text-white"
                                                    onClick={() => { setFile([]); setFileUrl([]); }}
                                                >
                                                    Remove {type.charAt(0).toUpperCase() + type.slice(1)}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </label>
                            </Form.Group>

                        </div>
                        <Button variant="primary" type="submit" className="mt-3 text-white" style={{ borderRadius: '10px', backgroundColor: '#4267B2', borderColor: '#4267B2' }}>
                            {loading ? 'Uploading...' : 'Upload'}
                        </Button>
                    </Form>
                </Modal.Body>

            </Modal>
            {/* modal code end */}
        </div>
    );
};

export default ParticipateContest;
