import React, { useState, useEffect } from 'react';
import PreLoader from '../../Components/PreLoader';
import Helpers from '../../Config/Helpers';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Select from 'react-select';

export default function ProfileSettings() {
    const [loading, setLoading] = useState(true);
    const [bio, setBio] = useState('');
    const [profilePicture, setProfilePicture] = useState(null);
    const [profilePictureUrl, setProfilePictureUrl] = useState('');
    const [userName, setUserName] = useState('');
    const [visibility, setVisibility] = useState('');
    const [utype, setUtype] = useState('');
    const [socialLinks, setSocialLinks] = useState([]);
    const [bannerImage, setBannerImage] = useState(null);
    const [bannerImageUrl, setBannerImageUrl] = useState('');
    const [artistName, setArtistName] = useState('');
    const [artistHandle, setArtistHandle] = useState('');
    const [message, setMessage] = useState('');
    const [websiteLink, setWebsiteLink] = useState('');
    const [mediums, setMediums] = useState('');
    const [tools, setTools] = useState('');
    const [genres, setGenres] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [awards, setAwards] = useState('');
    const [collaborations, setCollaborations] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                await fetchSubcategories();
    
                const response = await axios.get(`${Helpers.apiUrl}auth/profiles`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
    
                if (response.status === 200) {
                    const data = response.data;
                    // console.log(data, 'profile data comes');
                    setUserName(data.user.name);
                    setBio(data.bio);
                    setBannerImageUrl(data.banner_image);
                    setProfilePictureUrl(data.profile_picture);
                    setVisibility(data.visibility);
                    setUtype(data.user.utype);
                    setSocialLinks(data.social_links || []);
                    setArtistName(data.user.name || '');
                    setArtistHandle(data.artist_handle || '');
                    setMessage(data.message || '');
                    setWebsiteLink(data.website_link || '');
                    setMediums(data.mediums || '');
                    setTools(data.tools || '');
                    setAwards(data.awards || '');
                    setCollaborations(data.collaborations || '');
    
                    // Parse genres
                    const genreIds = data.genres || [];
                    const genreNames = data.genre_names || [];
    
                    const genreMap = genreIds.reduce((map, id, index) => {
                        map[id] = genreNames[index] || `Unknown (${id})`;
                        return map;
                    }, {});
    
                    // Map genre IDs to their names
                    const selectedGenres = genreIds.map(id => {
                        const name = genreMap[id] || `Unknown (${id})`;
                        return { value: id, label: name };
                    });
    
                    setGenres(selectedGenres);
                }
            } catch (error) {
                Helpers.toast('error', 'Failed to fetch profile data');
            } finally {
                setLoading(false);
            }
        };
    
        const fetchSubcategories = async () => {
            try {
                const response = await axios.get(`${Helpers.apiUrl}auth/fetch-sub-categories`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const groupedOptions = response.data.data.reduce((acc, sub) => {
                    const category = sub.category;
                    if (!acc[category]) acc[category] = [];
                    acc[category].push({ value: sub.id, label: sub.sub_category });
                    return acc;
                }, {});
    
                const formattedOptions = Object.keys(groupedOptions).map(category => ({
                    label: category,
                    options: groupedOptions[category]
                }));
    
                setSubcategories(formattedOptions);
            } catch (error) {
                Helpers.toast('error', 'Failed to fetch subcategories');
            }
        };
    
        fetchProfile();
    }, []);
    
    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('bio', bio);
        formData.append('visibility', visibility);
        formData.append('utype', utype);

        socialLinks.forEach((link, index) => {
            formData.append('social_links[]', link); // Use 'social_links[]' to send as an array
        });
        formData.append('name', artistName);
        formData.append('artist_handle', artistHandle);
        formData.append('message', message);
        formData.append('website_link', websiteLink);
        formData.append('mediums', mediums);
        formData.append('tools', tools);
        formData.append('genres', JSON.stringify(genres.map(g => g.value)));
        formData.append('awards', awards);
        formData.append('collaborations', collaborations);

        if (profilePicture) {
            formData.append('profile_picture', profilePicture);
        }
        if (bannerImage) {
            formData.append('banner_image', bannerImage);
        }

        try {
            const response = await axios.post(`${Helpers.apiUrl}auth/update-profiles`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                Helpers.toast('success', response.data.message || 'Profile updated successfully');
                const data = response.data.profile;
                setProfilePictureUrl(data.profile_picture);
                setIsEditMode(false);
                window.location.reload();
            } else {
                Helpers.toast('error', 'Failed to update profile');
            }
        } catch (error) {
            Helpers.toast('error', 'Failed to update profile');
        } finally {
            setLoading(false);
        }
    };

    const handleAddSocialLink = () => {
        setSocialLinks([...socialLinks, '']);
    };

    const handleSocialLinkChange = (index, value) => {
        const newSocialLinks = socialLinks.slice();
        newSocialLinks[index] = value;
        setSocialLinks(newSocialLinks);
    };

    const handleRemoveSocialLink = (index) => {
        const newSocialLinks = socialLinks.slice();
        newSocialLinks.splice(index, 1);
        setSocialLinks(newSocialLinks);
    };

    const handleBannerImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setBannerImage(file);
            setBannerImageUrl(URL.createObjectURL(file));
        }
    };

    const handleProfilePictureChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfilePicture(file);
            setProfilePictureUrl(URL.createObjectURL(file));
        }
    };

    const handleRemoveBannerImage = () => {
        setBannerImage(null);
        setBannerImageUrl('');
    };

    const handleRemoveProfilePicture = () => {
        setProfilePicture(null);
        setProfilePictureUrl('');
    };

    const handleGenreChange = (selectedOptions) => {
        setGenres(selectedOptions ? selectedOptions : []);
    };

    return (
        <div className='main-content right-chat-active'>
            {loading ? (
                <PreLoader />
            ) : (
                <div className="main-content bg-lightblue theme-dark-bg py-2">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="middle-wrap">
                                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                        <Link to="/default-settings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                        <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Profile Settings</h4>
                                    </div>
                                    <div className="card-body p-lg-5 p-4 w-100 border-0">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-4 text-center">
                                                <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                                                    <img src={profilePictureUrl || './assets/images/pt-1.jpg'} alt="profile" className="shadow-sm rounded-3 w-100" />
                                                </figure>
                                                <h2 className="fw-700 font-sm text-grey-900 mt-3">{userName}</h2>
                                                <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">{bio}</h4>
                                                {!isEditMode && (
                                                    <button
                                                        className="bg-current btn btn-sm text-center text-white font-xsss fw-600 p-2 w-60 rounded-3 d-inline-block mt-2"
                                                        onClick={() => setIsEditMode(true)}
                                                    >
                                                        Edit Profile
                                                    </button>
                                                )}
                                            </div>
                                        </div>

                                        {isEditMode && (
                                            <form onSubmit={handleProfileUpdate}>
                                                <div className="row">
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-group text-start">
                                                            <label className="mont-font fw-600 font-xsss">Bio</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={bio}
                                                                onChange={(e) => setBio(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-3">
                                                        <div className="form-group text-start">
                                                            <label className="mont-font fw-600 font-xsss">Visibility</label>
                                                            <select
                                                                className="form-control"
                                                                value={visibility}
                                                                onChange={(e) => setVisibility(e.target.value)}
                                                                style={{ lineHeight: '30px' }}
                                                            >
                                                                <option value="public">Public</option>
                                                                <option value="friends">Friends</option>
                                                                <option value="private">Private</option>
                                                                <option value="protected">Protected</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="form-group text-start">
                                                            <label className="mont-font fw-600 font-xsss">Become Artist?</label>
                                                            <select
                                                                className="form-control"
                                                                value={utype}
                                                                onChange={(e) => setUtype(e.target.value)}
                                                                style={{ lineHeight: '30px' }}
                                                            >
                                                                <option value="user" selected={utype === "user"}>User</option>
                                                                <option value="artist" selected={utype === "artist"}>Artist</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                {utype === 'artist' && (
                                                    <>
                                                        <div className="row">
                                                            <div className="col-lg-6 mb-3">
                                                                <div className="form-group text-start">
                                                                    <label className="mont-font fw-600 font-xsss">Artist Name</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={artistName}
                                                                        onChange={(e) => setArtistName(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <div className="form-group text-start">
                                                                    <label className="mont-font fw-600 font-xsss">Artist Handle</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={artistHandle}
                                                                        onChange={(e) => setArtistHandle(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <div className="form-group text-start">
                                                                    <label className="mont-font fw-600 font-xsss">Message</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={message}
                                                                        onChange={(e) => setMessage(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <div className="form-group text-start">
                                                                    <label className="mont-font fw-600 font-xsss">Website Link</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={websiteLink}
                                                                        onChange={(e) => setWebsiteLink(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <div className="form-group text-start">
                                                                    <label className="mont-font fw-600 font-xsss">Mediums</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={mediums}
                                                                        onChange={(e) => setMediums(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <div className="form-group text-start">
                                                                    <label className="mont-font fw-600 font-xsss">Tools</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={tools}
                                                                        onChange={(e) => setTools(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <div className="form-group text-start">
                                                                    <label className="mont-font fw-600 font-xsss">Genres</label>
                                                                    <Select
                                                                        options={subcategories}
                                                                        isMulti
                                                                        value={genres}
                                                                        onChange={handleGenreChange}
                                                                        placeholder="Select Genres"
                                                                        classNamePrefix="react-select"
                                                                        isClearable
                                                                        className='text-left multiple-input'
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                borderRadius: '10px',
                                                                                boxShadow: 'none',
                                                                                backgroundColor: 'transparent',
                                                                                lineHeight: '35px',
                                                                                borderColor: '#9191AF',
                                                                            }),
                                                                            menu: (provided) => ({
                                                                                ...provided,
                                                                                borderRadius: '10px',
                                                                                boxShadow: 'none',
                                                                            }),
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-3">
                                                                <div className="form-group text-start">
                                                                    <label className="mont-font fw-600 font-xsss">Awards & Recognition</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        value={awards}
                                                                        onChange={(e) => setAwards(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="col-lg-12 mb-3">
                                                                <div className="form-group text-start">
                                                                    <label className="mont-font fw-600 font-xsss">Collaborations</label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        value={collaborations}
                                                                        onChange={(e) => setCollaborations(e.target.value)}
                                                                        rows="5"
                                                                        style={{
                                                                            width: '100%',
                                                                            padding: '10px',
                                                                            fontSize: '14px',
                                                                            lineHeight: '1.5'
                                                                        }}
                                                                    ></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 mb-3">
                                                                <div className="form-group text-start">
                                                                    <label className="mont-font fw-600 font-xsss">Social Media Links</label>
                                                                    {socialLinks.map((link, index) => (
                                                                        <div key={index} className="d-flex align-items-center mb-2">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                value={link}
                                                                                onChange={(e) => handleSocialLinkChange(index, e.target.value)}
                                                                                placeholder="Enter social media link"
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger btn-sm ms-2"
                                                                                onClick={() => handleRemoveSocialLink(index)}
                                                                            >
                                                                                Remove
                                                                            </button>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary btn-sm mt-2 text-white"
                                                                    onClick={handleAddSocialLink}
                                                                >
                                                                    Add Link
                                                                </button>
                                                            </div>
                                                            <div className="col-lg-12 mb-3">
                                                                <div className="card mt-3 border-0">
                                                                    <div className="form-group text-start">
                                                                        <label className="mont-font fw-600 font-xsss">Banner Image</label>
                                                                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                                            <div className="form-group mb-0 w-100 position-relative">
                                                                                <input
                                                                                    type="file"
                                                                                    name="banner_image"
                                                                                    id="banner_image"
                                                                                    className="input-file"
                                                                                    onChange={handleBannerImageChange}
                                                                                />
                                                                                <label htmlFor="banner_image" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed position-relative" style={{ backgroundImage: bannerImageUrl ? `url(${bannerImageUrl})` : 'none', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                                                                    {!bannerImageUrl && (
                                                                                        <>
                                                                                            <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                                                            <span className="js-fileName">Drag and drop or click to replace banner image</span>
                                                                                        </>
                                                                                    )}
                                                                                </label>
                                                                                {bannerImageUrl && (
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <button type="button" className="btn btn-primary btn-sm m-2 text-white" onClick={() => document.getElementById('banner_image').click()}>Replace</button>
                                                                                        <button type="button" className="btn btn-danger btn-sm m-2 text-white" onClick={handleRemoveBannerImage}>Remove Banner Image</button>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                                <div className="row">
                                                    <div className="col-lg-12 mb-3">
                                                        <div className="card mt-3 border-0">
                                                            <div className="form-group text-start">
                                                                <label className="mont-font fw-600 font-xsss">Profile Picture</label>
                                                                <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                                    <div className="form-group mb-0 w-100 position-relative">
                                                                        <input
                                                                            type="file"
                                                                            name="profile_picture"
                                                                            id="profile_picture"
                                                                            className="input-file"
                                                                            onChange={handleProfilePictureChange}
                                                                        />
                                                                        <label htmlFor="profile_picture" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed position-relative" style={{ backgroundImage: profilePictureUrl ? `url(${profilePictureUrl})` : 'none', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                                                            {!profilePictureUrl && (
                                                                                <>
                                                                                    <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                                                    <span className="js-fileName">Drag and drop or click to replace profile picture</span>
                                                                                </>
                                                                            )}
                                                                        </label>
                                                                        {profilePictureUrl && (
                                                                            <div className="d-flex justify-content-between">
                                                                                <button type="button" className="btn btn-primary btn-sm m-2 text-white" onClick={() => document.getElementById('profile_picture').click()}>Replace</button>
                                                                                <button type="button" className="btn btn-danger btn-sm m-2 text-white" onClick={handleRemoveProfilePicture}>Remove Profile Picture</button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <button
                                                            type="submit"
                                                            className="bg-current btn btn-sm text-center text-white font-xsss fw-600 p-2 w175 rounded-3 d-inline-block m-2"
                                                            disabled={loading}
                                                        >
                                                            {loading ? 'Updating...' : 'Update'}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="bg-current btn btn-sm text-center text-white font-xsss fw-600 p-2 w175 rounded-3 d-inline-block ml-3"
                                                            onClick={() => setIsEditMode(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
